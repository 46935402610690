export const typeOptions = [
    { value: "pallet", label: "Pallet" },
    { value: "bag", label: "Bag"},
    { value: "barrel", label: "Barrel"},
    { value: "box", label: "Box"},
    { value: "bucket", label: "Bucket"},
    { value: "bundle", label: "Bundle"},
    { value: "cage", label: "Cage"},
    { value: "carton", label: "Carton"},
    { value: "case", label: "Case"},
    { value: "chest", label: "Chest"},
    { value: "container", label: "Container"},
    { value: "crate", label: "Crate"},
    { value: "cylinder", label: "Cylinder"},
    { value: "drum", label: "Drum"},
    { value: "envelope", label: "Envelope"},
    { value: "package", label: "Package"},
    { value: "pail", label: "Pail"},
    { value: "pallet", label: "Pallet"},
    { value: "parcel", label: "Parcel"},
    { value: "pieces", label: "Pieces"},
    { value: "reel", label: "Reel"},
    { value: "roll", label: "Roll"},
    { value: "sack", label: "Sack"},
    { value: "shrinkwrapped", label: "Shrinkwrapped"},
    { value: "skid", label: "Skid"},
    { value: "tank", label: "Tank"},
    { value: "totebin", label: "Totebin"},
    { value: "tube", label: "Tube"},
    { value: "unit", label: "Unit"},
    { value: "other", label: "Other"}
];

export const classOptions = [
    { value: "50", label: "50" },
    { value: "55", label: "55" },
    { value: "60", label: "60" },
    { value: "65", label: "65" },
    { value: "70", label: "70" },
    { value: "77.5", label: "77.5" },
    { value: "85", label: "85" },
    { value: "92.5", label: "92.5" },
    { value: "100", label: "100" },
    { value: "110", label: "110" },
    { value: "125", label: "125" },
    { value: "150", label: "150" },
    { value: "175", label: "175" },
    { value: "200", label: "200" },
    { value: "250", label: "250" },
    { value: "300", label: "300" },
    { value: "400", label: "400" },
    { value: "500", label: "500" }
]
