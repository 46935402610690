// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import CompanyModelController from "controllers/ModelControllers/CompanyModelController";

export default class SecondPanelController extends _viewController {
    constructor(props) {
        super(props)
        this.company = new CompanyModelController({params: {parent: this, 
                                                    controller: this,
                                                    is_view: true}})
    }
    load_cache() {
        if (this.panel_controller?.cache?.["1"]?.data !== undefined) {
            if (this.view?.state?.data?.company === undefined) {
                this.view.state.company_id = this.panel_controller.cache["1"].data
            }
        }
    }
    load_data() {
        this.view?.context?.api?.companies.get({caller: this, id: this?.view?.state?.company_id, callback: {f: this.load_company}})
    }
    load_company({caller, params, results}) {
	    caller.setState({key: "data", param: "company", value: results?.data?.company})
    }
    submit_action() {
        let code = this.controller.getState({key: "data", param: "code"})
        let id = this.controller.getState({key: "data", param: "company.id"})
        let value = {}
        let sb_config = {success: {show: false}}
        if (id !== undefined) {
            value.company_id = id
            if (code !== undefined && Object.keys(code).length > 0) { value.code = code }
                this.api.companies.join({caller: this.controller, 
                                            value: this.controller.toUnquotedJSON(value), 
                                            callback: {f: this.controller.process_creation, sb: sb_config} })
        }
    }
    process_creation({caller, params, results}) {
	    caller.panel_controller.state.parent.state.data.user = undefined
        caller.panel_controller.state.parent.forceUpdate()
        caller.panel_controller?.selectPanel({panel_num: 0, cache_data: undefined})
    }
    cancel_action() {
        this.controller?.panel_controller?.selectPanel({panel_num: 0, cache_data: undefined})
    }
}
