// GENERAL REACT MODULES
import _basePartial from "components/General/_basePartial.jsx"
import { Row, Col } from 'react-bootstrap'

// ALL OTHER MODULES
import QuoteLine from "./QuoteLine.partial.jsx"
import Controller from "./QuoteForm.controller.jsx"
import "./QuoteForm.css"

export default class QuoteForm extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this}})
	    this.state.data.line_items = this.line_items
    }
    get line_items() {
        let items = this.controller?.quote_request?.items
        if (items?.length === 0 || items === undefined) {
            this.controller.quote_request.items = [{}]
        }
        return items
    }
    followDidMount() {
        if (this.state.data.line_items === undefined) {
            this.state.data.line_items = this.line_items
            this.forceUpdate()
        }
    }
    render() {
	    this.state.data.line_items = this.line_items
        return (
            <Row id={this.name}>
                <Col className="panel add_shadow">
                    <Row className="bold center_only blue_text line_border">
                        <Col>
                            Type
                        </Col>
                        <Col>
                            Count
                        </Col>
                        <Col>
                            L (in)
                        </Col>
                        <Col>
                            W (in)
                        </Col>
                        <Col>
                            H (in)
                        </Col>
                        <Col>
                            Total Weight (lbs)
                        </Col>
                        <Col>
                            Class
                        </Col>
                    </Row>
                    {
                        this.render_line_items()
                    }
                </Col>
            </Row>
        )
    }
    render_line_items() {
        if (Array.isArray(this.line_items)) {
            return this.line_items?.map((item, index) => {
                return <QuoteLine params={{parent: this.state.parent,
                                            controller: this.partial_controller, 
                                            // data: this.state.data.line_items[index],
                                            index: index}} 
                                            key={"line_item_"+index}/>
            })
        }
    }
}
