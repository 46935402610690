// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import "./DatesPanel.css"

export default class DatesPanel extends _basePartial {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    renderDateCol({data, label, formtype, opt, col, classes}) {
        return (
            <>
                <Col className={"g-0 "+" "+((classes?.[0] !== undefined) ? classes?.[0] : "")} id={data?.public?.title + "_" + label}>
                    <Row>
                        <Col>
                            {data?.public?.title + " " + label}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {opt}
                        </Col>
                    </Row>
                </Col>
                <Col sm={col} className={"g-0"+" "+((classes?.[1] !== undefined) ? classes?.[1] : "")}>
                    {this.controller?.view.formObject({ data: data, owner: this.controller })}
                </Col>
            </>
        )
    }
    date_config(packet) {
        packet.type = "datepicker"
        packet.title = packet.name
        packet.name = "date"
        return packet
    }
    time_config(packet) {
        packet.type = "timepicker"
        packet.title = packet.name
        packet.name = "time"
        packet.classes = "min_date_width"
        return packet
    }
    appointment_config(packet) {
        packet.type = "checkbox"
        packet.title = packet.name
        packet.name = "appointment_required"
        return packet
    }
    text_config({ packet, name }) {
        packet.type = ""
        packet.title = packet.name
        packet.name = name
        return packet
    }
    packet(configs) {
        return {
            "placeholder": "",
            "subtype": "text",
            "title": "data",
            "name": configs?.subtype,
            "public": {
                "title": configs?.title
            }
        }
    }
    renderDateRow(configs) {
        return (
            <Row className="selection_row">
                <Col className="g-0 min_col col_pad flex_content">
                    <Row className="g-0">
                        {this.renderDateCol({ data: this.time_config(this.packet(configs)), label: "Time", opt: "(Optional)" })}
                    </Row>
                    <Row className="g-0">
                        {this.renderDateCol({ data: this.date_config(this.packet(configs)), label: "Date*" })}
                    </Row>
                </Col>
                <Col className="g-0 col_pad flex_content">
                    <Row className="g-0">
                        {this.renderDateCol({ data: this.appointment_config(this.packet(configs)), label: "Appointment", opt: "(Optional)", col: 2, classes: ["", "flex_center"]})}
                    </Row>
                    <Row className="g-0">
                        {this.renderDateCol({ data: this.text_config({ packet: this.packet(configs), name: "number" }), label: "#", opt: "(Optional)"})}
                    </Row>

                </Col>
            </Row>
        )
    }
    render() {
        return (
            <>
                <Row id={this.name}>
                    <Col>
                        {
                            this.renderDateRow(this.controller?.configs?.pickup)
                        }
                        <Row className="padding_row" />
                        {
                            this.renderDateRow(this.controller?.configs?.delivery)
                        }
                        <Row />
                    </Col>
                </Row>
            </>
        )
    }
}
