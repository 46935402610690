// GENERAL REACT MODULES
import React from "react";

// PAGE SPECIFIC MODULES
import _baseViewComponent from "components/General/_baseViewComponent.jsx"
import Controller from "./PanelView.controller.jsx";
import "./PanelView.css"

export default class PanelView extends _baseViewComponent {
    constructor(props) {
        super(props)
        this.controller = new Controller({params: {view: this}})
    }
    renderView() {
        return (
            <div className="panelViewWrapper">

            </div>
        )
    }
}