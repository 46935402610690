// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";
// import { Textfit } from 'react-textfit';

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./Payor.controller.jsx"
import "./Payor.css"
import config_data from "./Payor.json"
import { AiFillCloseCircle } from "react-icons/ai";
import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";

export default class PayorObject extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this}})
        this.state.form         = config_data.form_objects
    }
    get company() {
//      return this.controller.company
        return this.partial_controller.company
    }
    center() {
        if (this.state.center) {
            return "center_payor"
        }
        return ""
    }
    render_payor_view() {
        return (
            <Row className="g-0 min_col" id={this.name} ref={ (payorElement) => { (this.controller !== undefined) ? this.controller[this.name] = payorElement : this.payorElement = payorElement } }>
                <Col className="g-0">
                    <Row className={"cota_payor_box cota_panel_object_unsized g-0 "+this.center()}>
                        {this.add_nav()}
                        {this.a_wrap()}
                    </Row>
                </Col>
                
            </Row>
        )
    }
    closeable() {
        if (this.state.closeable) {
            return (
                <AiFillCloseCircle size={15} className={"searchable_close "} onClick={this.partial_controller.close.bind(this)}/>
            )
        }
    }
    a_wrap() {
        if (this.company?.url !== undefined) {
            // Used to be an href link. Removed for time being. Leaving for future use, in case a need to restore
            return (
                <>
                    {this.add_company()}
                </>
            )
        }
        return this.add_company()
    }
    add_company() {
        return (
            <Col>
                {this.closeable()}
                <Row className="padding">
                {/* {console.log("Looking for image",this)} */}
                    <Col className="g-0 cota_payor_image_col">
                        {/* {console.log("LOOKING CACHE",this)} */}
                        {this.partial_controller.get_payor_image(this?.panel_controller?.state?.image ?? this?.parent?.state?.data?.contact?.image?.[0]?.url)}
                    </Col>
                    <Col sm={5} className="g-0 company_name_label_col">
                        <span mode="single" className="payor_label" style={{"fontSize": this.font_size({text: this.company.name, height: 100, min: 6, limit: 28, floor: 18})}}>
                            {(this.company?.name !== undefined) ? this.trim_text({text: this.company.name, limit: 50}) : ""}
                        </span>
                    </Col>
                </Row>
            </Col>
        )
    }
    font_size({text, height, min, limit, floor}) {
        let font_size = 30
        // font_size = font_size * ((((height / text.length) - (height / limit)) / (height / min)) + 1)
        let min_length = (text.length > 4) ? text.length : 4
        font_size = height * (4/ ((min_length < limit) ? min_length : limit) ) 

        // This needs to be re-assessed to factor the box width. this works for wider screens, not for the smaller
        // font_size = font_size * (((min_length / 4) / 10) + 1)
        if (font_size < floor) {
            return floor+"px"
        }
        return font_size+"px"
    }
    trim_text({text, limit}) {
        return (text.length > limit) ? text.slice(0, limit-1) + '&hellip;' : text;
    }
    add_nav() {
        if (isNaN(this.controller?.company?.index)) {
            return this.add_label()
        }
        return (
            <Col sm={1} className="cota_payor_title_box strong_font g-0">
                <Row className="g-0">
                    <Col sm={3} className="g-0">
                        <BsFillCaretLeftFill size={30} className="nav_arrow pointer" onClick={this.controller?.company.nav.bind(this.controller?.company, false)}/>
                    </Col>
                    <Col className="g-0">
                         {this.state.label}
                    </Col>
                    <Col sm={3} className="g-0">
                        <BsFillCaretRightFill size={30} className="nav_arrow pointer" onClick={this.controller?.company.nav.bind(this.controller?.company, true)}/>
                    </Col>
                </Row>
            </Col>
        )
    }
    add_label() {
        if (this.state.label !== undefined) {
            return (
                <Col sm={1} className="cota_payor_title_box strong_font g-0">
                    {this.state.label}
                </Col>
            )
        }
    }
    render_payor_form() {
        return (
            <Row className="g-0 min_col" id={this.name}>
                <Col className="g-0">
                    <Row>
                        <Col className='large_text'>
                            {this.state.title}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.formObject({data: this.state.form.company, 
                                                        options: undefined,
                                                        controller: this.partial_controller,
                                                        owner: this.partial_controller})}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
    renderView() {
        this.state.data.company = (this.controller?.company?._id !== undefined && this.controller?.company?._id !== null) ? this.controller?.company?.data : this.props.params.company
        // if (!this.check_role("INTERNAL_COTA_USER") || this.partial_controller.not_blank(this.controller?.load?.number) || this.partial_controller.not_blank(this.controller?.load?.cota_id) || this.partial_controller.not_blank(this.controller?.quote?.number)) {
        //     this.state.data.company = (this.controller?.company?.data !== undefined && Object.keys(this.controller?.company?.data)?.length > 0) ? this.controller?.company?.data : this.props.params.company
        // }
        if (this.state.data?.company?.id !== undefined && this.state.data?.company?.id !== null) {
            return this.render_payor_view()
        }
        return this.render_payor_form()
    }
}
