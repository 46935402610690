// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import MultiLoadModalController from "controllers/ModelControllers/Shipping/MultiLoadModalController.jsx"
import distance_request from "assets/graphql/Distance/distance.graphql.json"
import ShipmentModelController from "controllers/ModelControllers/Shipping/ShipmentModelController.jsx"
import edit_load from "assets/graphql/Loads/loads.mutation.graphql.json"

export default class ShipmentsController extends _viewController {
    constructor(props) {
        super(props)
        this.state = {
            shipment: undefined
        }
        this.routing = {
            default: {
                path: 0,
                screen: 0
            },
            share: {
                path: 0,
                screen: 4,
            },
            alt: "load_number"
        }
        this.load       = new ShipmentModelController({params: {parent: this,
                                                        controller: this,
                                                        is_view: true}})
        this.multiload  = new MultiLoadModalController({params: {parent: this, 
                                                                controller: this, 
                                                                is_view: true}})                               
        this.requests   = [{callback: {f: this.process_users}, endpoint: "quotes", req_name: "creators"},
                                {callback: {f: this.process_customers}, endpoint: "loads", req_name: "customers"},
                                {callback: {f: this.process_payors}, endpoint: "loads", req_name: "payors"},
                                {callback: {f: this.process_status}, replace: {o: "status", r: 'status(types:"load", sortby: "name")'}, endpoint: "status"}]

        this.onDragEnd  = this.onDragEnd.bind(this)
    }
    change_date_value(dates) {
        if (dates?.[0] !== undefined) {
            this.state.from_date = new Date(dates[0]).valueOf()
        } else {
            this.state.from_date = undefined
        }
        if (dates?.[1] !== undefined) {
            this.state.to_date = new Date(dates[1]).valueOf()
        } else {
            this.state.to_date = undefined
        }
        this.forceUpdate()
    }

    sort_items({objects, obj_name}) {
        objects = objects?.filter(function (el) {
            return (el?.[obj_name] != null) && (el?.[obj_name]?.id !== null) && (el?.[obj_name]?.name !== null);
        });
        return objects?.sort(function(a,b) {
            a = a?.[obj_name]?.name.toLowerCase();
            b = b?.[obj_name]?.name.toLowerCase();
            return (a < b) ? -1 : (a > b) ? 1 : 0;
        });
    }
    generate_query_params(add_params) {
        if (Array.isArray(add_params)) {
            if (add_params.length > 0) {
                let qparams = "id,pickup_id, dropoff_id, company, carrier, status, total_amount, cota_id, "+add_params.toString()
                return qparams
            }
        }
        return "id, pickup_id, dropoff_id, company, carrier, status, total_amount, cota_id, pickup, dropoff, trailer_type, total_length, total_weight, total_stops, latest_note "
    }
    process_users({caller, params, results}) {
        results?.data?.quotes?.forEach((quote, index) => {
            caller.view.state.user_options.push({ value: quote?.creator, label: quote?.creator?.Capitalize() })
        })
    }
    process_customers({caller, params, results}) {
        if (results?.data?.loads !== undefined) {
            let loads = caller.sort_items({objects: results?.data?.loads, obj_name: "carrier"})
            loads.forEach((load, index) => {
                if (load?.carrier?.name !== undefined && load?.carrier?.id !== undefined) {
                    caller.view.state.customer_options.push({ value: load.carrier?.id, label: load.carrier?.name?.Capitalize() })
                }
            })
        }
    }
    process_payors({caller, params, results}) {
        if (results?.data?.loads !== undefined) {
            let loads = caller.sort_items({objects: results?.data?.loads, obj_name: "payor"})
            loads.forEach((load, index) => {
                if (load?.payor?.name !== undefined && load?.payor?.id !== undefined) {
                    caller.view.state.payor_options.push({ value: load.payor?.id, label: load.payor?.name?.Capitalize() })
                }
            })
        }
    }
    process_status({caller, params, results}) {
        results?.data?.status?.forEach((obj, index) => {
            if(obj?.value === "BILLED_FREIGHT") { return }
            caller.view.state.status_options.push({ value: obj?.value, label: obj?.label?.Capitalize() })
        })
    }
    release() {
	    this.controller?.view.toggle_modal({})
    }
    close() {
	    this.controller.view.toggle_modal({})
    }
    save_ml() {
        console.log(this.controller.multiload.summary)
        let _summary            = this.controller?.load?.summary
        _summary.co2            = Number.parseFloat(this.controller?.multiload?.total_co2)
        _summary.rate           = (Math.round(Number.parseFloat(this.controller?.multiload?.total_rate) * 100)) / 100
        _summary.total_length   = Number.parseFloat(this.controller?.multiload?.total_length)
        _summary.total_weight   = Number.parseFloat(this.controller?.multiload?.total_weight)
        _summary.distance       = Number.parseFloat(this.controller?.multiload?.distance?.total_distance)
        _summary.rate_mile      = Number.parseFloat(this.controller?.multiload?.total_rm)

        let data    = this.controller.toUnquotedJSON(_summary)
        let body    = JSON.parse(JSON.stringify(edit_load))
        body.query  = body.query.replace("Load", 'Load(input: '+data+')')
        this.api.loads.create({caller: this, params: {body: body}, callback: {f: this.controller.process_save_load}})
	    this.controller.grafana_frame.forceUpdate()
    }
    process_save_load({caller, params, results}) {
        console.log(results)
    }
    process_long_click() {
        if (this.is_multi_capable()) {
            this.view.toggle_modal({})
            // this.modal_ref.focus()
            this.focusDiv()
        }
    }
    processEventReaction(decoded) {
        if (decoded.channel === "grafana_row") {
            this.state.data = undefined
            this.view.handleScreen({path_num: 1, screen_num: 0, data: decoded.message.data})
        } else {
            this.state.data = decoded?.message?.data
        }
    }
    divide_stop({id, company, data}) {
        if (data !== undefined && data !== null) {
            let stop_data = data.replace(/ /g, '').split(",")
            let stop = {
                id: id,
                company: company,
                city: stop_data?.[0],
                state: stop_data?.[1],
                zip: stop_data?.[2]
            }
            return stop
        }
        return {}
    }
    display_internal() {
        if (this.check_role("INTERNAL_COTA_USER")) {
            return ""
        }
        return " hide"
    }
    is_multi_capable() {
        if (this.check_role("COTA_CAPABILITY_MULTI") || this.check_role("INTERNAL_COTA_USER")) {
            return true
        }
        return false
    }
    mouse_up() {
	    console.log("UP")
    }
    mouse_down() {
	    console.log("DOWN")
    }
    async onDragEnd(result) {
        if (result?.draggableId !== undefined) {
            this.view?.state?.data?.stops.move(result?.source?.index, result?.destination?.index)
            this.getZips()
        }
        if (this.state?.data !== undefined) {
            this.view.state.data.loads.push(this.state?.data)
            let pickup      = this.divide_stop({id: this.state.data?.pickup_id, company: this.state.data?.company, data: this.state.data?.pickup})
            pickup.type     = "pickup"
            pickup.load_id  = this.state.data?.pickup_id
            this.view.state.data.stops.push(pickup)

            let dropoff     = this.divide_stop({id: this.state.data?.dropoff_id, company: this.state.data?.company, data: this.state.data?.dropoff})
            dropoff.type    = "dropoff"
            dropoff.load_id = this.state.data?.dropoff_id
            this.view.state.data.stops.push(dropoff)

            this.getZips()
            this.view.forceUpdate()
        }
        this.state.data = undefined
    } 
    async getZips() {
        let zips    = this.view.state.data.stops.map((el) => {if (el.zip !== undefined) {return el.zip}})
        let body    = JSON.parse(JSON.stringify(distance_request))
        body.query  = body.query.replace("(zips:)", '(zips: '+JSON.stringify(zips)+')')
        this.api.distance.ask({caller: this, params: {body: body}, callback: {f: this.process_distance}})
    }
    process_distance({caller, params, results}) {
        caller.state.trip = results?.data?.distance
	    caller.setState({key: "data", param: "distance", value: results?.data?.distance})
    }
    _swap({obj, prop1, prop2}) {
        var tmp = obj[prop1];
        obj[prop1] = obj[prop2];
        obj[prop2] = tmp;
    }
    // Depricated; Preserved in case
    // _swap({obj, prop1, prop2}) {
    //     var tmp = obj[prop1];
    //     obj[prop1] = obj[prop2];
    //     obj[prop2] = tmp;
    // }
    focusDiv() {
        // ReactDOM.findDOMNode(this.view.refs._modal_ref).focus();
        const div = this.view.state.refs._modal_ref.current;
        console.log(div)
        div.focus()
    }
    delete_stop({index}) {
	    console.log(this.state.data.loads)
        if (index === undefined) {
            this.state.data.stops = []
            this.state.data.loads = []
            this.forceUpdate()
        }
    }
    toggle_ltl(index) {
        let radio_item = this.state?.radios?.[index]
        radio_item.checked = !radio_item?.checked
        this.state.radios[index] = radio_item
        this.forceUpdate()
    }
    resolve_ltl_toggle() {
        if (this.view.state?.radios?.[0]?.checked && this.view.state?.radios?.[1]?.checked) {
            return null
        }
        if (this.view.state?.radios?.[0]?.checked) {
            return true
        }
        if (this.view.state?.radios?.[1]?.checked) {
            return false
        }
    }
    determine_badge(value) {
        switch(value?.toLowerCase()) {
            case "dispatched":
                return "uncota-badge"
            case "booked":
                return "cota-badge"
            case "pending":
                return "yellow-badge"
            case "billed":
                return "unred-badge"
            default:
                return ""
        }
    }
}
Array.prototype.move = function(from, to) {
    this.splice(to, 0, this.splice(from, 1)[0]);
};
