// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import doc_query from "assets/graphql/Loads/loads.documents.graphql.json"

export default class DocumentsModalController extends _viewController {
    constructor(props) {
        super(props)
        this.endpoint = "loads"
    }
    get load() {
        return this.controller.load
    }

    get_row_color(index) {
        if (index === this.view?.state?.hover) {
            return "hover_line"
        }
        return ""
    }
    hover_line(index) {
        if (this.state.hover !== index) {
            return this.setState({hover: index})
        }
        return this.setState({hover: undefined})
    }
    create_document(type) {
        this.state.panel_controller?.selectPanel({panel_name: "create_documents", data: type})
    }
    update_documents() {

    }
    upload_callback({results, caller, params}) {
        if (caller !== undefined) {
            caller.process_documents_update()
        }
    }
    process_documents_update() {
        if (this.load?._id !== undefined) {
            let body    = JSON.parse(JSON.stringify(doc_query))
            body.query  = body.query.replace("load", 'load(id: "'+this.load._id+'")')
            this.api.quotes.ask({caller: this, params: {body: body}, callback: {f: this.process_loaded_quote, fin: true}})
        }
    }
    process_loaded_quote({caller, params, results}) {
        if (results?.data?.load?.documents !== undefined) {
            caller.load.documents = results?.data?.load?.documents
        }
    }
    download_file() {
        
    }
    truncateString(string, maxLength){
        if (string.length <= maxLength) {
            return string;
        } else {
            const truncatedString = string.substring(0, maxLength) + "..."
            return truncatedString;
        }
    }
    process_deleted_file({caller, results, params}) {
        if (typeof caller.view?.state?.parent?.controller?.delete_action === "function") {
            caller.view?.state?.parent?.controller?.delete_action({obj: caller.view?.state?.item})
        }
        caller.view?.state?.parent?.toggle_modal({})
    }
}
