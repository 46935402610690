// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import ColumnController from "components/Column/Column.controller.jsx"
import CarrierPanel from "module/pages/Shipments/ShipmentPartials/Carrier/Carrier.partial"
import HistoryPanel from "module/pages/Shipments/ShipmentPartials/History/History.partial"
import DispatchedPanel from "module/pages/Shipments/ShipmentPartials/DispatchedPanel/DispatchedPanel.partial"
import Dispatcher from "module/pages/Shipments/ShipmentPartials/Dispatcher/Dispatcher.partial"

export default class ColumnThreeController extends ColumnController {
    constructor(props) {
        super(props)
        this.state.data = {
            shipper: {},
            consignee: {},
            stop: undefined
        }
        this.state.rows = this.generate_rows()
    }
    get _rows() {
        return this.generate_rows()
    }

    display_internal(role) {
        if (this.check_role(role)) {
            return true
        }
        
        return false
    }
    
  generate_rows() {
        let rows        = Array(2).fill(undefined)
        let internal    = this.display_internal("INTERNAL_COTA_USER") 
        let owner       = this.display_internal("OWNER") 
        this.state.carrier = this.column?.controller?.load?.carrier
        if (this.state?.carrier?.id !== undefined && this.state?.carrier?.id !== "") {
            if (internal || owner) {
                rows[0] = {obj: CarrierPanel, params: {params: {parent: this, controller: this.view.controller, editable: true, index: 0}}}
                rows[1] =  {obj: DispatchedPanel, params: {params: {parent: this, controller: this.view.controller, editable: true, index: 0}}}
                rows[2] = {obj: HistoryPanel, params: {params: {parent: this, controller: this.view.controller, editable: true, index: 0}}}}
            else {
                rows[0] = {obj: CarrierPanel, params: {params: {parent: this, controller: this.view.controller, editable: true, index: 0}}}
                rows[1] = {obj: HistoryPanel, params: {params: {parent: this, controller: this.view.controller, editable: true, index: 0}}}
            }
        }
        if (this.view.controller?.load?.dispatcher?.id !== this.view.controller?.load?.payor?.id) {
            rows = this.add_after(rows, 1, {obj: Dispatcher, params: {params: {parent: this, controller: this.view.controller, editable: true, index: 0}}})
        }
        return rows
    }
    determine_state() {
        let saved = this.view.controller.getState({key: "data", param: "saved"})
        if (saved?.dispatched) {
            return true
        }
        return false
    }
    add_after(array, index, newItem) {
        return [
            ...array.slice(0, index),
            newItem,
            ...array.slice(index)
        ];
    }

}
