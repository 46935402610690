// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import { MdAdminPanelSettings } from "react-icons/md";

export default class UserIconController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    get user() {
        return this.view?.state?.user
    }
    get initials() {
        if(this.user?.given_name === undefined || this.user?.family_name === undefined) {
            return ""
        }
        return this.user?.given_name?.charAt(0) + this.user?.family_name?.charAt(0)
    }
    check_roles(roles) {
        if (roles !== undefined) {
            Object.keys(roles).forEach((key, value) => {
                if (roles[key].status === "APPLIED") {
                    this.setState({screen: 2})
                }
            })
        }
    }
    // <MdAdminPanelSettings />
    get_user_image(url) {
        if (url !== undefined && url !== null) {
            return (<img src={url} alt="Cota User" />)
        }
        return (<span className="user_initials">{this.initials}</span>)
    }
    get_user_badge() {
        if (this.check_role("INTERNAL_COTA_USER")) {
            return <MdAdminPanelSettings className="badge"/>
        }
    }
}