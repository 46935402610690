// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import ContactModelController from "controllers/ModelControllers/ContactModelController.jsx"
import PersonModelController from "controllers/ModelControllers/PersonModelController.jsx"
import graph_request from "assets/graphql/Contacts/Contacts.search.graphql.json"
import general_quotes from "assets/graphql/Quotes/Quotes.graphql.json"
import general_loads from "assets/graphql/Loads/floads.graphql.json"

export default class ContactDetailsController extends _viewController {
    constructor(props) {
        super(props)
        this.people     = []
        this.person = new PersonModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
        this.contact = new ContactModelController({params: {parent: this, 
                                                            controller: this, 
                                                            is_view: true}})
        // this.requests   = [{endpoint: "quotes", req_name: "general", replace: {o: "quotes(", r:'quotes( '}}]
        }
    get company() {
        return this.contact?.company
    }
    load_cache() {
        let key = Object.keys(this.panel_controller?.cache)[0]
        let cache = this.panel_controller.getCache(key)
        console.log("Loading Cache",cache,key,this)
        if (cache !== undefined) {
            this.setState({key:"panel_params", param: "data.id", value: cache.data?.id})
        }
    }
    process_roles(roles) {
        let formatted_roles = {}
        if (roles !== undefined && roles !== null) {
            roles.map((role, index) => {
                if (role?.name !== undefined) {
                    formatted_roles[role.name.toLowerCase()] = true
                }
                return
            })
        }
        return formatted_roles
    }
    load_harness() {
        this.load_contact(this.view?.props?.params?.panel_params)
    }
    async load_contact(params) {
        let id      = (params?.data?.id !== undefined) ? params?.data?.id : this.getState({key:"panel_params", param: "data.id"})
        if (id !== undefined) {
            let body    = JSON.parse(JSON.stringify(graph_request))
            body.query  = body.query.replace("contacts", 'contact(id: "'+id+'")')
            this.api.contacts.ask({caller: this, params: {body: body}, callback: {f: this.process_contact}, nocache: true})
        }
    }
    process_contact({caller, params, results}) {
        caller.people = results?.data?.contact?.people
        if (results?.data?.contact?.company !== undefined) {
            results.data.contact.company.name = results.data.contact.name
        }
        if (results?.data?.contact?.company?.notnull("employees")) {
            caller.people = [...caller.people, ...results?.data?.contact?.company?.employees]
        }
        let contact = {
            contact: results?.data?.contact,
            company: results?.data?.contact?.company
        }
        delete contact?.contact?.company
        if (contact?.contact !== undefined) {
            contact.contact.people = caller?.people
            contact.contact.roles = caller.process_roles(results?.data?.contact?.roles)
            caller.setState({key: "data", value: contact})
        }
        caller.load_quotes(contact?.company?.id)
        caller.load_loads(contact?.company?.id)
    }
    async load_quotes(id) {
        if (id !== undefined && id !== null) {
            let body    = JSON.parse(JSON.stringify(general_quotes))
            body.query  = body.query.replace("quotes(", 'quotes(customer: "'+id+'", ')
            this.api.quotes.ask({caller: this, params: {body: body}, callback: {p: {name: "quotes"}, f: this.process_quotes}, nocache: true})
        }
    }
    async load_loads(id) {
        if (id !== undefined && id !== null) {
            let body    = JSON.parse(JSON.stringify(general_loads))
            body.query  = body.query.replace("floads(l: 10, p: 0)", 'floads(customer: "'+id+'", l: 10, p: 0)')
            this.api.loads.ask({caller: this, params: {body: body}, callback: {p: {name: "floads"}, f: this.process_loads}, nocache: true})
        }
    }
    process_quotes({caller, params, results}) {
        if (results?.data?.quotes !== undefined && results?.data?.quotes !== null) {
            caller.setState({key: "data", param: "quotes", value: results?.data?.quotes})
        }
    }
    process_loads({caller, params, results}) {
        if (results?.data?.floads !== undefined && results?.data?.floads !== null) {
            caller.setState({key: "data", param: "floads", value: results?.data?.floads})
        }
    }
    get quotes() {
        return this.view.state.data.quotes
    }
    get loads() {
        return this.view.state.data.floads
    }

    editContact(page_key) {
        this.state?.parent?.selectPanel({panel_name: page_key, cache_data: this.state.data})
    }
    delete_contact() {
        this.api.contacts.delete({caller: this.controller?.view, value: this.controller?.contact?.id, callback: {f: this.controller.process_deletion}})
    }
    process_deletion({caller, params, results}) {
        caller.state?.parent?.backPanel()
    }
    get _data() {
        return this.getState({key: "data"});
    }
    delete_action({obj}) {
        this.carrier.documents.splice(obj?.index, 1)
    }
}
