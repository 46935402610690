// GENERAL REACT MODULES
import { Row, Col, Button } from 'react-bootstrap'

// PAGE SPECIFIC MODULES
import { accessorialsOptions } from "../Options.js"
import _baseComponent from "components/General/_baseComponent.jsx"

// ALL OTHER MODULES
import config_data from "../NewQuotePanel.json"
import SaveButton from "components/Loading/SaveButton/SaveButton.jsx"

export default class Ltlview extends _baseComponent {
    constructor(props) {
        super(props)
        this.state.form = config_data.form_objects
        this.controller = props.params?.controller
    }
    render() {
        return (
                <Row id={this.name}>
                    <Col>
                        <Row className="row_buffer row_2">
                            <hr />
                        </Row>
                        <Row>
                            <Col className="pallet_switch_form">
				                {this.controller.get_toggle("pallet_switch_form")}
                            </Col>
                        </Row>
                        <Row className="row_buffer row_4 mid_row">
                            <Col>
                                {this.controller?.load_pallet_window()}
                            </Col>
                        </Row>
                        <Row className="row_buffer row_5">
                            <Col>
                                <Row>
                                    <Col>
                                        Shipment Accessorials (Optional)
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.state.parent.formObject({data: this.state.form.optionSelectedAccessories, 
                                                                        owner: this, options: accessorialsOptions})}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        Reference Number (Optional)
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.state.parent.formObject({data: this.state.form.reference_number, owner: this, options: undefined})}
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={2} className="col_3 button_col_width">
                                <Row className={this.controller?.is_get_quote_avail()}>
                                    <Row>
                                        <Col className="QuoteResults">
                                            <Row className={this.controller?.is_hidden()}>
                                                <Col className='generic_override_flex'>
                                                    <Button className={"center_item button_row_buttons toggle_button_style "+this._theme} onClick={this.controller?.book_it.bind(this, "/booking")}>
                                                        Book It!
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row className={"generic_center "+this.controller?.is_hidden()}>
                                                <Col>
                                                    Or
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Row className="buffer_row">
                                                <Col>
                                                    &nbsp;
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='generic_override_flex'>
                                                    <SaveButton className={"center_item primary_button_coloring "+this._theme} onClick={this.controller?.get_quote.bind(this)}
                                                                params={{parent: this.state.parent, controller: this.controller}}>
                                                        {this.controller?.quote_button_label}
                                                    </SaveButton>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
        )
    }
}
