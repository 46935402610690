// GENERAL REACT MODULES
import { Container, Row, Col, Button } from 'react-bootstrap'

// PAGE SPECIFIC MODULES
import "./ShipmentDetails.css"
import data from "./ShipmentDetails.json"
import { ShipmentDetailsModel } from "./ShipmentDetailsModel.jsx"

// ALL OTHER MODULES
import "styles/generic.css"
import "styles/cota.css"
import MultiPanel from "components/MultiPanels/MultiPanel";
import grafana_config from "assets/grafana_config.json"

class ShipmentDetails extends MultiPanel {
    constructor(props) {
        super(props)
        this.state.panel_name = "shipment_details"
        this.state.panel_configs = grafana_config["pages"]["shipments"]
        this.state.subpanels = {
        }
        this.state.model = new ShipmentDetailsModel()
        this.state.data = this.get_data(data)
    }
    generateMilesColumn() {
        return (
            <>
                <Row className="g-0" >
                    <Col className="g-0" >
                        Miles
                    </Col>
                    <Col className="g-0" >
                        {this.state.data?.miles_data?.miles}
                    </Col>
                </Row>
                <Row className="g-0" >
                    <Col className="g-0" >
                        Stops
                    </Col>
                    <Col className="g-0" >
                        {this.state.data?.miles_data?.stops}
                    </Col>
                </Row>
            </>
        )
    }
    generateCommodities() {
        return(
            <>
            </>
        )
    }
    generateRatePanel() {
        return(
            <>
                <Col className="g-0 cota_panel_object_unsized inner_margin_right rate_panel" >
                    <Row className="rate_panel_label">
                        Rate
                    </Row>
                    <Row className="rate_panel_label">
                        ${this.state.model.rate_data()?.rate}
                    </Row>
                    <Row className="rate_panel_cost">
                        ${this.state.model.rate_data()?.per_mile}/mile
                    </Row>
                </Col>
            </>
        )
    }
    generateCO2Panel() {
        return( 
            <>
                <Col className="g-0 cota_panel_object_unsized inner_margin_left co2_panel" >
                    <Row className="co2_panel_amount">
                        {this.state.data?.co2?.amount}
                    </Row>
                    <Row className="co2_panel_label">
                        Pounds of CO2
                    </Row>
                </Col>
            </>
        )
    }
    map_equipment() {
        return(
            <>
                <Row className="g-0" >
                    <Col className="g-0" >
                        Van
                    </Col>
                    <Col className="g-0" >
                        Air-Ride
                    </Col>
                </Row>
            </>
        )
    }
    map_commotities() {
        return (
            <>
                <Row className="g-0" >
                    <Col className="g-0" >
                        Plastic Bottles
                    </Col>
                    <Col className="g-0" >
                        7 units
                    </Col>
                </Row>
                <Row className="g-0" >
                    <Col className="g-0" >
                        Plastic Cups
                    </Col>
                    <Col className="g-0" >
                        2,200 lbs.
                    </Col>
                </Row>
            </>
        )
    }
    returnColumnTwo() {
        return(
            <>
                <Row className="g-0" >
                    <Col className="cota_panel_object_unsized g-0 subpanel_common inner_margin_right distance_subpanel" >
                        {
                            this.generateMilesColumn()
                        }
                    </Col>
                    <Col className="g-0 subpanel_common inner_margin_left" >
                        <Row className="g-0" >
                            <Col className="g-0 booked_subpanel" >
                                Booked
                            </Col>
                            <Col className="g-0 heavy_font" >
                                Cota ID
                            </Col>
                            <Col className="g-0 heavy_font" >
                                {this.state.data?.cota?.id}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="g-0" >
                    <Col className="cota_panel_object_unsized g-0 subpanel_common" >
                        <Row className="g-0 column_two_titles" >
                            COMMODITY
                        </Row>
                        {this.map_commotities()}
                    </Col>
                </Row>
                <Row className="g-0" >
                    <Col className="cota_panel_object_unsized g-0 subpanel_common" >
                        <Row className="g-0 column_two_titles" >
                            EQUIPMENT
                        </Row>
                        {this.map_equipment()}
                    </Col> 
                </Row>
                <Row className="g-0 subpanel_common" >
                    {
                        this.generateRatePanel()
                    }
                    {
                        this.generateCO2Panel()
                    }
                </Row>
                <Row className="cota_panel_object_unsized g-0 subpanel_common" >
                    <Col className="g-0" >
                        <Row className="g-0 column_two_titles">
                            INFORMATION
                        </Row>
                    </Col>
                </Row>
                <Row className="cota_panel_object_unsized g-0 subpanel_common" >
                    <Col className="g-0" >
                        <Row className="g-0 column_two_titles">
                            DOCUMENTS
                        </Row>
                    </Col>
                </Row>
                <Row className="cota_panel_object_unsized g-0 subpanel_common" >
                    <Col className="g-0" >
                        <Row className="g-0 column_two_titles">
                            NOTES
                        </Row>
                        <Row className="g-0 column_two_notes" >

                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
    returnColumnThree(has_panels) {
        if (has_panels) {
            return(
                <>
                 <Col sm={1} className="g-0" >

                 </Col>
                </>
            )
        } else {
            return(
                <>
                    <Col sm={3} className="g-0" >
                        Col 3
                    </Col>
                </>
            )
        }
    }
    returnShipperPanel() {
        return(
            <>
                <Row className="subpanel g-0" >
                    <Col className="cota_panel_object_unsized" >
                        <Row className="g-0" >
                            <Col >
                                <Row className="g-0" >
                                    {this.state.data?.shipper?.name}
                                </Row>
                                <Row className="g-0" >
                                    {this.state.data?.shipper?.street_address_1}
                                </Row>
                                <Row className="g-0" >
                                    {this.state.data?.shipper?.street_address_2}
                                </Row>
                                <Row className="g-0" >
                                    {this.state.data?.shipper?.city}, {this.state.data?.shipper?.state} {this.state.data?.shipper?.zip}
                                </Row>
                            </Col>
                            <Col>
                                <Row className="g-0" >
                                    *
                                </Row>
                                <Row className="g-0" >
                                    {this.state.data?.shipper?.phone_number}
                                </Row>
                                <Row className="g-0" >
                                    {this.state.data?.shipper?.email}
                                </Row>
                                <Row className="g-0" >

                                </Row>
                            </Col>
                        </Row>
                        <Row className="g-0" >
                            <Col>
                                <Row className="g-0" >
                                    <Col>
                                        {this.state.data?.shipper?.open_hours}
                                    </Col>
                                    <Col>
                                        -
                                    </Col>
                                    <Col>
                                        {this.state.data?.shipper?.close_hours}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row className="g-0" >
                                    Appointment
                                </Row>
                                <Row className="g-0" >
                                    <Col>
                                        {this.state.data?.shipper?.datestamp}
                                    </Col>
                                    <Col>
                                        2pm
                                    </Col>
                                </Row>
                                <Row className="g-0" >
                                    Pickup # {this.state.data?.shipper?.pickup_id}
                                </Row>
                            </Col>
                        </Row>
                        <Row className="g-0" >
                            <Col>
                                {this.state.data?.shipper?.contact?.first_name} {this.state.data?.shipper?.contact?.last_name}
                            </Col>
                            <Col>
                                {this.state.data?.shipper?.contact?.phone_number} 
                            </Col>
                            <Col>
                                {this.state.data?.shipper?.contact?.email} 
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={2} className="sub_subpanel title_column">
                        Shipper
                    </Col>
                </Row>
            </>
        )
    }
    loadInnerFrame() {
        return (<>
            <Container className={this.offsetWrapper()} fluid="true">
                <Row className="max_row border_bottom " >
                    <Col>
                        <Row className="company_panel g-0" >
                            <Col className="cota_panel_object_unsized company_panel_column">
                            
                            </Col>
                            <Col className="sub_company_panel title_column" sm={2}>
                                Payor
                            </Col>
                        </Row>
                        {this.returnShipperPanel()}
                        <Row className="subpanel g-0" >
                            <Col className="cota_panel_object_unsized">
                                <Row className="g-0" >
                                    <Col>
                                        <Row className="g-0" >
                                            Cota Freight
                                        </Row>
                                        <Row className="g-0" >
                                            XXXX State St
                                        </Row>
                                        <Row className="g-0" >
                                            Suite 100
                                        </Row>
                                        <Row className="g-0" >
                                            Carlsbad, CA 92008
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row className="g-0" >
                                            *
                                        </Row>
                                        <Row className="g-0" >
                                            555.555.5555
                                        </Row>
                                        <Row className="g-0" >
                                            freight@cotasystem.com
                                        </Row>
                                        <Row className="g-0" >

                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="g-0" >
                                    <Col>
                                        <Row className="g-0" >
                                            <Col>
                                                8am
                                            </Col>
                                            <Col>
                                                -
                                            </Col>
                                            <Col>
                                                5pm
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row className="g-0" >
                                            Appointment
                                        </Row>
                                        <Row className="g-0" >
                                            <Col>
                                                04/08/2022
                                            </Col>
                                            <Col>
                                                2pm
                                            </Col>
                                        </Row>
                                        <Row className="g-0" >
                                            Pickup # 39010
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="g-0" >
                                    <Col>
                                        Jeremy Vrchota
                                    </Col>
                                    <Col>
                                        555.555.5555
                                    </Col>
                                    <Col>
                                        jeremy@cotasystem.com
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={2} className="sub_subpanel title_column">
                                Stop
                            </Col>
                        </Row>
                        <Row className="subpanel g-0" >
                            <Col className="cota_panel_object_unsized">
                                <Row className="g-0" >
                                    <Col>
                                        <Row className="g-0" >
                                            Cota Freight
                                        </Row>
                                        <Row className="g-0" >
                                            XXXX State St
                                        </Row>
                                        <Row className="g-0" >
                                            Suite 100
                                        </Row>
                                        <Row className="g-0" >
                                            Carlsbad, CA 92008
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row className="g-0" >
                                            *
                                        </Row>
                                        <Row className="g-0" >
                                            555.555.5555
                                        </Row>
                                        <Row className="g-0" >
                                            freight@cotasystem.com
                                        </Row>
                                        <Row className="g-0" >

                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col>
                                        <Row className="g-0" >
                                            <Col>
                                                8am
                                            </Col>
                                            <Col>
                                                -
                                            </Col>
                                            <Col>
                                                5pm
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row className="g-0" >
                                            Appointment
                                        </Row>
                                        <Row className="g-0" >
                                            <Col>
                                                04/08/2022
                                            </Col>
                                            <Col>
                                                2pm
                                            </Col>
                                        </Row>
                                        <Row className="g-0" >
                                            Pickup # 39010
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="g-0" >
                                    <Col>
                                        Jeremy Vrchota
                                    </Col>
                                    <Col>
                                        555.555.5555
                                    </Col>
                                    <Col>
                                        jeremy@cotasystem.com
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={2} className="sub_subpanel title_column">
                                Consignee
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={1} className="g-0 slim_column" >
                        <Row className="g-0 company_panel_column_buffer" >
                            
                        </Row>
                        <Row className="g-0 sub_subpanel_buffer" >
                            <div className="vl top_buffer_row" />
                            <div className="v_ball" />
                        </Row>
                        <Row className="g-0 sub_subpanel_buffer" >
                            <div className="vl" />
                            <div className="v_ball" />
                        </Row>
                        <Row className="g-0 sub_subpanel_buffer" >
                            <div className="vl bottom_buffer_row" />
                            <div className="v_ball" />
                        </Row>
                    </Col>
                    <Col sm={3} className="g-0 center_column" >
                        {
                            this.returnColumnTwo()
                        }
                    </Col>
                    <Col sm={1} className="g-0 slim_column" >
                        <div className="v2" />
                    </Col>
                    {
                        this.returnColumnThree(true)
                    }
                </Row>
                <Row className="g-0" >
                    <Col className="g-0" >
                    </Col>
                    <Col m={3} className="g-0 button_col" >
                        <Row className="g-0" >
                            <Col className="g-0" >
                                <Button className="light_cota_button">
                                    Duplicate
                                </Button>
                            </Col>
                            <Col>
                                <Button className="light_cota_button">
                                    Post Load
                                </Button>
                            </Col>
                            <Col className="g-0" >
                                <Button>
                                    Find Carrier
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>)
    }
}

export default ShipmentDetails;