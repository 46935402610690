// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import CompanyModelController from "controllers/ModelControllers/CompanyModelController";

export default class FourthPanelController extends _viewController {
    constructor(props) {
        super(props)
        this.company = new CompanyModelController({params: {parent: this, 
                                                        controller: this,
                                                        is_view: true}})
        this.requests   = [{callback: {f: this.state_results, p: {name: "areas", var: ["areas", "states"]}}, 
                                endpoint: "areas", replace: {o: "params:", r:'params: {country: "US"}'}}]
    }
    async follow_on_selection({event, obj, data}) {
        if (data?.name === "zip") { 
            // Live has this.company
            this.carrier.zip        = obj.zip
            this.carrier.city       = obj.city
            this.carrier.state      = obj.state
            this.carrier.country    = obj.country
        }
        if (data?.name === "address") {
            // Live has this.company
            this.carrier.address    = obj?.address
            this.carrier.address_2  = obj?.address_2
            this.carrier.zip        = obj?.location?.zip
            this.carrier.city       = obj?.location?.city
            this.carrier.state      = obj?.location?.state
            this.carrier.country    = obj?.location?.country
            this.setState({key: "search",
                            param: "zip.selected_value",
                            value: {zip: obj?.location?.zip} })
        }
    }
    load_cache() {
	    console.log(this.panel_controller?.cache)
        if (this.panel_controller?.cache?.["2"]?.data !== undefined) {
            if (this.view?.state?.data?.company?.name === undefined) {
                this.setState({key: "data", param: "company.name", value: this.panel_controller.cache["2"]?.data})
            }
        }
    }
    state_results({caller, params, results}) {
        if (results?.errors === undefined && results?.data?.areas !== undefined) {
            results.data?.areas?.forEach((area, index) => {
                caller.view.state.states.push({ value: area.state, label: area.state })
            })
        }
    }
    async submit_action() {
        let sb_config = {success: {show: false}}
        this.api.companies.create({caller: this.controller, value: this.controller.toUnquotedJSON(this.controller.company.summary.scrub()), callback: {f: this.controller.process_creation, sb: sb_config} })
        // this.panel_controller.state.parent.state.data.user.companies.push(company)
        // this.panel_controller.state.parent.forceUpdate()
        // this.controller?.panel_controller?.selectPanel({panel_num: 0, cache_data: undefined})
    }
    process_creation({caller, params, results}) {
	    console.log(results)
        // caller.panel_controller.state.parent.state.data.user.companies.push(company)
        caller.panel_controller.state.parent.forceUpdate()
        caller.controller?.panel_controller?.selectPanel({panel_num: 0, cache_data: undefined})        
    }
    cancel_action() {
        this.controller?.panel_controller?.selectPanel({panel_num: 0, cache_data: undefined})
    }
}
