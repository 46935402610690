// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import CompanyModelController from "controllers/ModelControllers/CompanyModelController.jsx"
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class PayorController extends _viewController {
    constructor(props) {
        super(props)
        this.company = new CompanyModelController({params: {parent: this, 
                                                            controller: this,
                                                            is_view: true}})
    }
    load_harness() {
        if (this.check_role("INTERNAL_COTA_USER")) {
            this.view.state.closeable = false
        }
    }
    close() {
        if (this.check_role("INTERNAL_COTA_USER")) {
            this.controller.setState({key: "search", param: "company", value: {}})
            this.partial_controller.setState({key: "data", param: "company", value: {id: null}})
            if (this.controller?.company !== undefined) {
                this.controller.company.update({key: "data", value: {id: null}})
            } else {
                this.controller.setState({key: "data", param: "company", value: {id: null}})
            }
        }
    }
    follow_on_selection({event, obj, data}) {
        this.setState({key: "data", param: "company", value: obj})
        // this.view.controller.company.data = obj
        if (this.controller?.company !== undefined) {
            this.controller.company.update(obj)
        } else {
            this.view.controller.setState({key: "data", param: "company", value: obj})
        }
    }
    get_payor_image(url) {
        if (url !== undefined && url !== null) {
            if(url?.size){
              
             return   <img className="cota_payor_image" alt="Cota Company" src={URL.createObjectURL(url)}></img>
            }
            return (<img src={url} className="cota_payor_image" alt="Cota Company"/>)
        }
        return (<div className="cota_payor_image" ></div>)
    }
    not_blank(value) {
        if (value !== undefined && value !== null && value !== "") {
            return true
        }
        return false
    }
}
