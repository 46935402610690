// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class RequestDocController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    wrap_request() {
        if (this.view.state?.use_modal) {
            // this.controller.request_document()
            this.view.toggle_modal({toggle_state: true})
        }
    }
    get emails() {
        return this.view.state.emails
    }
    submit_request({generate_only}) {
        this.modal.setState({is_sending: true})
        this.controller.request_documents({generate_only: generate_only})
        setTimeout(function() { //Start the timer
            this.modal.setState({is_sending: false})
            this.view.toggle_modal({toggle_state: false}) //After 1 second, set render to true
        }.bind(this), 1000)
    }
}