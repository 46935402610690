// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
// import { trailerOptions, requirementsOptions } from "./Options.js"
// import ModalController from "./Equipment.modal.controller.jsx"
import _baseModal from "components/General/_baseModal.jsx"
import configs from "./Equipment.json"
import "./Equipment.css"

export default class EquipmentModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.form = configs.form_objects
        this.state.trailer_types        = []
        this.state.special_requirements = []
        this.modal_controller = this.state.parent.modal_controller
        this.state.modal_params = {
            positioned: "middle_of_panel",
            title: "Equipment",
            title_size: "small",
            x_adjustment: "0",
            y_adjustment: "-100px",
            has_overlay: false,
            close_on_outside_click: false,
            closeable: true,
            draggable: true
        }
    }
    get equipment() {
	    return this.controller.equipment
    }
    renderView() {
        return (
                <>
                    <Row className="modal_width">
                        <Col>
                            <Row className="flex-nowrap padding">
                                <Col>
                                    Trailer Type*
                                </Col>
                                <Col className="flex-nowrap">
                                    Special Requirements
                                </Col>
                            </Row>
                            <Row className="flex-nowrap input_row_padding">
                                <Col>
                                        <div className="absolute input_width">
                                            {this.controller?.view?.formObject({data: this.state.form.trailer_types, owner: this, options: this.state?.parent?.state?.trailer_types})}
                                        </div>
                                </Col>
                                <Col>
                                        <div className="absolute input_width">
                                            {this.controller?.view?.formObject({data: this.state.form.requirements, owner: this, options: this.state?.parent?.state?.special_requirements})}
                                        </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row classID="row_padding">
                        <Col className="button_col">
                            {/* <Button className={"primary_button_coloring "+this._theme}> */}
                            <Button className={"primary_button_coloring "+this._theme} onClick={this.modal_controller.add_object.bind(this)}>
                                Done
                            </Button>
                        </Col>
                    </Row>
                </>
        )
    }
}
