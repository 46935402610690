// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import StopModelController from "controllers/ModelControllers/StopModelController.jsx"

export default class ShipperPartialController extends _viewController {
    constructor(props) {
        super(props)
        this.details        = undefined
        this.details_cache  = new StopModelController({params: {parent: this, 
                                                   controller: this, _nested: undefined,
                                                   is_view: true}})
    }
    load_details() {
        this.details = new StopModelController({params: {parent: this,
                                                    controller: this.view?.controller,
                                                    _nested: undefined,
                                                    index: this.view.props?.params?.index,
                                                    is_view: true, _param: this.view?.state?.type}})
    }
    get contact() {
	    return this.details?.contact
    }
    get company() {
        return this.details?.contact?.company
    }
    get role_object() {
        if(Array.isArray(this.controller.view.state.data[this.view.type])) {
            return this.controller.view.state.data[this.view.type]?.[this.view.index]
        }
        return this.controller.view.state.data[this.view.type]
    }
    async follow_on_selection({event, obj, data}) { 
	    console.log("SELECTION")
    }
    load_cache() {
        let cache_0 = this.panel_controller?.getCache("0", {k:"type", v: this.view?.state?.type})
        if (cache_0 !== undefined) {
            this.api.contacts.gid({caller: this, value: cache_0?.data?.id, callback: {f: this.process_loaded_contact, p: {name: this.view?.state?.type}}})
        }
    }
    process_loaded_contact({caller, params, results}) {
	    caller.controller.process_loaded_contact({caller: caller.controller, params: params, results: results})
    }
    selectRender() {
        let data = this.controller.getState({key: "data"})?.[this.state?.type+"_details"]
        let contact = this.controller.getState({key: "data"})?.[this.state?.type]
        this.details._load({data: data, sub_objects: {contact: contact}})
        // let data = this.controller.getState({key: "data"})?.[this.state?.type]
        if (contact === undefined || Object.keys(contact).length === 0) {
            return this.renderInput()
        }
        return this.renderDetails(this.details)
    }
    process_date({date, word}) {
        if (date !== undefined) {
            var splitStr = (""+date+"").split(word);
		    return splitStr[0]
        }
        return ""
    }
    editContact() {
        // this.state?.parent?.selectPanel({panel_name: page_key, cache_data: this.state.data})
        if (typeof this.controller.save_action === "function") {
            this.controller.save_action()
        }
        this.panel_controller?.selectPanel({panel_num: 1, cache_data: {type: this.state?.type, current: {id: this.controller.load?._id}, contact: this.controller.getState({key: "data"})?.[this.state?.type]}})
    }
    reset_contact() {
        if (this.props?.params?.index === undefined) {
            this.controller.setState({key: "search", param: this.state?.type, value: {}})
            this.controller.setState({key: "data", param: this.state?.type, value: {}})
        } else {
            let index = this.props?.params?.index
            let objs = this.controller.getState({key: "data", param: this.state?.type})
            objs[index] = {}
            // objs.splice(this.props?.params?.index, 1)
            this.controller.setState({key: "data", param: this.state?.type, value: objs})
            this.controller.view.forceUpdate()
            this.state.parent?.column?.forceUpdate()
	    }
    }
    reset_rep() {
        this.controller[this.state.type].rep = undefined
	    this.forceUpdate()
    }
    select_person(index) {
	    this.details.rep = index	
	    // this.controller[this.state.type].rep = index
        this.toggle_modal({toggle_state: undefined})
    }
    save_action() {
        let summary = this.partial_controller.details_cache.summary.scrub()
        delete summary.stop_type
        if (this.partial_controller.view.state.type === "stops") {
            let index = this.partial_controller.details.index
            let data = this.controller.stops[index]
            Object.keys(summary).forEach((k, i) => {
                data[k] = summary[k]
            })
            this.controller.view.state.data.stops[index] = data
            this.parent.forceUpdate()
        } else {
            Object.keys(summary).forEach((k, i) => {
                this.partial_controller.details[k] = summary[k]
            })
        }
        this.toggle_modal({toggle_state: undefined})
    }
    cancel() {
        this.toggle_modal({toggle_state: undefined})
    }
}
