// GENERAL REACT MODULES
import React from "react";

// PAGE SPECIFIC MODULES
import "./Home.css"
import Controller from "./Home.controller.jsx"
import ShipmentsMenu from "./ShipmentsPanel/ShipmentsMenu/ShipmentsMenu.view.jsx";
import SearchCarriersMenu from "./SearchCarrierPanel/SearchCarriersMenu.view.jsx";
import ShipmentsPanelView from "./ShipmentsPanel/ShipmentsPanelView/ShipmentsPanelView.view.jsx"
import SearchCarriers from "./SearchCarrierPanel/SearchCarriers/SearchCarriers.view.jsx";
import CotaContactsView from "./CotaContactsPanel/CotaContactsView/CotaContactsView.view.jsx";
import CarrierTripsView from "./CarrierTripsPanel/CarrierTripsView/CarrierTripsView.view.jsx";

import FirstPanel from "./NewCompany/FirstPanel/FirstPanel.view.jsx"
import SecondPanel from "./NewCompany/SecondPanel/SecondPanel.view.jsx"
import ThirdPanel from "./NewCompany/ThirdPanel/ThirdPanel.view.jsx"
import FourthPanel from "./NewCompany/FourthPanel/FourthPanel.view.jsx"
import ApplicationStatus from "./ApplicationStatus/ApplicationStatus.view.jsx";
import BookingPanel from "../Quotes/BookingPanel/BookingPanel.view"

// ALL OTHER MODULES
import _basePanelView from "components/General/_basePanelView.jsx"
import { MainConsumer } from "controllers/MainContext"
import { PanelController } from "controllers/PanelController/PanelController.jsx";
import MainContext from "controllers/MainContext.jsx";
import { Row, Col, Button } from "react-bootstrap";
import config_data from "./Home.json"
import NewQuotePanel from "../Quotes/NewQuotePanel/NewQuotePanel.view.jsx";
import CotaListPanel from "components/CotaListPanel/CotaListPanel.partial.jsx"
import UserIcon from "components/CotaUserBar/UserIcon/UserIcon.partial.jsx"

export const HomeConfig = function() {
    return "test"
}
export const Panels = {"ShipmentsPanel": {"menu_bar": ShipmentsMenu, "view": ShipmentsPanelView, controller: this},
                        "CotaContactsPanel": {"menu_bar": undefined, "view": CotaContactsView},
                        "SearchCarriersPanel": {"menu_bar": SearchCarriersMenu, "view": SearchCarriers},
                        "CarrierTripsPanel": {"menu_bar": undefined, "view": CarrierTripsView}}

class Home extends _basePanelView {
    constructor(props) {
        super(props)
        this.state.config_data  = config_data
        this.state.form         = config_data.form_objects
        this.controller         = new Controller({params: {view: this}})
        this.state.screen       = 0
        this.state.panels       = [{key: 0, obj: FirstPanel},
                                        {key: 1, obj: SecondPanel},
                                        {key: 2, obj: ThirdPanel},
                                        {key: 3, obj: FourthPanel},
                                        {key: 4, obj: NewQuotePanel, params: {panel_params: {is_closeable: true, close_title: "Back Home", close_func: "resetScreen"}}},
                                        {key: 5, obj: BookingPanel, params: {panel_params: {is_closeable: true, close_title: "Back Home", close_func: "handleCloseScreen"}}}
                                    ]
        this.state.channel_name     = ["grafana_row"]
        this.state.windows	= [{title: "Shipments", className: "panelWrapper", display: true, panel: Panels["ShipmentsPanel"]}]
        this.state.unloaded     = [{ title: "Carriers", className: "panelWrapper", display: true, panel: Panels["CotaContactsPanel"]},
                                    {title: "Search Carriers", className: "panelWrapper", display: true, panel: Panels["SearchCarriersPanel"]},
                                    {title: "Carrier Trips", className: "panelWrapper", display: true, panel: Panels["CarrierTripsPanel"]}]
                                    
        this.state.panel_trees  = [{key: 0,
                                        base: 0,
                                        paths: [{
                                            "reject": 0,
                                            "join": 0,
                                            "confirm": 1,
                                            "new": 2,
                                            "create": 3,
                                            "start": 0,
                                            "/booking": 4,
                                        }]},        {key: 1,
                                            name: "get_new_quote",
                                            base: 4,
                                            paths: [{
                                                "/booking": 4,
                                                "get_quote": 2,
                                                "pickup": 1,
                                                "delivery": 1,
                                                "save_action": 0
                                            }]}]
        this.state.controller = {
            toggles: {
                shipmentsList: false
            },
            panelObjects: {
                company: {value: undefined}
            }
        }
    }
    renderView() {
        this.state.context.page = this
        switch (this.state.screen) {
            case 0:   
                return(
                    <>
                        {this.renderMain()}
                    </>
                )
            case 1:
                return(
                    <>
                        <PanelController params={{parent: this, 
                                                    panel_name: "company",
                                                    tree: this.state.panel_trees[0],
                                                    path: 0,
                                                    panels: this.state.panels}}/>
                    </>
                )
            case 2:
                return(
                    <>
                        <ApplicationStatus />
                    </>
                )

                case 3:
                    return(
                        <>
                        <PanelController params={{parent: this, 
                                                    panel_name: "quotes",
                                                    tree: this.state.panel_trees[1],
                                                    path: 1,
                                                    panels: this.state.panels}}/>
                    </>
                    )
            default:
                return(<></>)
        }
    }
    async load_user() {
        if (this.state.data.user === undefined) {
            this.state.data.user    = this.controller.getCopy(await this.state?.context?.get_self({caller: this.controller, update: true}))
            this.forceUpdate()
        }
    }
    get panel_height() {
        return 80
    }
    renderMain() {
        return (
            <MainConsumer>
                {(context) => {
                    this.load_user()
                    // Refactor to use Modal System once finished
                    if(this.state.data.user === undefined || this.state.data.user === null) {
                        return (
                            <></>
                        )
                    }
                    if (this.state.data?.user?.companies?.length > 0) {
                        return(
                            <>
                                <Row id={this.name}>
                                    <Col className="min_column">
                                        <Button onClick={this.handleScreen.bind(this, {path_num: 1, screen_num: 3})} className={"primary_button_coloring "+this._theme}>
                                            Get New Quote
                                        </Button>
                                    </Col>
                                    <Col>
                                    
                                    </Col>
                                    <Col sm={1} className="dropdown_col dropdown_menu">
                                        {this.formObject({data: this.state?.form?.quick_links, options: this.controller?.options, owner: this})}
                                    </Col>
                                    <Col>
                                        &nbsp;
                                    </Col>
                                    <UserIcon params={{url: this.state?.data?.user?.image?.[0]?.url, user: this.state?.data?.user, 
                                                        parent: this, roles: this.context?.authClient?.idTokenParsed?.roles,
                                                        context: this.context}}/>
                                    <Col sm={1} className="user_label user_label_row cap_sentance">
                                        {this.state.data?.user?.name}
                                    </Col>
                                </Row>
                                <Row className="mainHomeScreen pageWrapper cota_panel_wrapper">
                                    <Col className="g-0 half_screen">
                                        <Row>
                                            <Col className="panel_title">
                                                {config_data?.panels?.shipments?.title}
                                            </Col>
                                        </Row>
                                        <Row className="panel_padding">
                                            <Col>
                                                <CotaListPanel params={{parent: this,
                                                            bordered: true,
                                                            panel_name: this.state.panel_name,
                                                            query_params: "id, total_amount, status, carrier, pickup",
                                                            configs: config_data?.panels?.shipments,
                                                            endpoint: "loads",
                                                            half: true,
                                                            query: "floads" }}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="g-0 half_screen">
                                        <Row>
                                            <Col className="panel_title">
                                                {config_data?.panels?.shipments?.title}
                                            </Col>
                                        </Row>
                                        <Row className="panel_padding">
                                            <Col>
                                                <CotaListPanel params={{parent: this,
                                                            bordered: true,
                                                            panel_name: this.state.panel_name,
                                                            configs: config_data?.panels?.carriers,
                                                            endpoint: "carriers",
                                                            half: true,
                                                            req_name: "min_list",
                                                            query: "fcarriers" }}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="mainHomeScreen pageWrapper cota_panel_wrapper">
                                    <Col className="g-0 half_screen">
                                        <Row>
                                            <Col className="panel_title">
                                                {config_data?.panels?.search_carriers?.title}
                                            </Col>
                                        </Row>
                                        <Row className="panel_padding">
                                            <Col>
                                                <CotaListPanel params={{parent: this,
                                                        bordered: true,
                                                        panel_name: this.state.panel_name,
                                                        configs: config_data?.panels?.search_carriers,
                                                        endpoint: "carriers",
                                                        half: true,
                                                        req_name: "min_list",
                                                        query: "fcarriers" }}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="g-0 half_screen">
                                        <Row>
                                            <Col className="panel_title">
                                                {config_data?.panels?.trips?.title}
                                            </Col>
                                        </Row>
                                        <Row className="panel_padding">
                                            <Col>
                                                <CotaListPanel params={{parent: this,
                                                        bordered: true,
                                                        panel_name: this.state.panel_name,
                                                        query_params: "id, company, pickup",
                                                        configs: config_data?.panels?.trips,
                                                        endpoint: "loads",
                                                        half: true,
                                                        query: "floads" }}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </>
                        )
                    } else {
                        if (this.state.data?.user?.roles?.length > 0) {
                            return (
                                <ApplicationStatus />
                            )
                        } else {
                            return(
                                <PanelController params={{parent: this,
                                                        tree: this.state.panel_trees[0],
                                                        path: 0,
							                            _panel_index: 0,
                                                        panels: this.state.panels }}/>
                            )
			            }
                    }
                }}
            </MainConsumer>
        )
    }
}
Home.contextType = MainContext
export default Home;
