// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./Dispatcher.controller.jsx"
import "./Dispatcher.css"

export default class Dispatcher extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this, controller: this.controller}})
    }
    renderView() {
        if (this.controller.load.status.toUpperCase() === "DISPATCHED_CARRIER" || this.controller.load.status.toUpperCase() === "DISPATCHED" || this.controller.load.status.toUpperCase() === "BILLED" || this.controller.load.status.toUpperCase() === "DELIVERED") {
            return (
                <>
                    <Row id={this.name} className="row_padding">
                        <Col className="cota_panel_object_unsized cota_panel_border max_block dispatch_panel">
                            <Row className="dispatch_title">
                                <Col>
                                    Dispatcher
                                </Col>
                            </Row>
                            <Row className="generic_center">
                                <Col>
                                  {this.controller.load?.dispatcher?.name}
                                </Col>
                            </Row>
                            <Row className="generic_center title">
                                <Col>
                                  Last Updated by
                                </Col>
                            </Row>
                            <Row className="generic_center">
                                <Col>
                                  {this.controller.load?._editor} ({this.controller.load?._edited})
                                </Col>
                            </Row>
                            <Row className="generic_center title">
                                <Col>
                                  Created by
                                </Col>
                            </Row>
                            <Row className="generic_center">
                                <Col>
                                  {this.controller.load?._creator}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            )
        }
        return (<></>)
    }
}
