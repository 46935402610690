// GENERAL REACT MODULES
import _baseComponent from "components/General/_baseComponent.jsx"
import { Row, Col } from 'react-bootstrap'

// ALL OTHER MODULES
import config_data from "../NewQuotePanel.json"

export default class PalletsView extends _baseComponent {
    constructor(props) {
        super(props)
	    this.state.form = config_data.form_objects
    }
    render() {
        return (
            <>
                <Row className="buffer_pallet_row" id={this.name}>
                    <Col />
                    <Col sm={2} className='total_columns'>
                        <Row>
                            <Col className="pallet_partial_title">
                                Total Weight ({this.controller.weight_label})
                            </Col>
                        </Row>
                        <Row>
                            {this.controller.view.formObject({data: this.state.form?.total_weight, 
                                                            owner: this})}
                        </Row>
                    </Col>
                    <Col sm={2} />
                    <Col sm={2} className='total_columns'>
                        <Row>
                            <Col className="pallet_partial_title">
                                Total Volume ({this.controller.volume_label}<sup className="cubed">3</sup>)
                            </Col>
                        </Row>
                        <Row>
                            {this.controller.view.formObject({data: this.state.form?.total_volume, 
                                                            owner: this})}
                        </Row>
                    </Col>
                    <Col />
                </Row>
                <Row>
                    <Col className="annotation">
                        * Weights exceeding 2500 lbs (1133 kg), or are determined to require an excess of 6 pallets will be ineligible for automatic Quoting.
                    </Col>
                </Row>
            </>
        )
    }
}
