// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import CommodityModelController from "controllers/ModelControllers/Shipping/CommodityModelController.jsx"

export default class CommodityModalController extends _viewController {
    constructor(props) {
        super(props)
        this._configs = {
            local: "commodity",
            array: "commodities"
        }
        this.commodity = new CommodityModelController({params: {parent: this, 
                                                        controller: this, 
                                                        index: 0,
                                                        is_view: true}})
    }
    new_object() {
        return new CommodityModelController({params: {parent: this, 
                                                    controller: this, 
                                                    index: 0,
                                                    is_view: true}})
    }
    clear_object() {

    }
    delete_commodity({index}) {
        console.log("DELETE")
        console.log(this)
        // this.controller._delete({name: this?._configs?.array, index: index})
    }
    add_object() {
	    console.log(this.modal_controller?.commodity?.summary)
        // this.controller.view.state.data.commodities.push(JSON.parse(JSON.stringify(this.modal_controller?.commodity?.summary)))
        // this.modal_controller.setState({key: "data", param: "commodities", value: [{}]})
	    // this.controller.view.setState({commodities: {}})
    }
    resolve_total_length(commodities) {
        let commodities_length = 0
        for (const comm of commodities) {
            if (comm !== undefined) {
                let remainder   = 0
                let quantity    = comm.quantity
                let length      = comm.length
                if (comm.quantity % 2) {
                    remainder   = 1
                    quantity    = quantity - 1
                }
                commodities_length = commodities_length + ((parseInt(length) * parseInt(quantity))) / 2 + (remainder * length);
            }
        }
        if (this.view.state.imperial) {
            if ((commodities_length / 12) > 2) {
                return commodities_length.toString() + "in ("+( Math.round((commodities_length / 12) * 10) / 10).toString()+"ft)"
            }
        }
        return commodities_length.toString() + ((this.view.state.imperial) ? "in" : "mm")
    }
}
