// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import summary_request from "assets/graphql/Summary/summary.graphql.json"

export default class CotaTotalsBarController extends _viewController {
    constructor(props) {
        super(props)
        this.requests       = [{callback: {f: this.process_summary}, endpoint: "summary"}]
    }
    generate_panel_row({title, metric, index}) {
        return (
            <Row key={"total_sub_title_"+index}>
                <Col className={"g-0 limit_title"}>
                    {metric?.name}
                </Col>
                <Col sm={1}>
                    {metric?.count}
                </Col>
            </Row>
        )
    }
    async load_summary(context) {
        let body    = JSON.parse(JSON.stringify(summary_request))
        this.api.summary.ask({caller: this, params: {body: body}, callback: {f: this.process_summary}})
    }
    process_summary({caller, params, results}) {
//        caller.view.state.summary = results?.data?.summary
        if (caller.view.state.summary === undefined) {
            caller.view.setState({summary: results?.data?.summary})
        }
    }
    render_summary() {
        if (this.view?.state?.summary !== undefined) {
            return Object.keys(this.view?.state?.summary).map((panel, index) => {
                return this.generate_bar_row({title: panel, obj: this.view?.state?.summary?.[panel], index: index})
            })
        }
    }
    generate_bar_row({title, obj, index}) {
        if(obj === null) { return (<></>) }
        if (obj !== undefined && Object.keys(obj).length > 0) {
            return (
                <Row key={"total_title_"+index} className="remove_margins">
                    <Col className="g-0">
                        <Row className="title_sub_panel center_text">
                            {title}
                        </Row>
                        <Row className={"add_shadow cota_panel_object total_sub_panel remove_margins total_sub_panel_color "+this.view?.state?.context?.user?.preferences?.theme}>
                            {
                                Object.keys(obj).map((metric, index) => {
                                    return this.generate_panel_row({title: title, metric: obj[metric], index: index})
                                })
                            }
                        </Row>
                    </Col>
                </Row>
            )
        }
    }
}

