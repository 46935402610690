// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class WebObjectModalController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    toggle_share() {
        this.setState({share_mode: !this.state.share_mode})
    }
    get is_private() {
        return this.view.state.is_private ?? true
    }
    delete_file(id) {
        this.api.upload.file_delete({caller: this, id: id, callback: {f: this.modal_controller.process_downloaded_file}})
    }
    process_deleted_file({caller, results, params}) {
        if (typeof caller.view?.state?.parent?.controller?.delete_action === "function") {
            caller.view?.state?.parent?.controller?.delete_action({obj: caller.view?.state?.item})
        }
        caller.view?.state?.parent?.toggle_modal({})
    }
    download_file(url) {
        if (url !== undefined) {
            this.api.upload.file_download({caller: this, url: url, is_private: true, callback: {f: this.modal_controller.process_downloaded_file}})
        }
    }
    process_downloaded_file({caller, results, params}) {
        if(results?.errors !== undefined) {
            console.log("Error downloading file", results.errors)
            return
        }
        const a = document.createElement("a")
        a.href = "data:"+caller?.state?.item?.item?.resource_type+";base64,"+results
        a.download = caller?.state?.item?.item?.name
        a.click()
        document.body.appendChild(a)
    }
}
