// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class ShareButtonController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    wrap_request() {
        if (this.view.state?.use_modal) {
            this.view.toggle_modal({toggle_state: true})
        }
    }
    submit_request() {
        this.view.toggle_modal({toggle_state: true})
    }
    copy({link}) {
        navigator.clipboard.writeText(link)
    }
}
