// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import name_request from "assets/graphql/Carriers/Carriers.names.graphql.json"

export default class HomeController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    change_date_value(dates) {
        if (dates?.[0] !== undefined) {
            this.state.from_date = new Date(dates[0]).valueOf()
        } else {
            this.state.from_date = undefined
        }
        if (dates?.[1] !== undefined) {
            this.state.to_date = new Date(dates[1]).valueOf()
        } else {
            this.state.to_date = undefined
        }
        this.forceUpdate()
    }
    // follow_on_selection({event, obj, data}) {
    //     this.view.empty_results(this.view?.state?.form?.user_filter)
    //     this.view.handleScreen({path_num: 0, screen_num: 2, data: obj})
    // }
    processEventReaction(decoded) {
        this.setState({key: "data", value: decoded.message.data})
        this.view.handleScreen({path_num: 0, screen_num: 2, data: decoded.message.data})
    }
    async get_users(name) {
        name        = (name === undefined) ? "" : name
        let body    = JSON.parse(JSON.stringify(name_request))
        let results = await this.api.carriers.ask({caller: this, params: {body: body}})
        results?.data?.carriers?.forEach((cnt, index) => {
            this.view.state.user_options.push({ value: cnt.name, label: cnt.name?.Capitalize() })
        })
    }
    get _data() {
        return this.getState({key: "data"});
    }
    display_internal() {
        if (this.check_role("INTERNAL_COTA_USER")) {
            return ""
        }
        return "hide"
    }
    follow_on_selection({event, obj, data}) {
        this.view.empty_results()
        this.setState({key: "data", value: obj})
        this.view.handleScreen({path_num: 0, screen_num: 2, data: obj})
    }
    get user_filter() {
        if (this.view?.state?.alpha !== undefined) {
            return this.view.state.alpha
        }
        return ""
    }
}
