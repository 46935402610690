// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";
import { FaPen } from "react-icons/fa";

// PAGE SPECIFIC MODULES
import ModalController from "./Equipment.modal.controller.jsx"
import _basePartial from "components/General/_basePartial.jsx"
import Modal from "./Equipment.modal.jsx";
import "../ShippingPartials.css"
import "./Equipment.css"

export default class EquipmentPanel extends _basePartial {
    constructor(props) {
        super(props)
        this.state.trailer_types        = []
        this.state.special_requirements = []
        this._modal                     = Modal
        this.modal_controller           = new ModalController({params: {view: this }})
    }
    followDidMount() {
        this.modal_controller.load_data()
    }
    get equipment() {
        return this.controller.equipment
    }
    renderView() {
        return (
            <>
                <Row className="row_padding" id={this.name} ref={ (divElement) => { this.divElement = divElement }}>
                    <Col className="cota_panel_object_unsized cota_panel_border">
                        <Row className="panel_title">
                            <Col className="edit_button">
                                &nbsp;
                            </Col>
                            <Col>
                                Equipment
                            </Col>
                            <Col className="edit_button g-0" onClick={this.toggle_modal?.bind(this, {toggle_state: true})}>
                                <FaPen size={15} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="cap_sentance generic_center">
                                {this.modal_controller.filter_array(this.state.trailer_types, this.controller?.equipment?.trailer_types)?.map((tt, index) => {
                                    return (
                                        <Row key={"tt_"+index}>
                                            <Col>
						                        {tt?.label}
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </Col>
                            <Col className="cap_sentance generic_center">
                                {this.modal_controller.filter_array(this.state.special_requirements, this.controller?.equipment?.requirements)?.map((req, index) => {
                                    return (
                                        <Row key={"req_"+index}>
                                            <Col>
						                        {req?.label}
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}
