// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"
import ModalController from "./Rate.modal.controller.jsx"
import "./RatePanel.css"
import { rateOptions } from "./Options.js"
import configs from "./Rate.json"

export default class RateModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.rate_items = []
        this.state.form = configs.form_objects
        this.modal_controller = new ModalController({params: {view: this }})
        this.state.modal_params = {
            positioned: "middle_of_panel",
            title: "Rate",
            title_size: "small",
            relative_position: "left",
            relative_alignment: "start",
            x_adjustment: "0px",
            y_adjustment: "-100px",
            has_overlay: false,
            close_on_outside_click: true,
            closeable: true,
            draggable: true
        }
    }
    render_rates() {
        let sum = this.controller?.load?.rate_items.reduce(function(prev, current) {
            return prev + +parseInt(current.rate)
        }, 0);
        if (this.controller?.load?.rate_items?.length > 0) {
            return (
                <>
                    <Row>
                        <Col className="border_btm">
                            &nbsp;
                        </Col>
                    </Row>
                    {
                        this.controller?.load?.rate_items?.map((rate, index) => {
                            return (
                                <Row key={"rate_item_"+index} className="item_row">
                                    <Col className="panel_title">
                                        {(rateOptions.filter(r => r.value === rate.rate_type))?.[0]?.label}
                                    </Col>
                                    <Col className="panel_title">
                                        ${rate.rate}
                                    </Col>
                                    <Col>
                                        {(rate.rate_by !== undefined) ? rate.rate_by : ((rate.rate_stops !== undefined) ? rate.rate_stops : rate.note)}
                                    </Col>
                                    <Col onClick={this.modal_controller.delete_rate.bind(this, index)} className="panel_title pointer">
                                        X
                                    </Col>
                                </Row>
                            )
                        })
                    }
                    <Row className="row_width">
                        <Col className="border_btm">
                            &nbsp;
                        </Col>
                    </Row>
                    <Row className="panel_title row_width">
                        <Col>
                            Total
                        </Col>
                        <Col>
                            =
                        </Col>
                        <Col>
                            ${sum}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        </Col>
                        <Col>
                            <Button className={"primary_button_coloring "+this._theme} onClick={this.toggle_modal.bind(this.state.parent)}>
                                Done
                            </Button>
                        </Col>
                    </Row>
                </>
            )
        }
    }
    render_row_option() {
        if (this.state?.data?.rate_item?.rate_type === "other") { 
            return(
                <>
                    <Col>
                        <Row>
                            <Col>
                                Description 
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.note, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                </>
            )
        }
    }
    renderView() {
        return (
            <div className="modal_width">
                <Row className="rate_entry_row">
                    <Col>
                        <Row>
                            <Col>
                                Rate Type*
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="absolute dropdown_dims dropdown_border">
                                    {this.controller?.view?.formObject({data: this.state.form.rate_type, owner: this, options: rateOptions, controller: this.modal_controller})}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                Rate*
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.rate, owner: this, controller: this.modal_controller})}
                            </Col>
                        </Row>
                    </Col>
                    {this.render_row_option()}
                    <Col>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                        <Row>
                            <Col className="panel_title">
                                =
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                        <Row>
                            <Col className="panel_title">
                                ${this.state?.data?.rate_item?.rate}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button className={"toggle_button_style "+this._theme} onClick={this.modal_controller.add_rate.bind(this)}>
                                    Add
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {this.render_rates()}
            </div>
        )
    }
}
