// GENERAL REACT MODULES
import React from "react";

// PAGE SPECIFIC MODULES
import PanelView from "module/components/CotaPanel/PanelView/PanelView.view.jsx";
import Controller from "./CarrierTripsView.controller";
import "./CarrierTripsView.css"
import "module/components/CotaPanel/PanelView/PanelView.css";
import grafana_config from "assets/grafana_config.json"
import GrafanaFrame from "components/GrafanaFrame/GrafanaFrame.view.jsx"

export default class CarrierTripsView extends PanelView {
    constructor(props) {
        super(props)
        this.state.panel_name = "carrier_trips"
        this.state.panel_configs = grafana_config["pages"]["home"]
        this.controller = new Controller({params: {view: this}})
    }
    renderView() {
        return (
            <div className="carrierTripWrapper">
                <GrafanaFrame className="grafana-wrapper" params={{parent: this, panel_configs: this.state.panel_configs, panel_name: this.state.panel_name, use_control_bar: false}}/>
            </div>
        )
    }
}