// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch.view.jsx"
import ModalController from "./Commodity.modal.controller.jsx"
import configs from "./Commodity.json"
import { typeOptions, classOptions } from "./Options.js"
import "./Commodity.css"

export default class CommodityModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.form = configs.form_objects
        this.schema = configs.schemas.commodity
        this.modal_controller = new ModalController({ params: { view: this } })
        this.state.requesting = false
        this.state.data = {
            commodity: {
                name: null
            }
        }
        this.state.edit_mode = false
        this.state.hover = undefined
        this.state.modal_params = {
            positioned: "middle_of_panel",
            title: "Commodity",
            title_size: "small",
            has_overlay: false,
            x_adjustment: "0px",
            y_adjustment: "-100px",
            close_on_outside_click: true,
            closeable: true,
            draggable: true
        }
        this.state.imperial = true
    }
    follow_modal_toggle() {
        this.modal_controller.add_object()
    }

    hover_line(index) {
        if (this.state.hover !== index) {
            return this.setState({ hover: index })
        }
        return this.setState({ hover: undefined })
    }
    get_row_color(index) {
        if (index === this.state.hover) {
            return "hover_line"
        }
        return ""
    }
    map_commodity_rows({commodity_array}) {
        // let commodity_array = this.controller?.[this?.modal_controller._configs?.array].concat(this.controller?.panel_controller?.cache?.["5"]?.data?.quote_data?.items)
        if (this.controller?.[this?.modal_controller._configs?.array] !== undefined) {
            return Object.values(commodity_array).map((commodity, index) => {
                return (
                    <div key={"commodity_row_" + index} className={"pointer " + this.get_row_color(index)}
                        onClick={this.modal_controller?.select_commodity.bind(this, index)}
                        onMouseEnter={this.hover_line.bind(this, index)}
                        onMouseLeave={this.hover_line.bind(this, index)}>
                        {this.render_commodity_row({ data: commodity, index: index })}
                    </div>
                )
            })
        }
    }
    render_commodity_row({ data, index }) {
        let commodity = this.modal_controller?.new_object()
        commodity._load({ data: data })
        return (
            <Row key={"commodity_row_" + index}>
                <Col className="commodity_row_title center">
                    {commodity?.name}
                </Col>
                <Col className="center" >
                    {commodity?.weight}
                </Col>
                <Col className="center" >
                    {commodity?.type?.Capitalize()}
                </Col>
                <Col className="center" >
                    {commodity?.quantity}
                </Col>
                <Col className="center" >
                    {commodity?.piece_count}
                </Col>
                <Col sm={1}  className="center">
                    {commodity?.length}
                </Col>
                {/* <Col  className="x_cell">
                    X
                </Col> */}
                <Col sm={1} className="center">
                    {commodity?.width}
                </Col>
                {/* <Col  className="x_cell">
                    X
                </Col> */}
                <Col  className="generic_center center">
                    {commodity?.height}
                </Col>
                <Col className="center" >
                    {commodity?.class}
                </Col>
                {/* <Col className="center" >
                    {commodity?.nmfc}
                </Col> */}
                <Col  className="x_cell x_col delete_button" onClick={this.modal_controller?.delete_commodity.bind(this, { index: index })}>
                    X
                </Col>
            </Row>
        )
    }
    renderView() {
        return (
            <div className="modal_width">
                <Row>
                    <Col>
                    </Col>
                    <Col>
                        <ToggleSwitch params={{ parent: this, name: "ltl_switch", label: { left: "LTL", right: "Truckload" } }} />
                    </Col>
                </Row>
                <Row className="g-0 col_padding remove_padding">
                    <Col className="col_padding">
                        Commodity*
                    </Col>
                    <Col sm={3} className="col_padding">
                        Total Weight (lbs)*
                    </Col>
                    <Col sm={1} className="g-0 save_col col_padding">
                        &nbsp;
                    </Col>
                </Row>
                <Row className="g-0 col_padding remove_padding">
                    <Col className="col_padding">
                        {this.formObject({ data: this.state.form.name, owner: this, controller: this.modal_controller })}
                    </Col>
                    <Col sm={3} className="col_padding">
                        {this.formObject({ data: this.state.form.weight, owner: this, controller: this.modal_controller })}
                    </Col>
                    {this.modal_controller?.add_switch()}
                </Row>
                <Row className="g-0 col_padding remove_padding">
                    <Col className="g-0 col_padding">
                        Unit Type*
                    </Col>
                    <Col className="g-0 col_padding">
                        Quantity*
                    </Col>
                    <Col className="g-0 col_padding">
                        Piece Count
                    </Col>
                    <Col sm={1} className="g-0 col_padding">
                        L*
                    </Col>
                    <Col sm={1} className="g-0 x_col_width">
                        &nbsp;
                    </Col>
                    <Col sm={1} className="g-0 col_padding">
                        W*
                    </Col>
                    <Col sm={1} className="g-0 x_col_width">
                        &nbsp;
                    </Col>
                    <Col sm={1} className="g-0 col_padding">
                        H*
                    </Col>
                    <Col className="g-0 col_padding">
                        Class*
                    </Col>
                    <Col className="g-0 col_padding">

                    </Col>
                    <Col className="g-0 col_padding">
                        NMFC#
                    </Col>
                </Row>
                <Row className="g-0 col_padding remove_top_padding remove_padding">
                    <Col className="g-0 col_padding">
                        {/* {this.formObject({data: this.state.form.unit_type, owner: this, options: typeOptions, controller: this.modal_controller})} */}
                        <div className="absolute">
                            {this.controller?.view?.formObject({ data: this.state.form.unit_type, owner: this, options: typeOptions, controller: this.modal_controller })}
                        </div>
                    </Col>
                    <Col className="g-0 col_padding">
                        {/* {this.formObject({data: this.state.form.quantity, owner: this, controller: this.modal_controller})} */}
                        {this.controller?.view?.formObject({ data: this.state.form.quantity, owner: this, controller: this.modal_controller })}
                    </Col>
                    <Col className="g-0 col_padding">
                        {/* {this.formObject({data: this.state.form.count, owner: this , controller: this.modal_controller})} */}
                        {this.controller?.view?.formObject({ data: this.state.form.count, owner: this, controller: this.modal_controller })}
                    </Col>
                    <Col sm={1} className="g-0 col_padding">
                        {/* {this.formObject({data: this.state.form.length, owner: this, controller: this.modal_controller})} */}
                        {this.controller?.view?.formObject({ data: this.state.form.length, owner: this, controller: this.modal_controller })}
                    </Col>
                    <Col sm={1} className="g-0 x_col x_col_width line_height">
                        X
                    </Col>
                    <Col sm={1} className="g-0 col_padding">
                        {/* {this.formObject({data: this.state.form.width, owner: this, controller: this.modal_controller})} */}
                        {this.controller?.view?.formObject({ data: this.state.form.width, owner: this, controller: this.modal_controller })}
                    </Col>
                    <Col sm={1} className="g-0 x_col x_col_width line_height">
                        X
                    </Col>
                    <Col sm={1} className="g-0 col_padding">
                        {/* {this.formObject({data: this.state.form.height, owner: this, controller: this.modal_controller})} */}
                        {this.controller?.view?.formObject({ data: this.state.form.height, owner: this, controller: this.modal_controller })}
                    </Col>
                    <Col className="g-0 col_padding">
                        {/* {this.formObject({data: this.state.form.class, owner: this, options: classOptions, controller: this.modal_controller})} */}
                        <div className="absolute">
                            {this.controller?.view?.formObject({ data: this.state.form.class, owner: this, options: classOptions, controller: this.modal_controller })}
                        </div>
                    </Col>
                    <Col className="g-0 col_padding">
                        <Button className={"toggle_button_style " + this._theme} onClick={this.modal_controller.estimate_class.bind(this)}>
                            Estimate Class
                        </Button>
                    </Col>
                    <Col className="g-0 col_padding">
                        {/* {this.formObject({data: this.state.form.nmfc, owner: this, controller: this.modal_controller})} */}
                        {this.controller?.view?.formObject({ data: this.state.form.nmfc, owner: this, controller: this.modal_controller })}
                    </Col>
                </Row>
                {this.render_summary()} 
            </div>
        )
    }
    render_summary() {
        let commodity_array = this.controller?.[this?.modal_controller._configs?.array].concat(this.controller?.panel_controller?.cache?.["5"]?.data?.quote_data?.items)
        if (this.state?.ltl_switch !== true) {
            return (
                <>
                    <Row className="g-0 col_padding remove_padding">
                        <hr className="hr" />
                    </Row>
                    <Row>
                        <Col className="center">Name</Col>
                        <Col className="center">Weight</Col>
                        <Col className="center">Unit Type</Col>
                        <Col className="center">Quantity</Col>
                        <Col className="center">Piece Count</Col>
                        <Col className="center">Length</Col>
                        <Col>Width</Col>
                        <Col>Height</Col>
                        <Col>Class</Col>
                    </Row>
                    <Row className="g-0 col_padding remove_padding buffer_bottom_row">
                        <Col>
                            {
                                this.map_commodity_rows({commodity_array: commodity_array})
                            }
                        </Col>
                    </Row>
                    <Row className="g-0">
                        <Col className="totals">
                            Total Weight
                        </Col>
                        <Col className="totals">
                            {commodity_array?.reduce((accumulator, currentValue) => accumulator + ((currentValue?.weight === undefined) ? 0 : currentValue?.weight), 0)} {(this.state.imperial) ? "lbs" : "kg"}
                        </Col>
                        <Col className="totals">
                            Total Length
                        </Col>
                        <Col className="totals">
                            {this.modal_controller.resolve_total_length(commodity_array)}
                        </Col>
                        {this.modal_controller?.add_save()}
                    </Row>
                </>
            )
        }
    }
}
