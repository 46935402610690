
// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from 'react-bootstrap'

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import "./DeleteContact.css"
import Modal from "./DeleteContact.modal"
import Controller from "./DeleteContact.controller.jsx"

export default class DeleteContactPartial extends _basePartial {
    constructor(props) {
        super(props)
        this._modal             = Modal
        this.modal_controller   = new Controller({params: { view: this, controller: this.controller }})
    }
    renderView() {
        return(
            <Button className={this._theme} variant="danger" onClick={this.toggle_modal?.bind(this, {toggle_state: true}) }>
                Delete
            </Button>
        )
    }
}