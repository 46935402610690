
// GENERAL REACT MODULES
import React from "react";
import { Row, Col } from 'react-bootstrap'

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import "./PeopleList.css"

export default class PeopleListPartial extends _basePartial {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    render_person_line({person, index}) {
        if (person?.first_name !== undefined) {
            return (
                <Row className="line" key={"people_"+index}>
                    <Col>
                        {person?.first_name+" "+person?.last_name}
                    </Col>
                    <Col>
                        {person?.phone}
                    </Col>
                    <Col>
                        {person?.email}
                    </Col>
                </Row>
            )
        }
    }
    render_people() {
        if (this.state?.people !== undefined) {
            if (Array.isArray(this.state.people)) {
                return this.state.people.map((person, index) => {
                    return this.render_person_line({person: person, index: index})
                })
            }
        }
    }
    renderView() {
        this.state.people = this.controller.people
        // this.state.people = this.controller.getState({key: "data", param: "contact.people" })
        return(
            <Row id={this.name} className="add_shadow">
                <Col>
                    <Row>
                        <Col className="title">
                            People
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr />
                        </Col>
                    </Row>
                    {
			            this.render_people()
                    }
                </Col>
            </Row>
        )
    }
}
