// GENERAL REACT MODULES
import { Col, Row } from 'react-bootstrap'
import { ColorRing } from  'react-loader-spinner'
import { Textfit } from 'react-textfit';

// OBJECT SPECIFIC MODULES
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch.view.jsx"
import _viewController from "controllers/ViewController/_viewController.jsx"
import Ltlview from "./Partials/Ltlview.partial.jsx"
import PalletsView from './Partials/PalletsView.partial.jsx'
import QuoteForm from './Partials/QuoteForm/QuoteForm.partial.jsx'
import Truckload from './Truckload.partial.jsx'
import QuoteRequestModelController from "controllers/ModelControllers/Quotes/QuoteRequestModelController"
import QuoteModelController from "controllers/ModelControllers/Quotes/QuoteModelController"
import CompanyModelController from "controllers/ModelControllers/CompanyModelController.jsx"
import graph_request from "assets/graphql/Quotes/NewQuote.graphql.json"
import existing_quote from "assets/graphql/Quotes/GetQuoteRaw.graphql.json"
import update_quote from "assets/graphql/Quotes/SaveQuote.graphql.json"
import re_quote from "assets/graphql/Quotes/ReQuote.graphql.json"
// import { Button } from 'react-bootstrap/lib/InputGroup.js';
import { Button } from 'react-bootstrap';

export default class NewQuotePanelController extends _viewController {
    constructor(props) {
        super(props)
        this.state = {
            countdown: "00:00"
        }
        this.company        = new CompanyModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true}})
        this.quote_request  = new QuoteRequestModelController({params: {parent: this, 
                                                                        controller: this, 
                                                                        is_view: true, _param: "quote_request"}})
        this.quote          = new QuoteModelController({params: {parent: this, 
                                                                    controller: this, 
                                                                    is_view: true}})
        this.requests       = [{callback: {p: {name: "trailer_types", var: "trailer_types"}}, 
                                                                        endpoint: "trailers"},
                                {callback: {p: {name: "special_requirements", var: "special_requirements"}},
                                                                        endpoint: "requirements", req_name: "special"},
                                {callback: {p: {name: "accessorials", var: "accessorials"}}, 
                                                                        endpoint: "requirements", req_name: "accessorials"}]
    }
    load_harness() {
        if (this.view?.state?.panel_params?.data?.id !== undefined) {
            let data = JSON.parse(JSON.stringify(this.view.state.panel_params.data))
            this.view.state.panel_params.data = undefined
            this.load_existing_quote(data)
        } else {
            if (!this.check_role("INTERNAL_COTA_USER")) {
                if (this.view.state.context?.self?.companies !== undefined) {
                    if (Array.isArray(this.view.state.context?.self?.companies)) {
                        let company = this.view.state.context?.self?.companies[0]
                        this.setState({key: ["search",
                                            "search",
                                            "data"], 
                            param: ["company.selected_value",
                                    "company.search_value",
                                    "company"], 
                            value: [company,
                                    company?.name,
                                    company]})
                    }
                }
            }
        }
    }
    determine_view(data,is_switched){
    // if were swapping to full truck we need the sums of the weights / lengths for the form 
        this.quote_request.weight = data.total_weight
        this.quote_request.length = data.total_length
        // if we taking 3/4 of a truck and we havent moved to a full truck from yet 
        if ((data.total_count >= 18 && is_switched === false) || (data.total_length >= 36 && is_switched === false)) {
            // this is nedded to set the default of the toggle 
            this.view.state.default_value = "Truckload"
            // push to full truck view and display error message
            this.setState({ key: "ltl_switch", value: true })
            this?.setState({ key: "is_hidden", param: undefined, value: false })
            this.get_toggle("ltl_switch")
            this.load_truckview_window()
            this.is_hidden()
            this.show_snackbar({msg: "load too heavy, long, or wide for ltl ", status: "error", pos: "top-center"})
            return false
        }
        else return true
    }
    get_toggle(name) {
        if(this.quote.number){
            return
        }

       else if (this.view?.state?.panel_params?.data?.id?.id === undefined) {
            if (name === "ltl_switch" ) return (<ToggleSwitch params={{ parent: this.view, name: "ltl_switch", label: {left: "LTL", right: "Truckload"}}}/>)
            if (name === "pallet_switch_form") return (<ToggleSwitch className="pallet_switch_form" 
                                                    params={{parent: this.view, name: "know_num_of_pallets", label: {left: "I Know", right: "I Don't Know How Many Pallets"}}}/>)
        }
    }
    async load_existing_quote(data) {
        if (data !== undefined) {
            if (data?.id !== undefined && (typeof data?.id === "string")) {
                let body    = JSON.parse(JSON.stringify(existing_quote))
                body.query = body.query.replace("quote", 'quote(id: "'+data?.id+'")')
                this.api.quotes.ask({caller: this, params: {body: body}, callback: {f: this.process_loaded_quote}})
            }
        }
    }
    process_loaded_quote({caller, params, results}) {
        caller.view.state.loading_quote = false
        let quote = results?.data?.quote
        let quote_request = quote?.raw_quote?.request
        if (quote_request !== undefined & quote_request !== null) {
            if (quote?.ltl_truckload) {
                quote_request.truckload = quote.truckload
            }
            quote_request.cost = quote?.cost
            caller.quote_request?._sync({data: quote?.raw_quote?.request})
        }
        caller.quote?._sync({data: quote})
        caller.company?._sync({data: quote?.customer})
        if (results?.data?.quote?.dropoff?.zip !== undefined) {
            caller.setState({key: ["search",
                            "search"], 
                        param: ["dropoff_zip.selected_value.zip",
                                "dropoff_zip.search_value"], 
                        value: [results?.data?.quote?.dropoff?.zip,
                            results?.data?.quote?.dropoff?.zip]})
        }
        if (results?.data?.quote?.pickup?.zip !== undefined) {
            caller.setState({key: ["search",
                            "search"], 
                        param: ["pickup_zip.selected_value.zip",
                                "pickup_zip.search_value"], 
                        value: [results?.data?.quote?.pickup?.zip,
                            results?.data?.quote?.pickup?.zip]})
        }
        caller.setState({key: ["search",
                                    "search"], 
                            param: ["company.selected_value",
                                "company.search_value"], 
                            value: [caller.company.data,
                                caller.company?.name]})
        if (results?.data?.quote?.is_calculated !== undefined) {
            caller.view.state.know_num_of_pallets = results.data.quote.is_calculated
        }
        caller.setState({key: "ltl_switch", value: results?.data?.quote?.ltl_truckload})
        caller.setState({key: "is_hidden", value: "active"})
        if (results?.data?.quote?.raw_quote !== undefined) {
            let raw_quote = JSON.parse(JSON.stringify(results?.data?.quote?.raw_quote))
            caller.view.state.data.quote.raw_quote = raw_quote
        }
    }
    follow_on_selection({event, obj, data}) {
        if (data?.name === "zip") {
            this.setState({key: "quote_request", param: "shipment."+data?.version+data?.name, value: obj.zip})
        }
    }
    // ltl_switch
    get _summary_switch() {
        if (this._is_ltl) {
            return 1
        }
        if (this._itemized) {
            return 2
        }
        return 0
    }
    get _is_ltl() {
        return this.getState({key: "ltl_switch"})
    }
    get _itemized() {
        return this.getState({key: "know_num_of_pallets"})
    }
    process_quote({caller, params, results}) {
        caller.controller.setState({key: "_is_mutating" , value: false})
        caller.controller.setState({key: "loading_quote", value: false})
        if (results?.errors === undefined) {
            let q = (results?.data?.quote_request !== undefined) ? results?.data?.quote_request : results?.data?.re_quote
            // if(!this._notnull(q)) { q = {} }
            // Ensure raw quote is added so QuoteModelController can calculate quote_rate on a new Quote
            q.raw_quote = { cost: q?.cost }
            caller.controller.quote._sync({data: q})
        } else {
            caller.controller?.setState({key: "errors", param: undefined, value: results.errors})
        }
    }
    save_quote() {
        let body    = JSON.parse(JSON.stringify(update_quote))
        let summary = this.quote.summary
        let data = this.toUnquotedJSON({id: summary.id, cost: summary.cost, quote_rate: summary.quote_rate})
        if(this?.quote?.data?.truckload){
            data = this.toUnquotedJSON({id: summary.id, cost: Number(this.quote_request.cost), quote_rate: summary.quote_rate})
        }
        body.query = body.query.replace("data:", 'data: '+data+'')
        this.api.quotes.ask({caller: this, params: {body: body}, callback: {f: this.process_save}})
    }
    async process_save({caller, params, results}) {

    }
    async get_quote() {
        this.setState({_is_mutating: true})
        this.controller.setState({key: "loading_quote", value: true})
        // let qn  = this.controller?.getState({key: "quote_results", param: "quote_id"})
        // let eqn = this.controller?.getState({key: "panel_params", param: "data.id"})
        this.controller.setState({key: ["errors", "quote_results"], value: [undefined, undefined]})
        let pz = this.controller.quote_request.pickup.zip
        let dz = this.controller.quote_request.dropoff.zip
        if ((pz !== undefined && pz !== "") && (dz !== undefined && dz !== "")) {
            this.controller.setState({key: "results", undefined})
            this.controller?.setState({key: "is_hidden", param: undefined, value: true})
            let summary = this.controller.quote_request?.summary
            /* TODO if this comes back false it means we swapped to full truckload and dont want to make 
             a quote yet so we need to surround the ask with an appropiate gaurd */
            // let is_ltl =  this.controller.determine_view(summary,this.state.parent.state.ltl_switch)
            summary.shipment.company = {id: this.controller?.company?._id}
            delete summary?.shipment?.company?.ts
            summary = summary.scrub()
            let body    = ""
            if (this.controller.quote?.number !== undefined && this.controller.quote?.number !== null) {
                body    = JSON.parse(JSON.stringify(re_quote))
                if (this.controller.quote?.number !== undefined) {
                    summary.carrier_quote_number = this.controller.quote?.number
                }
                let requote = {
                    quote: summary,
                    carrier_quote_number: this.controller.quote?.number
                }
                let data	= this.controller.toUnquotedJSON(requote)
                body.query	= body.query.replace("re_quote", 're_quote(data: '+data+')')
            } else {
                body	= JSON.parse(JSON.stringify(graph_request))
                let data	= this.controller.toUnquotedJSON(summary)
                body.query	= body.query.replace("quote_request", 'quote_request(data: '+data+')')
            }
            this.api.quotes.ask({caller: this, params: {body: body}, callback: {f: this.controller.process_quote}})
        }
    }
    is_get_quote_avail() {
        // if (Object.keys(this.quote_request?.summary?.items) > 0) {
            return "active"
        // }
        // return "inactive"
    }
    get panel_controller() {
        if (this.view.state?.parent?.constructor.name === "PanelController") {
            return this.view.state?.parent          
        }
        return undefined
    }
    get_results() {
        if (this.view?.state?.errors !== undefined) {
            return this.error_screen()
        }
        if (!this.view.state.loading_quote) {
            if (this.quote?.number !== undefined) {
                if (this.quote?.ltl_truckload || this.quote_request.data.truckload) {
                    if (this.check_role("INTERNAL_COTA_USER")) {
                        return this.flat_rate()
                    } else {
                        return this.pending_screen()
                    }
                }
                if (this.quote?.cost === null || this.quote?.cost === undefined ) {
                    if (this.check_role("INTERNAL_COTA_USER")) {
                        return this.results_screen()
                    } else {
                        return this.pending_screen()
                    }
                } else {
                    // console.log("NO CONDITIONS MET",this.quote.cost,this.quote.quote_rate)
                    // if(this.quote.cost !== "0.00"){
                    //     if(typeof(this.quote.quote_rate) !== "string"){
                    //         console.log("SETTING",this.quote.cost,typeof(this.quote.quote_rate),this,parseInt(`${Number(this.quote.cost) / this.quote.quote_rate}`,2))
                    //         this.quote.raw_quote.cost = `${(Number(this.quote.cost) / this.quote.quote_rate).toFixed(2)}`
                    //     }else{
                    //     this.quote.raw_quote.cost = parseInt(`${this.quote.cost}`).toFixed(2)
                    //     }
                    // }
                    return this.results_screen()
                }
            }
        }
        return this.loader()
    }
    is_hidden() {
        if (this.view?.state?.is_hidden) {
            return "active"
        }
        return "inactive"
    }
    get hours() {
        var diff = Math.abs(new Date() - new Date(parseInt(this.state.countdown)));
        var minutes = Math.floor((diff/1000)/60);
        var hours = minutes/60;
        var new_hours = hours
        if (hours > 24) {
            new_hours = 24
        }
        return new_hours
    }
    get minutes() {
        var diff = Math.abs(new Date() - new Date(parseInt(this.state.countdown)));
        var minutes = Math.floor((diff/1000)/60);
        var hours = minutes/60;
        minutes = minutes-(hours.toFixed()*60)
        if (minutes > 60) {
            minutes = 60
        }
        return minutes
    }
    get countdown() {
        return this.hours+":"+((this.minutes > 10) ? this.minutes : "0"+this.minutes)+((this.minutes >= 60) ? "+" : "")
    }
    get due_colors() {
        if (this.hours > 23) {
            return "red"
        }
        if (this.hours > 18) {
            return "yellow"
        }
        return ""
    }
    flat_rate() {
        if (this.quote.number === "Invalid Submission") {
            return this.no_results()
        }
        return(<>
            <Row>
                <Col className={'generic_center bold '+this.due_colors}>
                    Due in {this.countdown} minutes  
                </Col>
            </Row>
            <Row className="quote_results_with_header" id="quote_result">
                <Col className="results_panel">
                    <Row>
                        <Col className='pending_title'>
                            Quote # : {this.quote?.number}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    {this.view.formObject({data:  this.view?.state?.form?.flat_rate, 
                                                                                options: undefined, 
                                                                                owner: this})}
                                </Col>
                            </Row>
                            <Row>
                                <Col className='generic_center'>
                                   Cost
                                </Col>
                            </Row>
                        </Col>
                        {/* <Col>
                            <Row>
                                <Col>
                                    {this.view.formObject({data: this.view?.state?.form?.per_mile, 
                                                                                options: undefined, 
                                                                                owner: this})}
                                </Col>
                            </Row>
                            <Row>
                                <Col className='generic_center'>
                                    Per Mile
                                </Col>
                            </Row>
                      
                        </Col> */}
                        <Col>
                            <Row>
                                <Col>
                                    {this.view.formObject({data: this.view?.state?.form?.FTL_cost, 
                                                                                options: undefined, 
                                                                                owner: this})}
                                </Col>
                            </Row>
                            <Row>
                                <Col className='generic_center'>
                                   Quoted Rate
                                </Col>
                            </Row>
                        </Col>
              
                    </Row>
                    <Row className='sub_pending_label'>
                    
                        <Col>
                        {this.render_quick_selection()}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>)
    }
    no_results() {
        return (
            <Row className="add_shadow results_panel results_panel_row results_loading_padding" id="quote_loader">
                <Col>
                    <Row>
                        <Col className='pending_label'>
                            Quote Failed
                        </Col>
                    </Row>
                    <Row>
                        <Col className='sub_pending_label'>
                            Please try different parameters
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
    pending_screen() {
        return (
            <Row className="max_height" id="quote_result">
                <Col className="results_panel">
                    <Row>
                        <Col className='pending_title'>
                            Quote # : {this.quote?.number}
                        </Col>
                    </Row>{this.quote?.cost ?              <Row className='quote_text quote_val center_block lxmax'>
                                <Col className="font_size_small">
                                    Quoted Rate:
                                </Col>
                              
                                <Col className='g-0'>
                                <Textfit mode="single" forceSingleModeWidth={false} className="generic_100">
                    ${this.quote?.cost}
                </Textfit>
                                </Col>
                            </Row> : 
                    <><Row className='pending_label'>
                            <Col>
                                PENDING
                            </Col>
                        </Row><Row className='sub_pending_label'>
                                <Col>
                                    Within 2 Business Hours
                                </Col>
                            </Row></> }
                </Col>
            </Row>
        )
    }
    get quote_button_label() {
        if (this.quote._id !== undefined) {
            if (Boolean(this.quote.ltl_truckload)) {
                return "Update Quote"
            }
            return "Re Quote This"
        }
        if (!this.view?.state?.is_hidden) {
            return "Get Quote"
        }
        return "Get Another Quote"
    }
    loader() {
        return (
            <Row className="add_shadow results_panel results_panel_row results_loading_padding" id="quote_loader">
                <ColorRing visible={true}
                    height="110"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={['#fff', '#fff', '#fff', '#fff', '#fff']}/>
            </Row>
        )
    }
    error_screen() {
        return (
            <Row className="max_height" id="quote_result">
                <Col className="error_panel">
                    <Row>
                        <Col className='error_title'>
                            Errors : {this.view?.state?.error?.length}
                        </Col>
                    </Row>
                    {
                        this.view?.state?.errors?.map((error, index) => {
                            return (
                                this.error_lines({error: error, index: index})
                            )
                        })
                    }
                </Col>
            </Row>
        )
    }
    error_lines({error, index}) {
        if (index < 5) {
            return (
                <Row>
                    <Col>
                        {error.message}
                    </Col>
                </Row>
            )
        }
    }
    get_quote_cost() {
        if (this.check_role("INTERNAL_COTA_USER")) { 
            return this.quote.raw_quote.cost * this.quote.quote_rate
        } else {
            return this.quote?.cost
        }
    }
    render_cost() {
        if (this.check_role("INTERNAL_COTA_USER")) {
            // this.view.state.data.quote.cost = this?.state?.test
        // if (this.check_role("INTERNAL_COTA_USER") && this.view?.state?.panel_params?.data?.id.length !== undefined) {
            return this.view.formObject({data: this.view.state.form?.cost, 
                                                            options: undefined, 
                                                            owner: this})
        } else {
            return (
                <Textfit mode="single" forceSingleModeWidth={false} className="generic_100">
                    ${this.quote?.cost}
                </Textfit>
            )
        }
    }
    render_original() {
        if (this.check_role("INTERNAL_COTA_USER")) {
            // this.quote.raw_quote.cost = this.state.truckload_quote
            // this.quote.margin = Number(this.state.truckload_quote) - (Number(this.state.truckload_quote) * this.quote.quote_rate)
            // this.state.truckload_quote = "0.00"
            return (
                <Row className='quote_text center_block lh'>
                    <Col>
                        Cost: ${this.quote?.raw_quote?.cost}
                    </Col>
                </Row>
            )
        }

    }
    quick_is_toggled(value) {
        let rate = Math.round(this.quote?.quote_rate * 100) - 100
        if (rate === value) {
            return "selected_rate"
        }
        return this._theme
    }
    toggle_rate(value) {
        if(this?._notnull(this?.quote_request?.data?.truckload?.flat_rate)){
            this.quote.raw_quote = {}
            // this.quote_request.flat_rate = `${Number(this.quote_request.cost) * ((value/100) + 1)}`
            this.quote_request.cost = `${Number(this.quote_request.flat_rate) * ((value/100) + 1)}`
            // this.quote_request.flat_rate = this.quote.raw_quote.cost
            this.quote.raw_quote.cost  = this.quote_request.flat_rate
        }
        this.quote.quote_rate = (value/100) + 1
        if(Number(this.quote.raw_quote.cost) > 0){
            // this.view.state.data.quote.cost = this.quote.raw_quote.cost
        }
        // this.state.truckload_quote = `${Number(this.state.truckload_quote) * this.quote.quote_rate }`
        // this.view.state.data.quote.cost  = Number(this.view.state.data.quote.cost) * this.quote.quote_rate
        this.view.forceUpdate()
    }
    build_quick_button({value}) {
        return(
            <Col>
                <Button className={"mini_btn toggle_button_style "+this.quick_is_toggled(value)} onClick={this.toggle_rate.bind(this, value)}>
                    {value}%
                </Button>
            </Col>
        )
    }
    render_quick_selection() {
        let quick_buttons = [10, 15, 20]
        if (this.check_role("INTERNAL_COTA_USER")) {
            return (
                <Row>
                    {quick_buttons.map((value, index) => {
                        return this.build_quick_button({value: value})
                    })}
                    <Col>
                        <Button className={"toggle_button_style save_button "+this._theme} onClick={this.save_quote.bind(this)}>
                            Save
                        </Button>
                    </Col>
                </Row>
            )
        }
    }
    render_margin() {
        if (this.check_role("INTERNAL_COTA_USER") && !isNaN(this.quote?.margin)) {
            return (
                <Row className='mini_btn_row'>
                    <Col className="revenue">
                        Projected Margin: ${this.quote?.margin}
                    </Col>
                </Row>
            )
        }
    }
    results_screen() {
        return (
            <Row className="max_height" id="quote_result">
                <Col className="results_panel">
                    <Row className="results_panel_row g-0">
                        <Col className='g-0 center_block'>
                            <Row className='quote_text center_block lh'>
                                <Col>
                                    Quote # {this.quote?.number}
                                </Col>
                            </Row>
                            <Row className='quote_text quote_val center_block lxmax'>
                                <Col className="font_size_small">
                                    Quoted Rate:
                                </Col>
                              
                                <Col className='g-0'>
                                    {this.render_cost()}
                                </Col>
                            </Row>
                            {this.render_original()}
                           
                            <Row className='quote_text center_block lh'>
                                <Col>
                                    {this.quote?.days} day transit
                                </Col>
                            </Row>
                            {this.render_margin()}
                            {this.render_quick_selection()}
                        </Col>
                    </Row>
                </Col>
                {this.display_co2_panel()}
            </Row>
        )
    }
    display_co2_panel() {
        if (this.quote?.co2?.amount !== null && this.quote?.co2?.amount !== undefined) {
            return (
                <Col className='results_co2_panel add_shadow' xs={3}>
                    <Row className="results_panel_row co_row">
                        <Col className='center_obj g-0'>
                            <Row className='co2_number'>
                                <Col>
                                    <Textfit mode="single">
                                        {this.quote?.co2?.amount}
                                    </Textfit>
                                </Col>
                            </Row>
                            <Row className='center_block'>
                                <Col>
                                    Pounds of CO2
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            )
        }
    }
    async book_it(page_key) {
        await this.controller.save_quote()
        // checking to see if we used the button on the home screen
        let top_parent = this?.controller?.view?.parent?.parent?.name
        if (this.controller?.panel_controller?.parent?.state !== undefined) {
            this.controller.panel_controller.parent.state.panel_data = this.controller?.quote?.data
        }
        //if we did use the button on the home screen set the panel_num to 5 in order to navigate to the booking panel screen
        this.controller?.panel_controller?.selectPanel({panel_num: top_parent === "Home" ? 5 : 0,  cache_data: {rate_data: this.view?.state?.results, quote_data: this?.controller?.quote_request?.data}})
    }
    toggle_pallets_switch() {
        this.setState({know_num_of_pallets: !this.getState({key: "know_num_of_pallets"})})
    }
    load_pallet_window() {
        if (this.getState({key: "know_num_of_pallets"})) {
            return (
                <PalletsView params={{parent: this.view,
                                        controller: this}}/>
            )
        } else {
            return (
                <QuoteForm params={{parent: this.view, controller: this}}/>
                // <GrafanaFrame params={{parent: this.view, panel_configs: this.view.grafana(), panel_name: this.view?.state?.panel_names[0]}}/>
            )
        }
    }
    load_truckview_window() {
        if (this.getState({key: "ltl_switch"})) {
            return (
                <Truckload params={{parent: this.view,
                                    panel_controller: this.parent,
                                    controller: this}}/>
            )
        }
        return (
            <>
                <Ltlview params={{parent: this.view, 
                                    panel_controller: this.parent,
                                    controller: this}}/>
            </>
        )
    }
    processEventReaction(decoded) {
        let items = []
        Object.keys(decoded.message).forEach((key, index) => {
            if(!isNaN(key)) {
                items.push(decoded.message[key])
            }
        })
        this.changeViewState({"form": items})
    }
    get weight_label() {
        if (this.view?.state?.context?.self?.settings?.measurement_system !== undefined && this.view?.state?.context?.self?.settings?.measurement_system !== null) {
            if (this.view.state.context.self.settings.measurement_system.toUpperCase() === "METRIC") {
                return "kg"
            }
        }
        return "lbs"
    }
    get volume_label() {
        if (this.view?.state?.context?.self?.settings?.measurement_system !== undefined && this.view?.state?.context?.self?.settings?.measurement_system !== null) {
            if (this.view.state.context.self.settings.measurement_system.toUpperCase() === "METRIC") {
                return "m"
            }
        }
        return "ft"
    }
}
