// GENERAL REACT MODULES
import React from "react";
import { Button, Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import "./ThirdPanel.css"

// ALL OTHER MODULES
import MultiPanel from "components/MultiPanels/MultiPanel";
import { MainConsumer } from "controllers/MainContext.jsx"
import MainContext from "controllers/MainContext.jsx";
import Controller from "./ThirdPanel.controller.jsx";
import "styles/generic.css"
import config_data from "../../Home.json"


export default class ThirdPanel extends MultiPanel {
    constructor(props) {
        super(props)
        this.state.form = config_data.form_objects
        this.controller = new Controller({params: {view: this}})
    }
    renderInnerFrame() {
	    console.log(this.state.form.company_name)
        return(
            <MainConsumer>
                {(props) => {
                        {
                            this.state.user = props?.user
//                            this.context.api.roles.create({json: {uuid: this.state?.user?.uuid, company_id: company.id, role: "MEMBER", status: "APPLIED"}})
                        }
                        return(
                            <Row className="margin_auto generic_panel_object generic_90" id={this.name}>
				                <Col sm={2}>&nbsp;</Col>
                                <Col className="generic_block_ul inner_company_wrapper">
                                    <Row className="generic_center company_title_question">
                                        <Col>
                                            Company Name
                                        </Col>
                                    </Row>
                                    <Row className="company_field">
                                        <Col>
                                            {this.formObject({data: this.state.form.company_name, 
                                                        options: undefined, 
                                                        owner: this})}
                                        </Col>
                                    </Row>
                                    <Row className="generic_center_content">
                                        <Col className="generic_center">
                                            <Button className={"generic_center confirm_button "+this._theme} onClick={this.controller?.cancel_action.bind(this, false)}>
                                                Cancel
                                            </Button>
                                        </Col>
                                        <Col className="generic_center">
                                            <Button className={"generic_center confirm_button "+this._theme} onClick={this.controller?.submit_action.bind(this)}>
                                                Create
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
				                <Col sm={2}>&nbsp;</Col>
                            </Row>
                        )
                    }
                }
            </MainConsumer> 
        )
    }
}
ThirdPanel.contextType = MainContext
