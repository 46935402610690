// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import "../Information.css"
import configs from "../Information.json"

export default class ExtraInfo extends _basePartial {
    constructor(props) {
        super(props)
	    this.state.form = configs.form_objects
    }
    renderView() {
        return (
            <>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                PO #
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.po, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                Container #
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.container, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                Container Size
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.container_size, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                Customers Brokers
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.customers_brokers, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                BOL #
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.bol, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                House BOL #
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.house_bol, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                Vessel Name
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.vessel_name, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                Origin Port
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.origin_port, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                Destination Port
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.destination_port, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                Importer
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.importer, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                Customs Entry
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.customs_entry, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                Country of Origin
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.origin_country, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                Voyage/Flight
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.voyage_flight, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                Shipping Line
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.shipping_line, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                Fulfillment #
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.fulfillment_number, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                Additional Reference #
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.additional_reference, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                Import Value
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.import_value, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.in_bond, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                        <Row>
                            <Col className="button_col">
                                <Button className="panel_title pointer info_button" onClick={this.state.modal_controller?.toggle_extra.bind(this.state.modal_controller)}>
                                    Less Info
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}
