// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";
import { FaPen } from "react-icons/fa";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Modal from "./Documents.modal.jsx";
import "../ShippingPartials.css"
import "./Documents.css"

export default class DocumentsPanel extends _basePartial {
    constructor(props) {
        super(props)
        this._modal = Modal
    }
    renderView() {
        return (
            <>
                <Row className="row_padding" id={this.name} ref={ (divElement) => { this.divElement = divElement }}>
                    <Col className="cota_panel_object_unsized cota_panel_border">
                        <Row className="panel_title">
                            <Col className="edit_button">
                                &nbsp;
                            </Col>
                            <Col>
                                Documents {(this.controller?.load?.documents?.length > 0) ? "("+this.controller?.load?.documents?.length+")" : ""}
                            </Col>
                            <Col className="edit_button g-0" onClick={this.toggle_modal?.bind(this, {toggle_state: true})}>
                                <FaPen  size={15}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}
