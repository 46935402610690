// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";
import { FaPen } from "react-icons/fa";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Modal from "./Notes.modal.jsx";
import "../ShippingPartials.css"
import "./Notes.css"
import Controller from "./Notes.controller.jsx"

export default class NotesPanel extends _basePartial {
    constructor(props) {
        super(props)
        this.state.data.notes = [{note_text:null, viewable: null}]
        this.partial_controller = new Controller({params: { view: this, controller: this.controller }})
        this._modal = Modal
    }
    render_preview() {
        if (Array.isArray(this.controller?.load?.notes)) {
            if (this.controller?.load?.notes?.[0] !== undefined) {
                return (
                    <>
                        <Row>
                            <Col className="generic_center cap_sentance">
                                Latest Note
                            </Col>
                        </Row>
                        <Row>
                            <Col className="cap_sentance">
                                {this.controller?.load?.notes?.[0]?.note_text}
                            </Col>
                        </Row>
                        {this.partial_controller.render_date_row({label: "Last Update", date: this.controller?.load?.notes?.[0]?.updated_date})}
                        {this.partial_controller.render_date_row({label: "From", date: this.controller?.load?.notes?.[0]?.creator})}
                    </>
                )
            }
        }
    }
    render_more() {
        if (Array.isArray(this.controller?.load?.notes)) {
            if (this.controller?.load?.notes?.length > 1) {
                return (
                    <Row>
                        <Col>
                            ...
                        </Col>
                    </Row>
                )
            } 
        }
        return (<></>)
    }
    renderView() {
        return (
            <>
                <Row className="row_padding" id={this.name} ref={ (divElement) => { this.divElement = divElement }}>
                    <Col className="cota_panel_object_unsized cota_panel_border">
                        <Row className="panel_title">
                            <Col className="edit_button">
                                &nbsp;
                            </Col>
                            <Col>
                                {
                                    "Notes"+((this.controller?.load?.notes?.length > 0) ? " (" + this.controller?.load?.notes?.length +")" : "")
                                }
                            </Col>
                            <Col className="edit_button g-0" onClick={this.toggle_modal?.bind(this, {toggle_state: true})}>
                                <FaPen size={15} />
                            </Col>
                        </Row>
                        {this.render_preview()}
                        {this.render_more()}
                    </Col>
                </Row>
            </>
        )
    }
}
