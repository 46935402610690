
// GENERAL REACT MODULES
import React from "react";
import { Row, Col } from 'react-bootstrap'

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import form_configs from "./InsurancePartial.json"
import "../EditCarriers.css"
import "./InsurancePartial.css"

export default class InsurancePartial extends _basePartial {
    constructor(props) {
        super(props)
        this.state.form = form_configs.form_objects
    }
    renderView() {
        return(
            <Row id={this.name}>
                <Col>
                    <Row>
                        <Col>
                            Insurance
                        </Col>
                    </Row>
                    <Row className="cota_light_blue_button_rounded cota_panel_padding add_shadow">
                        <Col>
                            <Row className="text_row">
                                <Col>
                                    Producer
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.state.parent.formObject({data: this.state.form.producer})}
                                </Col>
                            </Row>
                            <Row className="text_row">
                                <Col>
                                    Broker Name
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.state.parent.formObject({data: this.state.form.broker_name})}
                                </Col>
                            </Row>
                            <Row className="text_row">
                                <Col>
                                    Email
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.state.parent.formObject({data: this.state.form.email})}
                                </Col>
                            </Row>
                            <Row className="text_row">
                                <Col>
                                    Phone
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.state.parent.formObject({data: this.state.form.phone})}
                                </Col>
                            </Row>
                            <Row className="text_row">
                                <Col>
                                    General Liability
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.state.parent.formObject({data: this.state.form.general_liability})}
                                </Col>
                            </Row>
                            <Row className="text_row">
                                <Col>
                                    Cargo Liability
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.state.parent.formObject({data: this.state.form.cargo_liability})}
                                </Col>
                            </Row>
                            <Row className="text_row">
                                <Col>
                                    Workmen's Compensation
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.state.parent.formObject({data: this.state.form.workmens_comp})}
                                </Col>
                            </Row>
                            <Row className="text_row">
                                <Col>
                                    Expiration
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.state.parent.formObject({data: this.state.form.expiration})}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}
