// GENERAL REACT MODULES
import _baseComponent from "components/General/_baseComponent.jsx"
import { Row, Col, Button } from 'react-bootstrap'
import "./Truckload.partial.css"
import "styles/generic.css"

// ALL OTHER MODULES
import config_data from "./NewQuotePanel.json"


export default class Truckload extends _baseComponent {
    constructor(props) {
        super(props)
        this.state.form = config_data.form_objects
        this.controller.view.state.default_value = undefined
    }
    render() {
        console.log("--------RENDERED", this.parent.state.data.quote.cost)
        return (
            <>
                <Col className="col_buffer" id={this.name}>
                    <Row className="row_buffer">
                        <Col sm={2} className="col_buffer">
                            <Row>
                                <Col>
                                    Trailer Type*
                                </Col>
                            </Row>
                            <Row className="dropdrown_wrapper">
                                <Col>
                                    {this.controller.view.formObject({
                                        data: this.state.form?.trailer_type,
                                        options: this.controller.getState({ key: "trailer_types" }),
                                        owner: this
                                    })}
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={2} className="col_buffer">
                            <Row>
                                <Col>
                                    Weight (lbs)*
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.controller.view.formObject({
                                        data: this.state.form?.weight,
                                        options: undefined,
                                        owner: this
                                    })}
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={2} className="col_buffer">
                            <Row>
                                <Col>
                                    Length (ft)
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.controller.view.formObject({
                                        data: this.state.form?.length,
                                        options: undefined,
                                        owner: this
                                    })}
                                </Col>
                            </Row>
                        </Col>
                        <Col className="col_buffer" />
                    </Row>
                    <Row className="row_buffer">
                        <Col sm={2} className="col_buffer">
                            <Row>
                                <Col>
                                    Commodity
                                </Col>
                            </Row>
                            <Row>
                                <Col className="g-0">
                                    {this.controller.view.formObject({
                                        data: this.state.form?.commodity,
                                        options: undefined,
                                        controller: this.controller,
                                        owner: this
                                    })}
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={2} className="col_buffer">
                            <Row>
                                <Col>
                                    Value ($)
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.controller?.view?.formObject({
                                        data: this.state?.form?.value,
                                        options: undefined,
                                        owner: this
                                    })}
                                </Col>
                            </Row>
                        </Col>
                        <Col className="col_buffer" />
                    </Row>
                    <Row>
                        <Col />
                        <Col sm={2} className="button_col_width">
                            <Button className="primary_button_coloring cota" onClick={this.controller?.get_quote.bind(this)}>
                                {this.controller?.quote_button_label}
                            </Button>
                        </Col>
                        {/* {console.log("CONTROLLER: ",this.controller?.is_hidden())} */}
                        <Row className={this.controller?.is_hidden()}>
                            <Col className='generic_override_flex'>
                                <Button className={"left button_row_buttons toggle_button_style " + this._theme} onClick={this.controller?.book_it.bind(this, "/booking")}>
                                    Book It!
                                </Button>
                            </Col>
                        </Row>

                    </Row>
                </Col>
            </>
        )
    }
}
