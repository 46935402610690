// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./Expandable.controller.jsx"
import { Button } from "react-bootstrap";
import { VscFilter } from "react-icons/vsc";
import { VscFilterFilled } from "react-icons/vsc";
import "./Expandable.css"

export default class ExpandablePartial extends _basePartial {
    constructor(props) {
        super(props)
        this.controller = new Controller({params: {view: this }})
        this.is_toggled = false
    }
    render_standard_nav() {
        if (this.state.nav_options) {
            return (
                <Button onClick={this.parent?.handleScreen.bind(this.parent, this.state.nav_options)} className={"primary_button_coloring "+this._theme}>
                    {this.state.nav_options?.label}
                </Button>
            )
        }
    }
    render_fold_toggle() {
        if (this.state.toggable) {
            if (this.state.is_toggled) {
                return (
                    <>
                        <VscFilterFilled onClick={this.toggle_fold.bind(this)} size={20}/>
                    </>
                )
            } else {
                return (
                    <>
                        <VscFilter onClick={this.toggle_fold.bind(this)} size={20}/>
                    </>
                )
            }
        }
    }
    toggle_fold() {
        this.state.is_toggled = !this.state.is_toggled
        this.parent.forceUpdate()
    }
    render_fold_frame() {
        if (this.state.is_toggled) {
            return (
                <Row className="g-0 expanded">
                    <Col className="g-0">
                        {this.parent?.filter_options()}
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row className="g-0 collapsed">
                    <Col></Col>
                </Row>
            )
        }
    }
    renderView() {
        this.parent.expandElement = this
        return (
            <Row id={this.name}>
                <Col>
                    <Row>
                        <Col className="min_column">
                            {this.render_standard_nav()}
                        </Col>
                        <Col sm={1} className="filter_icon">
                            {this.render_fold_toggle()}
                        </Col>
                    </Row>
                    {this.render_fold_frame()}
                </Col>
            </Row>
        )
    }
}
