// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"
import "./Documents.css"
import UploadPartial from "components/FileUpload/Upload.partial.jsx";
import Controller from "./Documents.modal.controller"
import DownloadPartial from "components/FileDownload/Download.partial";
import DeleteFile from "components/DeleteFile/DeleteFile.button.jsx"
import config_data from "./Documents.json"


export default class DocumentsModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.form         = config_data.form_objects
        this.modal_controller   = new Controller({params: {view: this, controller: this.controller }})
        this.state.modal_params = {
            positioned: "middle_of_panel",
            title: "Documents",
            title_size: "small",
            x_adjustment: "0px",
            y_adjustment: "-100px",
            has_overlay: true,
            close_on_outside_click: true,
            closeable: true,
            draggable: false
        }
    }
    renderView() {
        return (
            <>
                <Row>
                    <Col className="generic_center hide">
                        Quick Links
                    </Col>
                </Row>
                <Row className="g-0 flex-nowrap remove_padding buffer_bottom_row hide">
                    <Col className="generic_center col_padding">
                        <Button className={"primary_button_coloring doc_button "+this._theme} disabled={this.controller?.load?.carrier?.id === undefined}
                                onClick={this.modal_controller?.create_document.bind(this, "rate")}>
                            Create Rate Confirmation
                        </Button>
                    </Col>
                    <Col className="generic_center col_padding">
                        <Button className={"primary_button_coloring doc_button "+this._theme} disabled={this.controller?.load?.carrier?.id === undefined}
                                onClick={this.modal_controller?.create_document.bind(this, "bol")}>
                            Create Dispatch Sheet
                        </Button>
                    </Col>
                    <Col className="generic_center col_padding">
                        <Button className={"primary_button_coloring doc_button "+this._theme} disabled={this.controller?.load?.carrier?.id === undefined}
                                onClick={this.modal_controller?.create_document.bind(this, "bol")}>
                            Create Bill of Lading
                        </Button>
                    </Col>
                </Row>
                <Row className="pad_bottom">
                    <Col>
                        <UploadPartial params={{parent: this, controller: this.modal_controller, endpoint: "load"}} />
                    </Col>
                </Row>
                <Row className="autoscroll_list">
                    <Col>
                    {
                        this.controller?.load?.documents?.map((doc, index) => {
                            const docName = doc?.name
                            const docTypeName = doc?.document_type?.name
                            return (
                                <Row key={"doc_row_"+index} className={"doc_row pointer "+this.modal_controller.get_row_color(index)}
                                            onClick={this.modal_controller.download_file?.bind(this, {item: doc, index: index})}
                                            onMouseEnter={this.modal_controller.hover_line.bind(this, index)} 
                                            onMouseLeave={this.modal_controller.hover_line.bind(this, index)}>
                                    <Col className="center_text">
                                        {docName && this.modal_controller.truncateString(docName, 24)}
                                    </Col>
                                    <Col className="center_text">
                                        {docTypeName && this.modal_controller.truncateString(docTypeName, 24)}
                                    </Col>
                                    <Col sm={1} className="sm_button_col_width">
                                        <DownloadPartial params={{button: false, file: doc, controller: this.controller, parent: this}} />
                                    </Col>
                                    <Col sm={1} className="sm_button_col_width">
                                        <DeleteFile params={{button: false, file: doc, controller: this.controller, parent: this}} />
                                    </Col>
                                </Row>
                            )
                        })
                    }
                    </Col>
                </Row>
            </>
        )
    }
}
