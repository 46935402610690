// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "../_ModelController";

export default class RateModelController extends _ModelController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    get linehaul() {
        if (this.data.linehaul === undefined || this.data.linehaul === null || this.data.linehaul === "") {
            if (this.data.rate_mile !== undefined && this.data.rate_mile !== null && this.data.rate_mile !== null) {
                if (this.distance !== undefined && this.distance !== null) {
                    if (!isNaN(parseFloat(this.data.rate_mile) && !isNaN(parseFloat(this.distance)))) {
                        return parseFloat(this.data.rate_mile) * parseFloat(this.distance)
                    }
                }
            }
        }
        return this.data.linehaul
    }
    set linehaul(value) {
        this.data.linehaul = value
    }
    get distance() {
        return this.controller?.load?.distance
    }
    get total() {
        return Math.round((this.controller?.load?.rate) * 100) / 100
    }
    get profit() {
        let linehaul = (this.linehaul !== undefined) ? this.linehaul : 0
        let profit = Math.round((this.controller?.load?.rate - linehaul) * 100) / 100
        return (isNaN(profit)) ? 0 : profit
    }
    get margin() {
        let margin = Math.round(100 - ((this.linehaul / this.controller?.load.rate) * 100))
        return (isNaN(margin) || !isFinite(margin)) ? 0 : margin
    }
    set rate_mile(value) {
        if (value[0] === ".") {
            value = "0"+value
        }
        this.data.rate_mile = value
    }
    get rate_mile() {
        if (this.data.rate_mile === undefined || this.data.rate_mile === null || this.data.rate_mile === "") {
            if (this.data.linehaul !== undefined && this.data.linehaul !== null && this.data.linehaul !== "") {
                if (this.distance !== undefined && this.distance !== null && this.distance !== 0) {
                    if (!isNaN(parseInt(this.data.linehaul) && !isNaN(parseFloat(this.distance)))) {
                        let rate_mile = Math.round(((parseInt(this.data.linehaul) / parseFloat(this.distance)) * 100)) / 100
                        return (isNaN(rate_mile)) ? 0 : rate_mile
                    }
                }
            }
        }
        return (isNaN(this.data.rate_mile)) ? 0 : this.data.rate_mile
    }
}