// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class InformationModalController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    toggle_extra() {
        this.view.state.extra_mode = !this.view.state.extra_mode
        this.view.forceUpdate()
    }
}
