// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import CompanyModelController from "controllers/ModelControllers/CompanyModelController";

export default class ThirdPanelController extends _viewController {
    constructor(props) {
        super(props)
        this.company = new CompanyModelController({params: {parent: this, 
                                                    controller: this,
                                                    is_view: true}})
    }
    load_cache() {
        if (this.panel_controller?.cache?.["2"]?.data !== undefined) {
            if (this.view?.state?.data?.company === undefined) {
		        this.setState({key: "data", param: "company", value: {name: this.panel_controller.cache["2"]?.data}})
            }
        }
    }
    submit_action() {
	    this.controller?.panel_controller?.selectPanel({panel_num: 3, cache_data: this.view?.state?.data?.company?.name})
    }
    cancel_action() {
        this.controller?.panel_controller?.selectPanel({panel_num: 0, cache_data: undefined})
    }
}
