// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import "./History.css"

export default class HistoryPanel extends _basePartial {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    renderView() {
        return (
            <>
                <Row className="row_padding" id={this.name}>
                    <Col className="cota_panel_object_unsized cota_panel_border max_block">
                        <Row className="panel_title">
                            <Col>
                                History
                            </Col>
                        </Row>
                        {
                            this.controller?.events?.map((event, index) => {
                                return (
                                    <Row>
                                        <Col>
                                            {(event?.preposition !== null && event?.preposition !== undefined) ? event?.preposition + " " + event.title : event?.title}
                                        </Col>
                                        <Col>
                                            {event.date}
                                        </Col>
                                        <Col>
                                            
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </Col>
                </Row>
            </>
        )
    }
}
