// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import CompanyModelController from "controllers/ModelControllers/CompanyModelController";

export default class FirstPanelController extends _viewController {
    constructor(props) {
        super(props)
        this.company = new CompanyModelController({params: {parent: this, 
                                                    controller: this,
                                                    is_view: true}})
    }
    submit_action() {
        if (this.controller?.company?._id === undefined) {
		    console.log(this.state)
            this.controller?.panel_controller?.selectPanel({panel_num: 2, cache_data: this.state?.search?.company?.search_value})
        } else {
            this.controller?.panel_controller?.selectPanel({panel_num: 1, cache_data: this.controller?.company?._id})
        }
    }
    async follow_on_selection({event, obj, data}) { 
	    this.setState({key: "data", param: "company", value: obj})
    }
}
