// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";
import { FaPen } from "react-icons/fa";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Modal from "./Rate.modal.jsx"
import "./RatePanel.css"

export default class RatePanel extends _basePartial {
    constructor(props) {
        super(props)
	    this._modal = Modal
    }
    render_rate_edit() {
        if (this.controller?.view?.state?.context?.authClient?.idTokenParsed?.roles?.includes("INTERNAL_COTA_USER")) {
            return (
                <Col sm={1} className="pointer g-0 edit_col" onClick={this.toggle_modal?.bind(this, {toggle_state: true})}>
                    <FaPen size={15} />
                </Col>
            )
        }
    }
    render_margin() {
        if(!this.controller.quote?.truckload && !this.controller.quote?.raw_quote?.cost) {
            if(!this.controller.quote?.raw_quote) {
                this.controller.view.state.data.quote.raw_quote = {}
            }
            if(this.controller.quote.data?.truckload?.flat_rate) {
                this.controller.quote.raw_quote.cost = parseFloat(this.controller.quote.data?.truckload?.flat_rate)
            }
        }
        if (this.controller.check_role("INTERNAL_COTA_USER") && !isNaN(this.controller?.quote?.revenue)) {
            return (
                <Row>
                    <Col className="margin_text">
                        Margin: ${parseFloat(this.controller?.load?.rate - this.controller.quote?.raw_quote?.cost  ).toFixed(2)}
                    </Col>
                </Row>
            )
        }
    }
    render_raw_cost() {
        if (this.controller.check_role("INTERNAL_COTA_USER")) {
            return (
                <Col className="margin_text">
                    Cost: ${this.controller.quote?.raw_quote?.cost}
                </Col>
            )
        }
    }
    renderView() {
        return (
            <>
                <Row className="row_padding" id={this.name} ref={ (divElement) => { this.divElement = divElement }}>
                    <Col className="cota_panel_object_unsized cota_panel_border rate_panel generic_center pad_right">
                        <Row>
                            <Col className="cost_title">
                                Quoted Rate
                            </Col>
                            {this.render_rate_edit()}
                        </Row>
                        <Row>
                            <Col className="cost">
                                ${this.controller?.load?.rate}
                            </Col>
                        </Row>
                        {this.render_raw_cost()}
                        {this.render_margin()}
                    </Col> 
                    <Col className="cota_panel_object_unsized cota_panel_border co2_panel generic_center pad_left">
                        <Row>
                            <Col className="cost">
                                {this.controller?.load?.co2}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Pounds of CO2
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}
