// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class ToggleSwitchController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    toggle_switch(param) {
        this.state?.parent?.setState({[param]: !this.state?.parent?.state[param]})
    }
}