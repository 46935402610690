// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"
import configs from "./Shipments.json"
import "./Shipments.css"
import "styles/cota.css"

export default class ShipmentsModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.data = {
            note: {}
        }
        this.state.form = configs.form_objects
    }
    renderView() {
        let adjust_frame = this.state?.parent?.state?.refs?.grafanaElement?.getBoundingClientRect()
        this.state.width = adjust_frame?.width
        this.state.base = {y: 100+(33.3*this.controller?.state?.shipment?.message?.row)+((0-this.parent_dims?.height/2)+adjust_frame?.top), x: (this.parent_dims?.left-125)}
        this.init_offset()
        this.controller.modal_ref = this.divElement
        return (
            <Col id={this.name} className="g-0 inner_modal_window cota_panel_border add_shadow" ref={ (divElement) => { this.divElement = divElement }}
                onMouseUp={this.controller.release.bind(this)}
                style={this._inner_modal_location}>
                <Row className="g-0 panel_title title_row">
                    <Col className="bold">
                        {this.controller?.state?.shipment?.message?.data?.company}
                    </Col>
                    <Col className={"bold base_badge badge_col "+this.controller?.determine_badge(this.controller?.state?.shipment?.message?.data?.status)}>
                        {this.controller?.state?.shipment?.message?.data?.status}
                    </Col>
                    <Col>
                        {this.controller?.state?.shipment?.message?.data?.pickup}
                    </Col>
                    <Col>
                        {this.controller?.state?.shipment?.message?.data?.pickup_zip}
                    </Col>
                    <Col>
                        {this.controller?.state?.shipment?.message?.data?.dropoff}
                    </Col>
                    <Col>
                        {this.controller?.state?.shipment?.message?.data?.dropoff_zip}
                    </Col>
                    <Col>
                        {this.controller?.state?.shipment?.message?.data?.trailer_type}
                    </Col>
                    <Col>
                        {this.controller?.state?.shipment?.message?.data?.total_weight}
                    </Col>
                    <Col className="bold">
                        ${this.controller?.state?.shipment?.message?.data?.total_amount}
                    </Col>
                    <Col>
                        {this.controller?.state?.shipment?.message?.data?.distance}
                    </Col>
                    <Col>
                        {this.controller?.state?.shipment?.message?.data?.rate_mile}
                    </Col>
                    <Col sm={1} className="close_button" onClick={this.controller?.close.bind(this)}>
                        X
                    </Col>
                </Row>
            </Col>
        )
    }
}
