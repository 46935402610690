import React from "react";
import _baseViewComponent from "../../../components/General/_baseViewComponent.jsx"
import "./TitleBar.css"

class TitleBar extends _baseViewComponent {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    render() {
        return (
            <div>
                {this.state.title}
            </div>
        )
    }
}

export default TitleBar;