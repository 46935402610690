
// GENERAL REACT MODULES
import React from "react";
import { Row, Col} from 'react-bootstrap'

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import "./InsuranceView.css"

export default class InsuranceView extends _basePartial {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    get panel_color() {
        if (this.controller?.carrier?.insurance?.expired) {
            if (this.controller?.carrier?.insurance?.warning_expired) {
                return "yellow"
            }
            return "red"
        }
        return "blue"
    }
    renderView() {
        return(
            <Row id={this.name}>
                <Col className={"text_row panel left "+this.panel_color}>
                    <Row>
                        <Col className="bold">
                            {this.controller?.carrier?.insurance?.broker_name}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.controller?.carrier?.insurance?.producer}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                           {this.controller?.carrier?.insurance?.email}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                           {this.controller?.carrier?.insurance?.phone_flat}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            General Liability: {this.controller?.carrier?.insurance?.general_liability}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Cargo Liability: {this.controller?.carrier?.insurance?.cargo_liability}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Work Comp: {this.controller?.carrier?.insurance?.workmens_comp}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Expiration {this.controller?.carrier?.insurance?.exp}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}
