// GENERAL REACT MODULES
import React from "react";
import { Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import PanelMenu from "module/components/CotaPanel/PanelMenu/PanelMenu.view.jsx";
import Controller from "./ShipmentsMenu.controller";
import { MainConsumer } from "controllers/MainContext"
import "./ShipmentsMenu.css"

export default class ShipmentsMenu extends PanelMenu {
    constructor(props) {
        super(props)
        this.controller = new Controller({params: {view: this}})
    }
    render() {
        return (
            <MainConsumer>
                {(context) => {
                    this.context = context
                    return (
                        <div className={"panelMenuWrapper panel_menu_header_color "+this._theme}>
                            <Button onClick={this.controller.switchView.bind(this)} 
                                    className={"panelRoundButton switchViewButton btn btn-primary btn-sm primary_button_coloring "+this._theme}>
                                    List View
                            </Button>
                        </div>
                        )
                    }
                }
            </MainConsumer>
        )
    }
}