// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"
import "./StatusPanel.css"
import { statusOptions } from "./Options.js"

export default class StatusModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.closeable = true
        this.state.centered = false
        this.state.draggable = false
        this.state.options    = statusOptions
        this.state.base = {x: 0, y: 0}
        // this.modal_controller = this.state.parent?.partial_controller
        this.state.modal_params = {
            positioned: "relative_to_parent",
            title: "Status",
            title_size: "extra_small",
            relative_position: "below",
            relative_alignment: "start",
            x_adjustment: "140px",
            y_adjustment: "-35px",
            has_overlay: false,
            close_on_outside_click: true,
            closeable: true,
        }
    }
    select_status(index) {
        this.controller.load.status = this.state.options?.[index]?.value
        this.controller.view.forceUpdate()
        this.state.parent?.toggle_modal({})
    }
    renderView() {
        return (
            <>
                {
                    this.state?.options?.map((opt, index) => {
                        return (
                            <Row className="row_height pointer" key={"statusOptions_"+index}>
                                <Col onClick={this.select_status?.bind(this, index)}>
                                    {opt.label}
                                </Col>
                            </Row>
                        )
                    })
                }
            </>
        )
    }
}
