// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "controllers/ModelControllers/_ModelController";

export default class QuoteRequestModelController extends _ModelController {
    constructor(props) {
        super(props)
        let get_form    = this.get_form.bind(this)
        let get_min     = this.get_min.bind(this)
        // let get_zip     = this.get_zip.bind(this)
        // let get_options = this.get_options.bind(this)
        this.form   = {
            get a() {
                return get_form()
            },
            get min() {
                return get_min()
            }
        }
    }
    get items() {
        return this.data?.items
    }
    set items(value) {
        this.data.items = value
    }
    get number() {
        if (this.data?.carrier_quote_number === null || this.data?.carrier_quote_number === undefined) {
            return undefined
        }
        return this.data?.carrier_quote_number
    }
    set number(value) {
        this.data.number = value
    }
    set _id(value) {
        this.data.id = value
    }
    get flat_rate() {
        return this.truckload?.flat_rate
    }
    set flat_rate(value) {
	    this.truckload.flat_rate = value
    }
    get value() {
	    return this.truckload?.value
    }
    set value(value) {
	    this.truckload.value = value
    }
    get trailer_type() {
        return this.truckload?.trailer_type
    }
    set trailer_type(value) {
	    this.truckload.trailer_type = value
    }
    get weight() {
        return this.truckload?.weight    
    }
    set weight(value) {
        if (value !== undefined && value !== null) {
	        this.truckload.weight = value
        }
    }
    get length() {
        return this.truckload?.length    
    }
    set length(value) {
        if (value !== undefined && value !== null) {
	        this.truckload.length = value
        }
    }
    get commodity() {
        return this.truckload?.commodity    
    }
    set commodity(value) {
	    this.truckload.commodity = value
    }
    get per_mile() {
        return this.truckload?.per_mile
    }
    set per_mile(value) {
	    this.truckload.per_mile = value
    }
    get truckload() {
        if (this.data?.truckload === undefined) {
            this.data.truckload = {
                commodity: null,
                weight: null,
                length: null,
                value: null,
                flat_rate: null,
                trailer_type: null,
                per_mile: null
            }
        }
        return this.data?.truckload
    }
    set truckload(value) {
        this.data.truckload = value
    }
    get cost() {
        if (this.data?.cost !== undefined) {
            return this.data?.cost
        }
        if (this.data?.truckload?.flat_rate !== undefined) {
            return this.data.truckload.flat_rate
        }
    }
    set cost(value) {
	    this.data.cost = value
    }
    get raw_quote() {
        return this.data?.raw_quote
    }
    get date() {
        let date = ""
        if (this.data?.shipment?.date !== undefined && this.data?.shipment?.date !== null) {
		    let convert_date = undefined
            try {
                convert_date = new Date(this.data.shipment.date)
            } catch (err) {
                console.log(err)
            } finally {
                if (!isNaN(convert_date)) {
                    date = convert_date
                }
            }
        }
        return date
    }
    set date(value) {
	    this.shipment.date = value
    }
    get shipment() {
        if (this.data !== undefined) {
            if (this.data?.shipment === undefined || this.data?.shipment === null) {
                this.data.shipment = {
                    date: null,
                    reference_number: null,
                    dropoff: {
                        zip: null,
                        options: null
                    },
                    pickup: {
                        zip: null,
                        options: null
                    },
                    accessories: {
                        options: null
                    },
                    company: null
                }
            }
            if (this.data?.shipment?.pickup === undefined || this.data?.shipment?.pickup === null) {
                this.data.shipment.pickup = {}
            }
            this.data.shipment.pickup.zip = this.get_zip('pickup')
            if (this.data?.shipment?.dropoff === undefined || this.data?.shipment?.dropoff === null) {
                this.data.shipment.dropoff = {}
            }
            this.data.shipment.dropoff.zip = this.get_zip('dropoff')
        }
        return this.data?.shipment
    }
    set shipment(value) {
	    this.data.shipment = value
    }
    get pickup() {
	    return this.shipment?.pickup
    }
    set pickup(value) {
	    this.shipment.pickup = value
    }
    get dropoff() {
        return this.shipment?.dropoff
    }
    set dropoff(value) {
        this.shipment.dropoff = value
    }
    get pickup_options() {
	    return this.pickup?.options
    }
    set pickup_options(value) {
	    this.pickup.options = value
    }
    get dropoff_options() {
        return this.dropoff?.options
    }
    set dropoff_options(value) {
        this.dropoff.options = value
    }
    get_date() {
        if (this.date !== "") {
            return this.date
        }
        return new Date()
    }
    get_zip(version) {
        let searched_zip = this.controller?.getState({key: "search", param: version+"_zip"})
        if (searched_zip?.selected_value?.zip !== undefined) {
            return searched_zip.selected_value.zip
        }
        if (searched_zip?.search_value !== undefined) {
            return searched_zip.search_value 
        }
        return ""
    }
    get_min() {
        let form = this.get_form()
        Object.keys(form).forEach(key => {
            if (form[key] === null || form[key] === "") {
                delete form[key];
            }
        });
        return form
    }   
    get_form() {
        return this.controller.getState({key: "form"})
    }
    get summary() {
        return this.generate_quote()
    }
    get total_weight() {
        if (isNaN(this.data?.total_weight)) {
            return undefined
        }
        return this.data?.total_weight
    }
    set total_weight(value) {
        if (!isNaN(value)) {
            if (value >= 0) {
                this.data.total_weight = value
            }
        }
    }
    get total_volume() {
        if (isNaN(this.data?.total_volume)) {
            return undefined
        }
        return this.data?.total_volume
    }
    set total_volume(value) {
        if (!isNaN(value)) {
            if (value >= 0) {
                this.data.total_volume = value
            }
        }
    }
    get pallet() {
        if (this.data?.pallet === undefined) {
                this.data.pallet = {}
        }
        return this.data?.pallet
    }
    set pallet(value) {
        this.data.pallet = value
    }
    generate_quote() {
	    let quote_request = JSON.parse(JSON.stringify(this.data))
	    delete quote_request.per_mile
        quote_request.name  = this.controller.getParam({key: "name"})
        quote_request.total_weight = 0
	    quote_request.number = this.number
        quote_request.shipment = JSON.parse(JSON.stringify(this.shipment))
	    const dateOptions = { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' };
        if (this.get_date() !== undefined) {
            quote_request.shipment.date = this.get_date().toISOString().replace(/T.*/,'').split('-').join('-')
        }
        if (quote_request?.cost !== undefined) { 
            if (!isNaN(parseFloat(quote_request.cost))) {
                quote_request.cost = parseFloat(quote_request.cost)
            } else {
                delete quote_request.cost
            }
        }
        if (this.controller?.view?.state?.ltl_switch) {
            // Type, Weight, Length, Commodity, Value
            delete quote_request.items
            delete quote_request.pallet
        } else {
            if (this.controller?.view?.state?.know_num_of_pallets) {
                // Weight & Volume
                quote_request.pallet = {
                    total_weight: String(this.total_weight),
                    total_volume: String(this.total_volume)
                }
                quote_request.total_weight = Number(this.total_weight)
                quote_request.total_volume = Number(this.total_volume)
		        delete quote_request.items
            } else {
		        delete quote_request.pallet
		        if (quote_request.items.length > 0) {
                    quote_request.items.map((item, index) => {
                        item.class = item.class?.toString()
                        if (Object.values(item).length < 7) {
                            quote_request.items.splice(index, 1)
                        }
                        if ((item?.number !== "" && item?.number !== undefined) &&
                                (item?.weight !== "" && item?.weight !== undefined)) {
                            quote_request.total_weight = quote_request.total_weight + Number(item?.weight)
                        }
                    })
                } else {
                    delete quote_request.items
                }
            }
        }
        if (!this.controller?.view?.state?.ltl_switch) {
            delete quote_request.truckload
        }
	    delete quote_request.raw_quote
        return quote_request
    }
}
