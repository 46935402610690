// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import RateItemModelController from "controllers/ModelControllers/Shipping/RateItemModelController.jsx"

export default class RateModalController extends _viewController {
    constructor(props) {
        super(props)
        this.rate_item  = new RateItemModelController({params: {parent: this,
                            controller: this,
                            is_view: true}})
    }
    add_rate() {
        if ((this.modal_controller?.rate_item?.rate_type !== undefined && this.modal_controller?.rate_item?.rate_type !== "") 
                && (this.modal_controller?.rate_item?.rate !== undefined && this.modal_controller?.rate_item?.rate !== "")) {
            this.controller.load.rate_items.push(JSON.parse(JSON.stringify(this.modal_controller?.rate_item?.summary)))
            this.modal_controller.setState({key: "data", param: "rateitem", value: {rate: "", rate_type: this.modal_controller?.rate_item?.rate_type }})
            this.forceUpdate()
        }
    }
    delete_rate(index) {
        let item = this.controller?.load?.rate_items?.[index]
        this.controller.load.rate_items = this.controller.load.rate_items.filter((_, i) => i !== index)
        if (item?.id !== undefined) {
            this.api.loads.delete({caller: this, value: item?.id, params: {type: "rate_item"} })
        }
        this.forceUpdate()
    }
}
