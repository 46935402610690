// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"
import configs from "./Shipper.json"
import "./Shipper.css"

export default class ShipperObjectModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.form = configs.form_objects
        this.modal_controller = this.state.parent?.partial_controller
    }
    renderView() {
        this.parent_dims = this.state.parent?.divElement?.getBoundingClientRect()
        this.state.base = {y: this.parent_dims?.top, x: 20}
        return (
            <Col id={this.name} className="g-0 inner_modal_window cota_panel_border add_shadow" ref={ (divElement) => { this.divElement = divElement } } style={this._inner_modal_location}>
                <Row className="g-0">
                    <Col>
                        <Row className="g-0 panel_title title_row">
                            <Col>
                                People
                            </Col>
                            <Col className="g-0 pointer" onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})} sm={1}>
                                X
                            </Col>
                        </Row>
                        <Row className="inner_people_window g-0">
                            <Col>
                                {
                                    this.modal_controller?.contact?.people?.map((person, index) => {
                                        return (
                                            <Row className="g-0 people_row pointer" onClick={this.modal_controller.select_person.bind(this.state.parent, index)} key={"person_"+index}>
                                                <Col>
                                                    {person?.first_name} {person?.last_name}
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        )
    }
}
