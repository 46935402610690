// GENERAL REACT MODULES
import React from "react";
import { Row, Col } from 'react-bootstrap'

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./ListPartial.controller.jsx"
import "./List.css"

export default class ListPartial extends _basePartial {
    constructor(props) {
        super(props)
        if (props?.params?.list_modal !== undefined) {
            this._modal = props.params.list_modal
        }
        this.modal._closeable   = true
        this.state.modal        = {}
        this.partial_controller = new Controller({params: {view: this}})
        this.state.data.bolds   = [0]
    }
    render_link({index, value}) {
        let url = this.state?.urls?.[index]
        if (url !== undefined && !this.state.managed) {
            return (
                <a href={url}>{value}</a>
            )
        }
        return (<span>{value}</span>)
    }
    renderView() {
        if(this.state.attr === "documents"){
            for(let i = 0; i<this.controller?.[this.state?.obj]?.documents?.length; i++){
                if(this.controller?.[this.state?.obj]?.documents[i].name === "undefined"){
                    this.controller[this.state.obj].documents[i].name = this.controller?.[this.state?.obj]?.documents[i]?.document_type?.label
                }
            }
        }
        if (this.state.attr !== undefined) {
            this.state.base_items = this.controller?.[this.state?.obj]?.[this.state?.attr]
        } else {
            this.state.base_items = this.controller?.[this.state?.obj]
        }
        if (this.state?.param !== undefined && this.state.base_items !== undefined) {
         
            // if(this.state.attr === "documents" && this.state.param === "document_type")
            // {  if(this.state.base_items.name !== "undefined") {
            //     console.log("CHECKING",this.state.base_items)
            //      this.state.base_items = this.state.base_items.name
            // }  }
                this.state.items = this.state.base_items?.map(a => a?.[this.state.param])
                this.state.urls  = this.state.base_items?.map(a => a?.url)
        } else {
                this.state.items = this.state.base_items
        }
        return(
            <Row id={this.name} className="add_shadow">
                <Col>
                    <Row className="titles">
                        {
                            this.state?.keys?.map((item, index) => {
                                return (
                                    <Col>
                                        {this.state?.keys?.[index]?.replace(/_/g, ' ')}
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    {
                        this.state?.items?.map((item, index) => {
                            if (item !== undefined) {
                                return (
                                    <Row className={"line "+this.partial_controller.get_row_color(index)+" "+this.partial_controller.get_row_class({index: index, limit: this.state?.items.length})} key={"line_"+index}>
                                        {Object.values(item).map((value, i) => {
                                            if (this.state.keys.indexOf(Object.keys(item)?.[i]) > -1) {
                                                return (
                                                    <Col className={"pointer limit_col"} key={"line_col_"+index+"_"+i} 
                                                            onClick={this.partial_controller.handle_toggle?.bind(this, {item: item, index: index})}
                                                            onMouseEnter={this.partial_controller.hover_line.bind(this, index)} 
                                                            onMouseLeave={this.partial_controller.hover_line.bind(this, index)}>
                                                        {this.render_link({index: index, value: value})}
                                                    </Col>
                                                )
                                            }
                                        })}
                                    </Row>
                                )
                            }
                        })
                    }
                </Col>
            </Row>
        )
    }
}



