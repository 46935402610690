// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal";
import "./RequestDoc.css";

export default class RequestDocModal extends _baseModal {
    constructor(props) {
        super(props)
        this.modal_controller = this.state.parent?.partial_controller
        this.state.modal_params = {
            positioned: "relative_to_parent",
            title: "Resend File ?",
            title_size: "",
            closeable: true,
            has_overlay: true,
            close_on_outside_click: false,
            x_adjustment: "-100px",
            y_adjustment: "-200px",
            draggable: false,
        }
        this.state.form = {
            "email": {
                "title": "receipient",
                "name": "email",
                "type": "select",
                "subtype": "name",
                "placeholder": "Select Email"
            }
        }
    }
    renderView() {
        this.modal_controller.modal = this
        if (this.parent?.state?.title !== undefined) {
             this.state.modal_params.title = "Send "+this.parent?.state?.title+" ?"
        }
        if (this.state.is_sending) {
            return (
                <>
                    <Row className="g-0 col_padding remove_top_padding">
                        <Col className="g-0 col_padding remove_padding generic_center">
                            Document Sent!
                        </Col>
                    </Row>
                </>
            )
        }
        return (
            <>
                <Row className="g-0 col_padding remove_top_padding">
                    <Col className="g-0 col_padding remove_padding">
                        <Row>
                            <Col>
                                A {this.parent?.state?.title} will be generated and automatically emailed. A copy can also be found in the Documents section of the Load.
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Select Receipients email: 
                            </Col>
                            <Col>
                                {this.formObject({data: this.state.form.email, owner: this.state.parent, options: this.modal_controller.emails, controller: this.modal_controller})}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                        <Row>
                            <Col className="center_col">
                                <Button className={"primary_button_coloring "+this._theme} onClick={this.parent?.toggle_modal?.bind(this.parent, {toggle_state: false})}>
                                    No
                                </Button>
                            </Col>
                            <Col className="center_col">
                                <Button className={"primary_button_coloring "+this._theme} onClick={this.parent?.partial_controller?.submit_request.bind(this.parent?.partial_controller)}>
                                    Yes
                                </Button >
                            </Col>
                        </Row>
                        <Row className="gen_row">
                            <Col className="gen_left_col">
                                Generate only:
                            </Col>
                            <Col className="gen_right_col">
                                <Button className={"primary_button_coloring gen_button "+this._theme} onClick={this.parent?.partial_controller?.submit_request.bind(this.parent?.partial_controller, {generate_only: true})}>
                                    Generate
                                </Button >
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}
