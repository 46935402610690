import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import _baseModal from "components/General/_baseModal.jsx";
import "./CarrierDetails.css"

export default class CarrierDetailsModal extends _baseModal {
    constructor(props) {
        super(props)
	    this.state.base         = {x: 0, y: 0}
        this.state.css          = {hidden: true}
	    this.state.closeable    = true
        this.handleAccount      = this.handleAccount.bind(this);
        this.handleRoute        = this.handleRoute.bind(this)
    }
    handleAccount() {
        this.setState((state) => ({
            hidden_account: !state.hidden_account
        }));
    }
    handleRoute() {
        this.setState((state) => ({
            hidden_route: !state.hidden_route
        }));
    }
    renderView() {
	    this.parent_dims = this.state.parent?.divElement?.getBoundingClientRect()
        this.state.base = {y: -320, x: 340}
        return (
            <Col className="g-0 inner_modal_window cota_panel_border add_shadow modal_bg" ref={ (divElement) => { this.divElement = divElement } } style={this._inner_modal_location}>
                {
                    this.render_closeable()
                }
                <Row>
                    <Col>
                        {this.controller.billing_details.bank}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Routing: 
                        <Button onClick={this.handleRoute} className={"primary_button_coloring "+this._theme}>
                            {this.state.hidden_route ? this.controller.billing_details.routing_number : "********* show"}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Account:
                        <Button onClick={this.handleAccount} className={"primary_button_coloring "+this._theme}>
                            {this.state.hidden_account ? this.controller.billing_details.account_number : "********** show "}
                        </Button>
                    </Col>
                </Row>
            </Col>
        )
    }
}
