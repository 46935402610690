// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import CompanyModelController from "controllers/ModelControllers/CompanyModelController";

export default class ApplicationStatusController extends _viewController {
    constructor(props) {
        super(props)
        this.company = new CompanyModelController({params: {parent: this, 
                                                            controller: this,
                                                            is_view: true}})
    }
    load_data() {
        this.api?.companies.get({caller: this, id: this.view?.state?.data?.role?.company?.id, callback: {f: this.load_company}})
    }
    load_company({caller, params, results}) {
        caller.setState({key: "data", param: "company", value: results?.data?.company})
    }
}
