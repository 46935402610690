// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "controllers/ModelControllers/_ModelController";

export default class QuoteModelController extends _ModelController {
    constructor(props) {
        super(props)
        let get_co2_amount   = this.get_co2_amount.bind(this)
        this.co2 = {
            get amount() {
                return get_co2_amount()
            }
        }
    }
    // Probably needs to be re-labeled to margin for better alignment to vernacular. Along with API
    get revenue() {
        if (this.data?.revenue !== undefined && this.data?.revenue !== null) {
            return parseFloat(this.data?.revenue).toFixed(2)
        }
        return parseFloat((this.data.cost) - this.data?.raw_quote?.cost).toFixed(2)
    }
    get margin() {
        return parseFloat((this.cost) - this.data?.raw_quote?.cost).toFixed(2)
    }
    get ltl_truckload() {
        return this.data?.ltl_truckload
    }
    get output() {
        return this.data?.output
    }
    get raw_quote() {
        return this.data?.raw_quote
    }
    set raw_quote(value) {
        this.data.raw_quote = value
    }
    get rate_reply() {
        return this.output?.rateReplyDetails?.[0]
    }
    get details() {
        return this.rate_reply?.ratedShipmentDetails?.[0]
    }
    get number() {
        if (this.data?.cota_id !== undefined && this.data?.cota_id !== null) {
            return this.data.cota_id
        }
        if (this.data?.carrier_quote_number !== undefined) {
            return this.data?.carrier_quote_number
        } 
        if (this.data?.number !== undefined) {
            return this.data.number
        }
        return
    }
    get date() {
        return this.rate_reply?.commit
    }
    get cost() {
        return this.data?.cost
    }
    set cost(value) {
        if (String(value).split('.')[1]?.length > 2) {
            value = parseFloat(value).toFixed(2)
        }
        this.data.cost = value
        this.data.quote_rate = parseFloat(value / this.data?.raw_quote?.cost).toFixed(2)
    }
    get days() {
        return this.data?.days
    }
    get vendor() {
        return this.data?.vendor
    }
    get_co2_amount() {
        return this.data?.co2
    }
    get volume() {
        return this.data?.volume
    }
    get weight() {
        return this.data?.weight
    }
    get total_volume() {
        return (this.data?.total_volume !== undefined) ? this.data?.total_volume : this.volume
    }
    get total_weight() {
        return (this.data?.total_weight !== undefined) ? this.data?.total_weight : this.weight
    }
    get trailer_type() {
        return this.data?.trailer_type
    }
    get trailer_type_id() {
        return this.trailer_type?.id
    }
    get quote_rate() {
        if (this.data?.quote_rate === undefined || this.data?.quote_rate === null) {
            return 1
        }
        return this.data?.quote_rate
    }
    set quote_rate(value) {
        if (this.controller.check_role("INTERNAL_COTA_USER")) {
            this.data.quote_rate = parseFloat(value)
            this.data.cost = parseFloat(this.data?.raw_quote?.cost * this.quote_rate).toFixed(2)
        }
    }
    get summary() {
        let _summary = this.data
        _summary.cost = parseFloat(_summary.cost)           
        _summary.quote_rate = parseFloat(_summary.quote_rate)        
        return _summary 
    }
}
