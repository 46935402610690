export const rateOptions = [
    { value: "flat_rate", label: "Flat Rate" },
    { value: "stop_fee", label: "Stop Fee" },
    { value: "tarp_fee", label: "Tarp Fee" },
    { value: "permits_tolls", label: "Permits Tolls" },
    { value: "overdimension_fee", label: "Overdimision Fee" },
    { value: "detention", label: "Detention" },
    { value: "other", label: "Other" }
];
export const mileOptions = [
    { value: "mile", label: "Mile" },
    { value: "flat", label: "Flat" },
    { value: "percentage", label: "Percentage" },
    { value: "each_stop", label: "Each Stop" },
    { value: "other", label: "Other" }
];

