// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "../_ModelController";

export default class RateItemModelController extends _ModelController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    set rate_type(value) {
        this.data.rate_type = value
    }
    get rate_type() {
        return this.data?.rate_type
    }
    set rate(value) {
        this.data.rate = value
    }
    get rate() {
        return this.data?.rate
    }
    get summary() {
        return this.data.scrub()
    }
}