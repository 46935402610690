// GENERAL REACT MODULES
import { Col } from 'react-bootstrap'

// OBJECT SPECIFIC MODULES
import "./UserIcon.css"
import Controller from './UserIcon.controller';

// ALL OTHER MODULES
import _baseComponent from "components/General/_baseComponent";

export default class UserIcon extends _baseComponent {
    constructor(props) {
        super(props)
        this.controller = new Controller({params: {view: this}})
    }
    render() {
        return (
            <Col className="user_icon_col" id={this.name}>
                <div className="user_icon">
                    {this.controller.get_user_image(this.view?.state?.url)}
                    <div className='user_badge'>
                        {this.controller.get_user_badge()}
                    </div>
                </div>
            </Col>
        )
    }
}