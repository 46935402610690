// GENERAL REACT MODULES
import React from "react";
import { Button } from "react-bootstrap";
import MultiPanel from "components/MultiPanels/MultiPanel";
import "styles/generic.css"
import "./ApplicationStatus.css"
import { Container, Row, Col } from 'react-bootstrap'
import PayorObject from "module/pages/Shipments/ShipmentPartials/Payor/Payor.partial.jsx"
import Controller from "./ApplicationStatus.controller";

export default class ApplicationStatus extends MultiPanel {
    constructor(props) {
	    super(props)
        this.controller = new Controller({params: {view: this}})
    }
    renderInnerFrame() {
	    this.state.data.role = this.state?.context?.self?.roles?.[0]
        return(
            <Container className="generic_panel_object generic_90">
                <Row className="status_window">
                    <Col>
                        <Row>
                            <Col>
                                <PayorObject params={{parent: this, controller: this.controller, center: true,
                                        data: {company: this.controller?.company}, closeable: false}}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller?.company?.name} request
                            </Col>
                            <Col>
                                {this.controller?.company?.role?.status}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button className="">Send Again</Button>
                            </Col>
                            <Col>
                                Request Help
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}
