// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"
import configs from "../Information.json"
import "../Information.css"
import BasicInfo from "./BasicInfo.partial"
import ExtraInfo from "./ExtraInfo.partial"
import ModalController from "./Information.modal.controller.jsx"

export default class InformationModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.form = configs.form_objects
	    this.state.extra_mode = false
        this.modal_controller = new ModalController({params: { view: this }})
        this.state.modal_params = {
            positioned: "middle_of_panel",
            title: "Information",
            title_size: "small",
            x_adjustment: "0px",
            y_adjustment: "-100px",
            has_overlay: false,
            close_on_outside_click: true,
            closeable: true,
            draggable: true
        }
    }
    renderView() {
        return (
            <>
                {
                    (!this.state.extra_mode) ? <BasicInfo params={{parent: this, controller: this.controller, modal_controller: this.modal_controller }} /> 
                                                : <ExtraInfo params={{parent: this, controller: this.controller, modal_controller: this.modal_controller }} />
                }
                <Row>
                    <Col className="button_col">
                        <Button className={"primary_button_coloring "+this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </>
        )
    }
}
