// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import creator_request from "assets/graphql/Quotes/Quote.creator.graphql.json"
import customer_request from "assets/graphql/Quotes/Quote.customer.graphql.json"

export default class QuotesController extends _viewController {
    constructor(props) {
        super(props)
        this.routing = {
            default: {
                path: 0,
                screen: 2
            },
            load: {
                path: 1,
                screen: 0,
            },
            alt: "load_number"
        }
        this.requests   = [{callback: {f: this.process_users}, endpoint: "quotes", req_name: "creators"},
                            {callback: {f: this.process_customers}, endpoint: "quotes", req_name: "customers"}]
    }
    change_date_value(dates) {
        if (dates?.[0] !== undefined) {
            this.state.from_date = new Date(dates[0]).valueOf()
        } else {
            this.state.from_date = undefined
        }
        if (dates?.[1] !== undefined) {
            this.state.to_date = new Date(dates[1]).valueOf()
        } else {
            this.state.to_date = undefined
        }
        this.forceUpdate()
    }
    processEventReaction(decoded) {
        if (this._isMounted) {
            if (decoded.channel === "grafana_button") {
                this.view.handleScreen({path_num: 1, screen_num: 0, data: decoded.message.data})
            }
            if (decoded.channel === "grafana_row") {
                this.view.handleScreen({path_num: 0, screen_num: 2, data: decoded.message.data})
            }
        }
    }
    handleCloseScreen() {
        this.resetScreen()
    }
    async get_users(name) {
        name        = (name === undefined) ? "" : name
        let body    = JSON.parse(JSON.stringify(creator_request))
        body.query  = body.query.replace("fcontacts", 'fcontacts(name: "'+name+'")')
        await this.api.quotes.ask({caller: this, params: {body: body}, callback: {f: this.process_users}})
    }
    async get_customers(name) {
        name        = (name === undefined) ? "" : name
        let body    = JSON.parse(JSON.stringify(customer_request))
        await this.api.quotes.ask({caller: this, params: {body: body}, callback: {f: this.process_customers}})
    }
    process_users({caller, params, results}) {
        if (results !== undefined) {
            results?.data?.quotes?.forEach((quote, index) => {
                if (quote?.creator !== undefined && quote?.creator !== null) {
                    caller.view.state.user_options.push({ value: quote?.creator, label: quote?.creator?.Capitalize() })
                }
            })
        }
    }
    process_customers({caller, params, results}) {
        if (results?.data?.quotes !== undefined) {
            results?.data?.quotes?.forEach((quote, index) => {
                if (quote?.customer?.name !== undefined && quote?.customer?.id !== undefined) {
                    caller.view.state.customer_options.push({ value: quote.customer?.id, label: quote.customer?.name?.Capitalize() })
                }
            })
        }
    }
    follow_on_dropdown({key, param, value}) {
        this.view.forceUpdate()
    }
    display_internal() {
        if (this.check_role("INTERNAL_COTA_USER")) {
            return ""
        }
        return " hide"
    }
    toggle_ltl(index) {
        let radio_item = this.state?.radios?.[index]
        radio_item.checked = !radio_item?.checked
        this.state.radios[index] = radio_item
        this.forceUpdate()
    }
    resolve_ltl_toggle() {
        if (this.view.state?.radios?.[0]?.checked && this.view.state?.radios?.[1]?.checked) {
            return null
        }
        if (this.view.state?.radios?.[0]?.checked) {
            return true
        }
        if (this.view.state?.radios?.[1]?.checked) {
            return false
        }
    }
}
