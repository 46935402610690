import React from 'react'
import { MdOutlineRequestQuote, MdOutlinePersonOutline } from "react-icons/md";
// import { GoDashboard } from "react-icons/go";
import { BsTruckFlatbed } from "react-icons/bs";
import { FaBoxes } from "react-icons/fa";

import Home from "module/pages/Home/Home.view.jsx"
import Quotes from "module/pages/Quotes/Quotes.view.jsx"
import Shipments from 'module/pages/Shipments/Shipments.view.jsx'
import Contacts from 'module/pages/Contacts/Contacts.view.jsx';
import Carriers from 'module/pages/Carriers/Carriers.view.jsx';
import { RiDashboard3Line } from "react-icons/ri"

const mContext = {
                    menus: [[{name: "Home", icon: <RiDashboard3Line className="menuListIcon"/>, internal: true, link: "/",
                                requirements: {roles: ["INTERNAL_COTA_USER"]}}, // REMOVE THIS LATER ??
                        {name: "Quotes", icon: <MdOutlineRequestQuote className="menuListIcon"/>, internal: true, link: "/quotes"},
                        {name: "Shipments", icon: <FaBoxes className="menuListIcon"/>, internal: true, link: "/shipments"},
                        {name: "Contacts", icon: <MdOutlinePersonOutline className="menuListIcon"/>, internal: true, link: "/contacts"},
                        {name: "Carriers", icon: <BsTruckFlatbed className="menuListIcon"/>, internal: true, link: "/carriers", 
                            requirements: {roles: ["INTERNAL_COTA_USER"]}}]],
                    pages: {"/": {link: "/", page: <Home />},
                            "/quotes": {link: "/quotes", page: <Quotes />},
                            "/shipments": {link: "/shipments", page: <Shipments />},
                            "/contacts": {link: "/contacts", page: <Contacts />},
                            "/carriers": {link: "/carriers", page: <Carriers />}
                        },
                    default: "/"
}
mContext.getMenu = function() {
    return mContext.menus
}

mContext.getPages = function() {
    return mContext.pages
}
mContext.getPage = function({link, params}) {
    return mContext.pages[link].page
}
const ModuleContext = React.createContext(mContext)

export const ModuleProvider = ModuleContext.Provider
export const ModuleConsumer = ModuleContext.Consumer

export default ModuleContext
