// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";
import { FaPen } from "react-icons/fa";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Modal from "./Commodity.modal";
import "../ShippingPartials.css"
import "./Commodity.css"
import Controller from "./Commodity.controller.jsx"

export default class CommodityPanel extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: { view: this, controller: this.controller }})
        this._modal = Modal
        this.state.data.commodities = [{}]
        this.state.imperial = true
    }
    render_rows({commodities_list}) {
        if ((commodities_list) !== undefined) {
            return commodities_list.map((commodity, index) => {
                return (
                    <Row key={"commodity_partial_rows_"+index} className="pad_rows">
                        <Col className="center_text">
                            {this.render_commodity_row_label(commodity)}
                        </Col>
                    </Row>
                )
            })
        }
    }
    render_commodity_row_label(commodity) {
        if (commodity?.name !== undefined && commodity?.name !== null && commodity?.name !== "") {
            return commodity?.name
        } else {
            return commodity.quantity +" un-named items"
        }
    }
    renderView() {
        // create a combined array of cached quote data and commodidites to make sure all info is displayed
        let commodities_list = this.controller?.commodities
        if (Array.isArray(this.controller?.quote?.items)) {
            commodities_list.concat(this.controller?.quote?.items)
        }
        return (
            <>
                <Row className="row_padding" id={this.name} ref={ (divElement) => { this.divElement = divElement }}>
                    <Col className="cota_panel_object_unsized cota_panel_border">
                        <Row className="panel_title">
                            <Col className="edit_button">
                                &nbsp;
                            </Col>
                            <Col>
                                Commodity
                            </Col>
                            <Col className="edit_button g-0" onClick={this.toggle_modal?.bind(this, {toggle_state: true})}>
                                <FaPen size={15} />
                            </Col>
                        </Row>
                        {
                            this.render_rows({commodities_list: commodities_list})
                        }
                        <Row className="pad_rows">
                            <Col className="totals totals_title">
                                Total Weight
                            </Col>
                            <Col className="totals">
                                {commodities_list?.reduce((accumulator, currentValue) => accumulator + ((currentValue?.weight === undefined) ? 0 : currentValue?.weight), 0)} {(this.state.imperial) ? "lbs" : "kg"}
                            </Col>
                            <Col className="totals totals_title">
                                Total Length
                            </Col>
                            <Col className="totals">
                                {this.partial_controller.resolve_total_length(commodities_list)}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}
