// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

import CompanyModelController from "controllers/ModelControllers/CompanyModelController.jsx"
import ShipmentModelController from "controllers/ModelControllers/Shipping/ShipmentModelController.jsx"
import CommodityModelController from "controllers/ModelControllers/Shipping/CommodityModelController.jsx"
import EquipmentModelController from "controllers/ModelControllers/Shipping/EquipmentModelController.jsx"
import QuoteModelController from "controllers/ModelControllers/Quotes/QuoteModelController"
import InformationModelController from "controllers/ModelControllers/Shipping/InformationModelController.jsx"
import StopModelController from "controllers/ModelControllers/StopModelController.jsx"

export default class SinglePageController extends _viewController {
    constructor(props) {
        super(props)
        this.company        = new CompanyModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
	    this.load	        = new ShipmentModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
        this.commodity      = new CommodityModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true,
                                                            _param: "commodities",
                                                            index: 0}})
        this.equipment      = new EquipmentModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
        this.quote          = new QuoteModelController({params: {parent: this,
                                                        controller: this,
                                                        is_view: true}})
        this.information	= new InformationModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true}})
        this.pickup         = new StopModelController({params: {parent: this,
                                                        controller: this,
                                                        is_view: true, _param: "pickup"}})  
        this.consignee      = new StopModelController({params: {parent: this,
                                                        controller: this,
                                                        is_view: true, _param: "consignee"}})
    }
    load_cache() {
        let cache = this.panel_controller.getCache("4")
        if (cache !== undefined) {
            console.log(cache)
            this.view.state.data = cache?.data?.data
        }
    }
}

