export const internalOptions = [
    { value: "quote", label: "Quote" },
    { value: "shipment", label: "Shipment" },
    { value: "contact", label: "Contact" },
    { value: "carrier", label: "Carrier" }
];
export const externalOptions = [
        { value: "quote", label: "Quote" },
        { value: "shipment", label: "Shipment" },
        { value: "contact", label: "Contact" }
]
