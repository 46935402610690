// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";
import { FaPen } from "react-icons/fa";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Modal from "./InformationModal/Information.modal.jsx";
import "../ShippingPartials.css"
import "./Information.css"
import Controller from "./Information.controller.jsx"

export default class InformationPanel extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: { view: this, controller: this.controller }})
        this._modal = Modal
    }
    renderCol({name, attribute}) {
        if (attribute === undefined || attribute === "" || attribute === null) {
            return (<></>)
        }
        return (
            <Col>
                {name} {attribute}
            </Col>
        )
    }
    renderView() {
        return (
            <>
                <Row className="row_padding" id={this.name} ref={ (divElement) => { this.divElement = divElement }}>
                    <Col className="cota_panel_object_unsized cota_panel_border">
                        <Row className="panel_title">
                            <Col className="edit_button">
                                &nbsp;
                            </Col>
                            <Col>
                                Information
                            </Col>
                            <Col className="edit_button g-0" onClick={this.toggle_modal?.bind(this, {toggle_state: true})}>
                                <FaPen size={15} />
                            </Col>
                        </Row>
                        <Row>
                            {this.renderCol({name: "PO #", attribute: this.partial_controller?.information?.po_number})}
                            {this.renderCol({name: "Container #", attribute: this.partial_controller?.information?.container_number})}
                        </Row>
                        <Row>
                            {this.renderCol({name: "BOL #", attribute: this.partial_controller?.information?.bol_number})}
                            {this.renderCol({name: "House BOL #", attribute: this.partial_controller?.information?.house_bol})}
                        </Row>
                        <Row>
                            {this.renderCol({name: "Vessel Name #", attribute: this.partial_controller?.information?.vessel_name})}
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}
