// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import CommodityModelController from "controllers/ModelControllers/Shipping/CommodityModelController.jsx"

export default class CommodityModalController extends _viewController {
    constructor(props) {
        super(props)
        this._configs = {
            local: "commodity",
            array: "commodities"
        }
        this.commodity = this.new_object()
    }
    new_object() {
        return new CommodityModelController({params: {parent: this, 
                                                    controller: this, 
                                                    is_view: true}})
    }
    clear_object() {

    }
    delete_commodity({index}) {
        this.controller._delete({name: this?.modal_controller?._configs?.array, index: index})
    }
    map_commodity_rows() {
        let commodities = this.view?.controller?.getState({key: "data", param: this?._configs.array})
        if (commodities !== undefined) {
            return Object.values(commodities).map((commodity, index) => {
                return (
                    <span key={"commodity_row_"+index}>
                        {this.render_commodity_row({data: commodity, index: index})}
                    </span>
                )
            })
        }
    }
    render_commodity_row({data, index}) {
        let commodity = this.commodity._load({data: data})
        return (
            <Row key={"commodity_row_"+index}>
                <Col className="commodity_row_title">
                    {commodity?.name}
                </Col>
                <Col>
                    {commodity?.type?.Capitalize()}
                </Col>
                <Col>
                    {commodity?.quantity}
                </Col>
                <Col>
                    {commodity?.labeled?.count}
                </Col>
                <Col sm={1} className="generic_center">
                    {commodity?.length}
                </Col>
                <Col sm={1} className="x_cell">
                    X
                </Col>
                <Col sm={1} className="generic_center">
                    {commodity?.width}
                </Col>
                <Col sm={1} className="x_cell">
                    X
                </Col>
                <Col sm={1} className="generic_center">
                    {commodity?.height}
                </Col>
                <Col>
                    {commodity?.weight}
                </Col>
                <Col>
                    {commodity?.class}
                </Col>
                <Col sm={1} className="x_cell x_col delete_button" onClick={this.delete_commodity.bind(this, {index: index})}>
                    X
                </Col>
            </Row>
        )
    }
    add_object() {
        if (this.commodity._sum > 0) {
            let data = JSON.parse(JSON.stringify(this.commodity?.summary))
            this.view.state.data.commodity = JSON.parse(JSON.stringify(this.view.schema))
            this.view.controller?.add_commodity(data)
            this.view.state.edit_mode = false
            this.view.parent.forceUpdate()
        }
    }
    close() {
        this.add_object()
        this.view.state.edit_mode = false
        this.view?.parent?.toggle_modal({ toggle_state: false })
    }
    add_switch() {
        if (this.view?.state?.ltl_switch !== true) {
            return (
                <Col sm={1} className="g-0 save_col col_padding">
                    <Button className={"toggle_button_style " + this._theme} onClick={this.add_object.bind(this)}>
                        {(this.view.state.edit_mode === true) ? "Save" : "Add Items" }
                    </Button>
                </Col>
            )
        } else {
            return (
                this.add_save()
            )
        }
    }
    add_save() {
        return (
            <Col sm={1} className="g-0 save_col col_padding">
                <Button className={"primary_button_coloring " + this._theme} onClick={this.close.bind(this)}>
                    Save
                </Button>
            </Col>
        )
    }
    async estimate_class() {
        if (!this.state.requesting) {
            this.setState({requesting: true})
            await this.api.class.estimate({caller: this, value: {density: this.modal_controller.commodity?.density,
                volume: this.modal_controller.commodity?.volume,
                weight: this.modal_controller.commodity?.weight}, callback: {f: this.modal_controller.process_class}})
        }
    }
    process_class({caller, params, results}) {
        if (caller.modal_controller.commodity !== undefined && results?.data?.class !== undefined) {
            caller.modal_controller.commodity.class = results?.data?.class
        }
        caller.setState({requesting: false})
    }
    select_commodity(index) {
        this.state.edit_mode            = true
        this.state.data.editing_index   = index
        this.state.data.commodity       = this.controller.commodities?.[index]
        this.controller.commodities.splice(index, 1)
        this.forceUpdate()
    }
    follow_toggle() {
        if (this.view.state.edit_mode) {
            this.add_object()
        }
    }
    resolve_total_length(commodities) {
        let commodities_length = 0
        for (const comm of commodities) {
            if (comm !== undefined) {
                let remainder   = 0
                let quantity    = comm.quantity
                let length      = comm.length
                if (comm.quantity % 2) {
                    remainder   = 1
                    quantity    = quantity - 1
                }
                commodities_length = commodities_length + ((parseInt(length) * parseInt(quantity))) / 2 + (remainder * length);
            }
        }
        if (this.view.state.imperial) {
            if ((commodities_length / 12) > 2) {
                return commodities_length.toString() + "in ("+( Math.round((commodities_length / 12) * 10) / 10).toString()+"ft)"
            }
        }
        return commodities_length.toString() + ((this.view.state.imperial) ? "in" : "mm")
    }
}

