// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class HomeController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    check_array({array, value}) {
        return (array.indexOf(value) > -1);
        // return true
    }
    col_css({value}) {
        let array = this.getState({key: "data", param: "bolds"})
        if (this.check_array({array: array, value: value})) {
            return "bold_text"
        }
        return ""
    }
    handle_toggle({index, item}) {
        if (this._modal !== undefined) {
            this.state.modal.item = this.state.base_items?.[index]
            this.state.modal.index = index
            this.toggle_modal({})
        }
    }
    get_row_color(index) {
        if (index === this.view?.state?.hover) {
            return "hover_line"
        }
        return ""
    }
    hover_line(index) {
        if (this.state.hover !== index) {
            return this.setState({hover: index})
        }
        return this.setState({hover: undefined})
    }
    get_row_class({index, limit}) {
        if (index === 0) {
            return "top_line"
        }
        if (index === limit-1) {
            return "bottom_line"
        }
        return ""
    }
}
