// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"
import config_data from "./ContactDetails.json"
import Controller from "./ContactShare.modal.controller.jsx"
import "./ContactDetails.css"

export default class ContactShareModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.closeable    = true
        this.state.form         = config_data.form_objects
        this.modal_controller   = new Controller({params: { view: this, controller: this.controller }})
    }
    renderView() {
        return (
            <Col className="g-0 inner_modal_window cota_panel_border add_shadow modal_details" ref={ (divElement) => { this.divElement = divElement } } style={this._inner_modal_location}>
                {
                    this.render_closeable()
                }
                <Row>

                </Row>                
            </Col>
        )
    }
}


