// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from 'react-bootstrap'
import { RiDeleteBin7Fill } from "react-icons/ri";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./RequestDoc.button.controller.jsx"
import "./RequestDoc.button.css"
import Modal from "./RequestDoc.modal.jsx"

export default class RequestDoc extends _basePartial {
    constructor(props) {
        super(props)
        this._modal             = Modal
        this.state.use_modal    = true
        this.partial_controller = new Controller({params: {view: this, controller: this?.controller}})
        this.state.receipient   = {email: this.state.emails?.[0]?.value}
    }
    get css() {
        if(this.state?.css === undefined) { return "" }
        return this.state.css
    }
    render_button() {
        return (
            <Button className={this.css+" "+this._theme} onClick={() => this.partial_controller.wrap_request()}>
                {this.state?.title ?? "Re-Send"}
            </Button>
        )
    }
    render_icon() {
        return (
            <Row id={this.name}>
                <Col className="download_icon pointer">
                    <RiDeleteBin7Fill onClick={() => this.partial_controller.wrap_request()} size={20}/>
                </Col>
            </Row>
        )
    }
    renderView() {
        if(this.state?.button) {
            return this.render_button()
        }
        return this.render_icon()
    }
}
