//GENERAL REACT MODULES
import React from "react"
import {Button} from "react-bootstrap"
import { TbNotes, TbNotesOff } from "react-icons/tb";



//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import ContactDescriptionModal from "./ContactDescription.modal"

export default class ContactDescriptionButton extends _basePartial {
    constructor(props) {
        super(props)
        this.modal_controller   = this.controller
        this._modal = ContactDescriptionModal
    }

    render_icon() {
        if(this.parent?.contact?.description){
            return <TbNotes size="1rem"/>
        }
        return <TbNotesOff size="1rem"/>
    }

    renderView() {
        console.log('note modal', this)
        return (
            <Button id={this.name} variant="light"  size="sm" onClick={this.toggle_modal.bind(this)}>
                {this.render_icon()}
            </Button>
        )
    }
}