// GENERAL REACT MODULES
import React from "react";

// PAGE SPECIFIC MODULES
import _baseViewComponent from "components/General/_baseViewComponent.jsx"
import Controller from "./PanelMenu.controller.jsx";
import { MainConsumer } from "controllers/MainContext"
import "./PanelMenu.css"

export default class PanelMenu extends _baseViewComponent {
    constructor(props) {
        super(props)
        this.controller = new Controller({params: {view: this}})
    }
    renderView() {
        return (
            <MainConsumer>
                {(context) => {
                    this.context = context
                    return (
                        <div className={"panelMenuWrapper panel_menu_header_color "+this.context?.user?.preferences?.theme}>

                        </div>
                    )
                }
            }
            </MainConsumer>
        )
    }
}