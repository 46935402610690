// GENERAL REACT MODULES
import { Button } from 'react-bootstrap'

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import ContactModelController from "controllers/ModelControllers/ContactModelController.jsx"
import PersonModelController from "controllers/ModelControllers/PersonModelController.jsx"
import company_request from "assets/graphql/Companies/Company.graphql.json"
import mutation_request from "assets/graphql/Contacts/Contacts.mutation.graphql.json"
import update_request from "assets/graphql/Contacts/Contacts.update.graphql.json"
import state_request from "assets/graphql/Areas/States.graphql.json"
import contact_request from "assets/graphql/Contacts/Contacts.search.graphql.json"

export default class EditContactController extends _viewController {
    constructor(props) {
        super(props)
        this.people     = []
        this.employees  = []
        this.person     = new PersonModelController({params: {parent: this, key: "", index: 0, _param: "people",
                                                            controller: this,
                                                            is_view: true}})
        this.contact    = new ContactModelController({params: {parent: this, 
                                                        controller: this, 
                                                        is_view: true}})
        this.data = {}
        this.contact_type = undefined
        if (this.people.length === 0) {
            this.new_person()
        }
        this.requests   = [{callback: {f: this.state_results, p: {name: "areas", var: ["areas", "states"]}}, 
                                endpoint: "areas", replace: {o: "params:", r:'params: {country: "US"}'}}]
    }
    get_image(url) {
        if (url !== undefined && url !== null) {
            return (<img src={url} className="logo" alt="Cota Freight Logo"/>)
        }
        return (<div className="logo" ></div>)
    }
    get company() {
        return this.contact.company
    }
    state_results({caller, params, results}) {
        if (results?.errors === undefined && results?.data?.areas !== undefined) {
            results.data.areas.forEach((area, index) => {
                caller.view.state.states.push({ value: area.state, label: area.state })
            })
        }
    }
    async load_states() {
        let body    = JSON.parse(JSON.stringify(state_request))
        body.query = body.query.replace("params:", 'params: {country: "US"}')
        this.api.areas.ask({caller: this, params: {body: body}, callback: this.state_results})
    }
    load_harness() {
        this.load_contact(this.view?.props?.params?.panel_params)
    }
    load_cache() {
        let cache_0 = this.panel_controller.getCache("0")
        let cache_1 = this.panel_controller.getCache("1")
        if (cache_0 !== undefined) {
            this.setState({key:"panel_params", param: "data.id", value: cache_0.data?.contact?.id})
        }
        if (cache_1 !== undefined) {
            if (cache_1?.data?.type !== undefined) {
                this.contact_type = cache_1?.data?.type
            }
            if (cache_1.data?.contact?.id !== undefined) {
                this.setState({key:"panel_params", param: "data.id", value: cache_1.data?.contact?.id})
            }
            if (cache_1?.data?.is_iteratable !== undefined) {
                this.setState({key: "is_iteratable", value: cache_1?.data.is_iteratable})
            }
        }
    }
    async load_contact(params) {
        if (params?.data?.id?.length !== undefined) {
            this.view.state.form_disabled = true
            let id      = (params?.data?.id !== undefined) ? params?.data?.id : this.getState({key:"panel_params", param: "data.id"})
            if (id !== undefined) {
                let body    = JSON.parse(JSON.stringify(contact_request))
                body.query  = body.query.replace("contacts", 'contact(id: "'+id+'")')
                this.view.state.panel_params.data = undefined
                this.api.contacts.ask({caller: this, params: {body: body}, callback: {f: this.process_contact}})
            }
        }
    }
    process_roles(roles) {
        let formatted_roles = {
            payor: null,
            shipper: null,
            consignee: null,
            importer: null
        }
        roles?.forEach((role, index) => {
            if (role?.name !== undefined) {
                formatted_roles[role.name.toLowerCase()] = true
            }
        })
        return formatted_roles
    }
    process_contact({caller, params, results}) {
        if (results?.data?.contact?.notnull("id")) {
            caller.view.state.form_disabled = true
            if (results?.data?.contact?.notnull("people")) {
                caller.people       = [...results?.data?.contact?.people, ...caller.people]
            }
            caller.employees   = results?.data?.contact?.company?.employees
            if (results?.data.contact !== null && results?.data.contact !== undefined) {
                results.data.contact.roles   = caller.process_roles(results?.data?.contact?.roles)
            }
            if (results?.data?.contact?.address === null) {
                results.data.contact.address = {}
            }
            if (results?.data?.contact?.address?.location === null) {
                results.data.contact.address.location = {}
            }
            caller.view.state.data.company = results.data?.contact?.company
            caller.view.state.data.contact = results.data?.contact
            let name    = ((caller._notnull(caller.view.state.data?.contact?.name)) ? caller.view.state.data?.contact?.name : "")
            let company = ((caller._notnull(caller.view.state.data?.company?.id)) ? caller.view.state.data?.company : null)
            if (results?.data?.contact?.name !== undefined && results?.data?.contact?.name !== null) {
                company = {name: results.data.contact.name}
            }
            caller.setState({key: ["search",
                                    "search"],
                            param: ["company.search_value",
                                    "company.selected_value"],
                            value: [name,
                                    company]})
            let address = caller.contact?.address
            let location = caller.contact?.raw_location
            let zip = caller.contact?.zip
            caller.setState({key: ["search",
                                            "search",
                                            "search"],
                                        param: ["address.search_value",
                                                "zip.selected_value",
                                                "zip.search_value"],
                                        value: [address,
                                            location,
                                            zip]})
        }
    }
    process_company({caller, params, results}) {
        caller.setState({key: ["search",
                                "search",
                                "data"],
                            param: ["address.selected_value",
                                    "zip.selected_value",
                                    "company"],
                            value: [{address: results?.data?.company?.address?.address},
                                    {zip: results?.data?.company?.address?.location?.zip},
                                    results?.data?.company]})
    }
    new_object(type) {
        switch(type) {
            case "people":
                return new PersonModelController({params: {parent: this, 
                                                                controller: this, 
                                                                is_view: true}})
            default: 
                return undefined
        }
    }
    new_person() {
        this?.people?.push({"first_name": undefined, "last_name": undefined, "email": undefined, "phone": undefined})
        this?.view?.forceUpdate()
    }
    action_new_person(index) {
        if (this.person.load(index)._sum >= 4) {
            this.new_person()
        }
    }
    remove_person(index) {
        if (index > -1) {
            this.people.splice(index, 1)
        }
        this?.view?.forceUpdate()
    }
    getPersonButton({index, parent, disabled}) {
        disabled = (disabled) ? disabled : false
        let title = (parent.state.override_title === undefined) ? "people" : parent.state.override_title
        if (index === this[title]?.length-1) {
            return (
                <Button className="cota_light_blue_button" onClick={this.action_new_person.bind(this, parent?.state?.index)} >
                    Add
                </Button>
            )
        } else {
            return (
                <Button className="cota_light_blue_button" onClick={this.remove_person.bind(this, index)} disabled={disabled}>
                    Delete
                </Button>   
            )
        }
    }
    upload() {

    }
    browse(e) {

    }
    remove_file(index) {

    }
    build_people() {
        let summary_people = []
        this.people.forEach((person, index) => {
            this.person.index = index
            if (this.person.sum > 0) {
                summary_people.push(this.person.summary)
            }
        })
        return summary_people
    }
    async saveAction(page_key) {
        if (this?.state?.current_image) {
            this.state.parent.state.image = this?.state?.current_image
        }
        this.state.save = true
        this.setState({save: true})
        this.setState({_is_mutating: true})
        let body = undefined
        if (this.state?.data?.contact?.id === undefined) {
            body    = JSON.parse(JSON.stringify(mutation_request))
        } else {
            body    = JSON.parse(JSON.stringify(update_request))
        }
        let summary = this.controller.contact.summary
        delete summary?.contact?.image
        let summary_people = this.controller.build_people()
        if (summary_people.length > 0) {
            summary.contact.people = summary_people
        }
        let data = this.controller.toUnquotedJSON(summary)
        body.query = body.query.replace("input:", 'input: '+data)
        let sb_config = {success: {show: false}}

        this.api.contacts.create({caller: this, params: {body: body}, callback: {f: this.controller?.process_save, p: {page_key: page_key, sb: sb_config}}})
        // Leave below line for TSing: Comment above, and uncomment below for usage
        // this.panel_controller.selectPanel({panel_name: page_key, cache_data: {id: "1f724de9-6dba-41b7-bc3b-cca764bbbe96", type: this.controller?.contact_type}})
    }
    async process_save({caller, params, results}) {
        let id = (results?.data?.setContact?.id !== undefined) ? results?.data?.setContact?.id : results?.data?.updateContact?.id
        if (id !== undefined && id !== null) {
            if (caller?.controller?.uploader !== undefined) {
                await caller.controller.uploader?.process_queue(id)
            }
            caller.panel_controller.selectPanel({panel_name: params?.page_key, cache_data: {id: id, type: caller.controller?.contact_type }})
        }
        caller.setState({_is_mutating: false})
    }
    time_config(packet) {
        packet.type = "timepicker"
        packet.name = "time"
        return packet
    }
    packet(configs) {
        return {
            "placeholder": "",
            "subtype": "text",
            "title": configs.subtype,
            "public": {
                "title": configs.title
            }
        }
    }
    get_sub_type(name) {
        return {
            "title": name,
            "type": name,
            "default": "Company name",
        }
    }
    resolve_time(name) {
        return this.time_config(this.packet(this.get_sub_type(name)))
    }
    get _data(){
        return this.getState({key: "data"})
    }
    async follow_on_selection({event, obj, data}) {
        if (data?.name === "zip") { 
            this.contact.zip        = obj.zip
            this.contact.city       = obj.city
            this.contact.state      = obj.state
            this.contact.country    = obj.country
        }
        if (data?.name === "address") {
            this.contact.address    = obj?.address
            this.contact.address_2  = obj?.address_2
            this.contact.zip        = obj?.location?.zip
            this.contact.city       = obj?.location?.city
            this.contact.state      = obj?.location?.state
            this.contact.country    = obj?.location?.country
            this.setState({key: "search",
                            param: "zip.selected_value",
                            value: {zip: obj?.location?.zip} })
        }
        if (obj?.id !== undefined && data?.name === "company") {
            let body    = JSON.parse(JSON.stringify(company_request))
            body.query  = body.query.replace("company", 'company(id: "'+obj?.id+'")')
            this.api.companies.ask({caller: this, params: {body: body}, nocache: true, callback: {f: this.process_company}})
        }
    }
    async process_selection({caller, params, results}) {
        if (results?.data !== undefined) {
            let company_obj = results?.data?.company
            company_obj.id = params?.company_obj?.id
            if (results?.data?.company?.address.notnull) {
                caller.setState({key: ["search",
                                    "search"],
                                param: ["address.selected_value",
                                    "zip.selected_value"],
                                value: [{address: results?.data?.company?.address?.address},
                                            {zip: results?.data?.company?.address?.location?.zip}]})
            }
            caller.setState({key: "data", param: "company", value: company_obj})
        }
    }
}