import _baseModal from "components/General/_baseModal.jsx";
import { Row, Col, Button } from 'react-bootstrap'
import "./WebObject.css"
import ModalController from "./WebObject.modal.controller.jsx"
import DownloadPartial from "components/FileDownload/Download.partial";
import DeleteFile from "components/DeleteFile/DeleteFile.button.jsx"

export default class WebObjectModal extends _baseModal {
    constructor(props) {
        super(props)
        this.modal_controller   = new ModalController({params: { view: this, controller: this.controller }})
        this.state.closeable    = true
        this.state.share_mode   = false
    }
    render_download() {
        if(this.state?.item?.item === undefined) { return }
        return <DownloadPartial params={{parent: this, controller: this?.controller, is_private: true, file: this.state?.item?.item, 
                                            button: true, css: "primary_button_coloring "+this._theme}}/>
    }
    render_share() {
        if (this.state.share_mode) {
            return(
                <Row>
                    <Col className="generic_center">
                        <Button className={"primary_button_coloring "+this._theme}>
                            Email
                        </Button>
                    </Col>
                    <Col className="generic_center">
                        <Button className={"primary_button_coloring "+this._theme}>
                            User
                        </Button>
                    </Col>
                    <Col className="generic_center">
                        <Button className={"primary_button_coloring "+this._theme} onClick={this.modal_controller.toggle_share.bind(this)}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row>
                    <Col className="generic_center">
                        <Button className={"primary_button_coloring "+this._theme}>
                            Update
                        </Button>
                    </Col>
                    <Col className="generic_center">
                        <DeleteFile params={{button: true, file: this.state.item.item, controller: this.controller, parent: this}} />
                        {/* <Button className={"primary_button_coloring "+this._theme} onClick={this.modal_controller.delete_file.bind(this, undefined)}>
                            Delete
                        </Button> */}
                    </Col>
                    <Col className="generic_center">
                        <Button className={"primary_button_coloring "+this._theme} onClick={this.modal_controller.toggle_share.bind(this)}>
                            Share
                        </Button>
                    </Col>
                    <Col className="generic_center">
                        <Button className={"primary_button_coloring "+this._theme}>
                            View
                        </Button>
                    </Col>
                    <Col className="generic_center">
                        <DownloadPartial params={{button: true, file: this.state.item.item, controller: this.controller, parent: this}} />
                        {/* <Button className={"primary_button_coloring "+this._theme} onClick={this.modal_controller.download_file.bind(this)}>
                            Download
                        </Button> */}
                    </Col>
                </Row>
            )
        }
    }
    renderView() {
        this.state.item = this.state.parent?.state?.modal
        return(
            <Col className="g-0 inner_modal_window cota_panel_border add_shadow modal_details" ref={ (divElement) => { this.divElement = divElement } } style={this._inner_modal_location}>
                {
                    this.render_closeable()
                }
                <Row className="line">
                    <Col sm={2} className="modal_title">
                        Name
                    </Col>
                    <Col className="generic_center">
                        {this.state.item?.item?.name}
                    </Col>
                </Row>
                <Row className="line">
                    <Col sm={2} className="modal_title">
                        Document Type
                    </Col>
                    <Col className="generic_center">
                        {this.state.item?.item?.document_type?.label}
                    </Col>
                </Row>
                <Row>
                    <Col>  
                        &nbsp;
                    </Col>
                </Row>
                {this.render_share()}
            </Col>
        )
    }
}
