// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
// import EquipmentModelController from "controllers/ModelControllers/Shipping/EquipmentModelController.jsx"

export default class EquipmentModalController extends _viewController {
    constructor(props) {
        super(props)
        this.isMounted      = true
        this.requests       = [{callback: {p: {name: "special_requirements", var: "special_requirements"}},
                                    endpoint: "requirements", req_name: "special"},
                                {callback: {p: {name: "trailer_types", var: "trailer_types"}}, 
                                    endpoint: "trailers"}]
    }
    get equipment() {
	    return this.view.equipment
    }
    add_object() {
        this.state.parent.toggle_modal({toggle_state: false})
    }
    filter_array(array1, array2) {
        let filtered = []
        if (array1 !== undefined && array2 !== undefined) {
                filtered = array1.filter(el => {
                    return array2.indexOf(el.value) !== -1;
                });
        }
        return filtered;
    };
}
