// GENERAL REACT MODULES
import React from "react";
import { Button, Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import "./FourthPanel.css"

// ALL OTHER MODULES
import MultiPanel from "components/MultiPanels/MultiPanel";
import { MainConsumer } from "controllers/MainContext.jsx"
import MainContext from "controllers/MainContext.jsx";
import Controller from "./FourthPanel.controller.jsx";
import "styles/generic.css"
import config_data from "../../Home.json"
import { countriesOptions } from "assets/countryCodes.js"

export default class FourthPanel extends MultiPanel {
    constructor(props) {
        super(props)
        this.state.form = config_data.form_objects
        this.controller = new Controller({params: {view: this}})
	    this.state.states	= []
        this.state.data  = {
            company: {
                address: {
                    address: null
                }
            }
        }
    }
    renderInnerFrame() {
	    console.log(this)
        return(
            <MainConsumer>
                {(props) => {
                    return(
                        <Row className="generic_panel_object generic_100 g-0" id={this.name}>
                            <Col>
                                <Row className="max_width_row">
                                    <Col sm={1} className="logo_col">
                                        <Row>
                                            <div className="new_contact_logo" />
                                            <div className="new_contact_logo_button">
                                                Click to upload logo
                                            </div>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row className="company_title_question company_details_row_padding">
                                            <Col>
                                                Company Name
                                            </Col>
                                        </Row>
                                        <Row className="company_details_row_padding">
                                            <Col>
                                                {this.formObject({data: this.state.form.company_name, 
                                                                    options: undefined, 
                                                                    owner: this})}
                                            </Col>
                                        </Row>
                                        <Row className="company_details_row_padding">
                                            <Col>
                                                Billing Address 1
                                            </Col>
                                        </Row>
                                        <Row className="company_details_row_padding">
                                            <Col>
                                                {this.formObject({data: this.state.form.address, 
                                                                    options: undefined, 
                                                                    owner: this})}
                                            </Col>
                                        </Row>
                                        <Row className="company_details_row_padding">
                                            <Col>
                                                Billing Address 2
                                            </Col>
                                        </Row>
                                        <Row className="company_details_row_padding">
                                            <Col>
                                                {this.formObject({data: this.state.form.address_2, 
                                                                    options: undefined, 
                                                                    owner: this})}
                                            </Col>
                                        </Row>
                                        <Row className="company_details_row_padding">
                                            <Col>
                                                Billing City
                                            </Col>
                                        </Row>
                                        <Row className="company_details_row_padding">
                                            <Col>
                                                {this.formObject({data: this.state.form.city, owner: this})}
                                            </Col>
                                        </Row>
                                        <Row className="company_details_row_padding">
                                            <Col>
                                                Phone Number
                                            </Col>
                                        </Row>
                                        <Row className="company_details_row_padding">
                                            <Col>
                                                {this.formObject({data: this.state.form.phone, owner: this})}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Row>
                                                <Col>
                                                    <Row className="company_details_row_padding">
                                                        <Col>
                                                            State
                                                        </Col>
                                                    </Row>
                                                    <Row className="company_details_row_padding">
                                                        <Col>
                                                            {this.formObject({data: this.state.form.state, options: this.state.states, owner: this})}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <Row className="company_details_row_padding">
                                                        <Col>
                                                            Zip
                                                        </Col>
                                                    </Row>
                                                    <Row className="company_details_row_padding">
                                                        <Col>
                                                            {this.formObject({data: this.state.form.zip, owner: this})}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <Row className="company_details_row_padding">
                                                        <Col>
                                                            Country
                                                        </Col>
                                                    </Row>
                                                    <Row className="company_details_row_padding">
                                                        <Col>
                                                            {this.formObject({data: this.state.form.country, 
                                                                                    owner: this,
                                                                                    options: countriesOptions})}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Row className="company_details_row_padding">
                                                        <Col>
                                                            Email Address
                                                        </Col>
                                                    </Row>
                                                    <Row className="company_details_row_padding">
                                                        <Col>
                                                            {this.formObject({data: this.state.form.email, owner: this})}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <Row className="company_details_row_padding">
                                                        <Col>
                                                            Fax Number
                                                        </Col>
                                                    </Row>
                                                    <Row className="company_details_row_padding">
                                                        <Col>
                                                            {this.formObject({data: this.state.form.fax, owner: this})}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="cd_bottom_row">
                                    <hr />
                                </Row>
                                <Row>
                                    <Col>&nbsp;</Col>
                                    <Col className="generic_center">
                                        <Button className={"generic_center save_button "+this._theme} onClick={this.controller?.cancel_action.bind(this)}>
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col className="generic_center">
                                        <Button className={"generic_center save_button "+this._theme} onClick={this.controller?.submit_action.bind(this)}>
                                            Save
                                        </Button>
                                    </Col>
                                    <Col>&nbsp;</Col>
                                </Row>
                            </Col>
                        </Row>
                    )
                }
            }
            </MainConsumer>
        )
    }
}
FourthPanel.contextType = MainContext
