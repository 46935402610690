// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import "../ShippingPartials.css"
import "./Carrier.css"

export default class Carrier extends _basePartial {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    get carrier() {
        return this.controller?.carrier_model
    }
    renderView() {
        if (this.carrier?._id !== undefined && this.carrier?._id !== null) {
            return (
                <Row className="row_padding" id={this.name}>
                    <Col className="cota_panel_object_unsized cota_panel_border max_block">
                        <Row>
                            <Col>
                                <Row className="panel_title">
                                    <Col>
                                        Carrier
                                    </Col>
                                </Row>
                                <Row className="row_text">
                                    <Col>
                                        {this.carrier?.name}
                                    </Col>
                                </Row>
                                <Row className="row_text">
                                    <Col>
                                        {this.carrier?.address}
                                    </Col>
                                </Row>
                                <Row className="row_text">
                                    <Col>
                                        {this.carrier?.city} {this.carrier?.state} {this.carrier?.zip}
                                    </Col>
                                </Row>
                                <Row className="row_text">
                                    <Col>
                                        {this.carrier?.email}
                                    </Col>
                                </Row>
                                <Row className="row_text">
                                    <Col>
                                        Phone :
                                    </Col>
                                    <Col>
                                        {this.carrier?.phone}
                                    </Col>
                                </Row>
                                <Row className="row_text">
                                    <Col>
                                        DOT #
                                    </Col>
                                    <Col>
                                        {this.carrier?.company?.dot_number}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )
        }
    }
}
