// GENERAL REACT MODULES
import React from "react";

// PAGE SPECIFIC MODULES
import Controller from "./ShipmentsPanelView.controller.jsx";
import "./ShipmentsPanelView.css"

// ALL OTHER MODULES
import _baseViewComponent from "components/General/_baseViewComponent.jsx"
import grafana_config from "assets/grafana_config.json"

export default class ShipmentsPanelView extends _baseViewComponent {
    constructor(props) {
        super(props)
        this.state.panel_configs = grafana_config["pages"]["home"]
        this.partial_controller = new Controller({params: {view: this}})
    }
    renderView() {
	    console.log(this)
        return (
            <div className="mapouter">
                <div className="gmap_canvas">
                    {
                        this.partial_controller.renderIframeView()
                    }
                </div>
            </div>
        )
    }
}
