
// GENERAL REACT MODULES
import React from "react";
import { Row, Col } from 'react-bootstrap'

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import form_configs from "./PeoplePartial.json"
import "./PeoplePartial.css"
import "../EditCarriers.css"

export default class PeoplePartial extends _basePartial {
    constructor(props) {
        super(props)
        this.state.form = JSON.parse(JSON.stringify(form_configs.form_objects))
    }
    renderView() {
        if (this.state.override_title !== undefined) {
            Object.keys(this.state.form).forEach((k, i) => {
                this.state.form[k].title = this.state.override_title
            })
        }
        // Refactor to match Contacts
        // if (this.state?.person?.user_id !== undefined || this.props?.params?.person?.user_id !== undefined) {
        //     this.state.form_disabled = true
        // }
        return(
            <Row id={this.name}>
                <Col className="g-0">
                    <Row>
                        <Col className="remove_right_padding">
                            <Row>
                                <Col>
                                    First Name
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.state.parent.formObject({data: this.state.form.first_name, index: this.state.index, owner: this, disabled: this.state.form_disabled})}
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    Last Name
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.state.parent.formObject({data: this.state.form.last_name, index: this.state.index, owner: this, disabled: this.state.form_disabled})}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col className="remove_right_padding">
                    <Row>
                        <Col className="remove_right_padding">
                            <Row>
                                <Col>
                                    Email
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.state.parent.formObject({data: this.state.form.email, index: this.state.index, owner: this, disabled: this.state.form_disabled})}
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    Phone
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.state.parent.formObject({data: this.state.form.phone, index: this.state.index, owner: this, disabled: this.state.form_disabled})}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm={3}>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    Role
                                </Col>
                            </Row>
                            <Row>
                                <Col className="black_text">
                                    {this.state.parent.formObject({data: this.state.form.roles, owner: this, index: this.state.index, disabled: this.state.form_disabled , 
                                                                    options: this.controller.getState({key: "roles"})})}
                                </Col>
                            </Row>
                        </Col>
                        <Col className="small_col">
                            <Row>
                                <Col>
                                    &nbsp;
                                </Col>
                            </Row>
                            <Row>
                                <Col className="center_button">
                                    {
                                        this.controller?.getPersonButton({index: this.state.index, parent: this, disabled: this.state.form_disabled})
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}
