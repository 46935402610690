// GENERAL REACT MODULES
import React from "react";
import { Container, Row, Col } from 'react-bootstrap'

// PAGE SPECIFIC MODULES
import "./SinglePage.css"

// ALL OTHER MODULES
import "styles/generic.css"
import MultiPanel from "components/MultiPanels/MultiPanel";
import Controller from './SinglePage.controller.jsx';


export default class SinglePage extends MultiPanel {
    constructor(props) {
        super(props)
        this.controller = new Controller({params: {view: this }})
    }
    renderInnerFrame() {
        console.log(this)
        return (
            <Container className={"container_wrapper "+ this.offsetWrapper()}>
                <Row>
                    <Col>
                        <Col>
                        
                        </Col>
                        <Col>
                        
                        </Col>
                    </Col>
                    <Col>
                        <Col>

                        </Col>
                        <Col>
                        
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    
                    </Col>
                    <Col>
                    
                    </Col>
                </Row>
            </Container>
        )
    }
}

