
// GENERAL REACT MODULES
import React from "react";
import { Row, Col } from 'react-bootstrap'

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import configs from "./Stats.json"
import "./Stats.css"

export default class StatsListPartial extends _basePartial {
    constructor(props) {
        super(props)
        this.state.form = configs.form_objects
    }
    renderView() {
        return(
            <Row id={this.name} className="add_shadow">
                <Col sm={4}>
                    <Row>
                        <Col>
                            Time Frame
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.state.parent.formObject({data: this.state.form.time_frame, options: [], owner: this})}
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row className="inner_panel">
                        <Col>
                            <Row>
                                <Col>
                                    &nbsp;
                                </Col>
                                <Col className="small_text">
                                    Average
                                </Col>
                            </Row>
                            <Row className="bold_text">
                                <Col>
                                    Quotes
                                </Col>
                                <Col>
                                    $0.00
                                </Col>
                            </Row>
                            <Row className="bold_text">
                                <Col>
                                    Shipments
                                </Col>
                                <Col>
                                    $0.00
                                </Col>
                            </Row>
                            <Row className="bold_text">
                                <Col>
                                    Cota Load
                                </Col>
                                <Col>
                                    
                                </Col>
                            </Row>
                            <Row className="bold_text green_text">
                                <Col>
                                    lbs CO2
                                </Col>
                                <Col>
                                    
                                </Col>
                            </Row>
                            <Row className="bold_text green_text">
                                <Col>
                                    lbs CO2 saved
                                </Col>
                                <Col>
                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className="small_text">Revenue:</span> $0
                                </Col>
                                <Col>
                                    <span className="small_text">Profit:</span> $0.00
                                </Col>
                                <Col>
                                    <span className="small_text">Margin</span> 0%
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}
