// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";
import { FaPen, FaRegWindowClose, FaPlus, FaCheck } from "react-icons/fa";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import "./Shipper.css"
import config_data from "./Shipper.json"
import Controller from "./ShipperPartial.controller.jsx"
import Modal from "./Shipper.modal.jsx";
import ShipperButtons from "./Partial/ShipperButtons.partial.jsx"
import ContactDescriptionButton from "components/NewLoadPartials/ShipperObject/ContactDescriptionModal/ContactDescriptionButton.partial.jsx"

export default class ShipperObject extends _basePartial {
    constructor(props) {
        super(props)
        this.state.form         = config_data.form_objects
        this.configs            = config_data.configs
        this.state.seperated    = true
        this._draggable_modal   = false
        this.partial_controller = new Controller({params: {view: this, controller: this.controller}})
        this._modal             = Modal
    }
    follow_on_selection({event, obj, data}) {
        this.controller.setState({key: data?.title, param: data?.name, value: {search_value: ""}})
        this.api?.contacts?.gid({caller: this, value: obj?.id, callback: {f: this.process_loaded_contact, p: {name: data?.name}}})
    }
    process_loaded_contact({caller, params, results}) {
        if(params?.name === "consignee") {
            return caller.controller.process_loaded_contact({caller: caller.controller, params, results})
        }
        let data = {
                stop_type: "STOP",
                contact: JSON.parse(JSON.stringify(results?.data?.contact))
        }
        let stop_num = (caller?.controller?.view?.state?.data?.stops.length - 1 >= 0) ? caller?.controller?.view?.state?.data?.stops?.length - 1 : 0
        let stop_check = caller?.controller?.view?.state?.data?.stops?.[stop_num]
        stop_check = (stop_check === null || stop_check === undefined) ? {} : stop_check
        if (Object.keys(stop_check).length > 0) {
            caller.controller.view.state.data.stops.push(data)
        } else {
            caller.controller.view.state.data.stops[stop_num] = data
        }
        caller.controller.view.forceUpdate()
        caller.state.parent?.column?.forceUpdate()
    }
    get details() {
        return this.partial_controller.details
    }
    get contact() {
        return this.details?.contact
    }
    get company() {
        return this.contact?.company
    }
    get role_css() {
        if(this.state?.type === "stops") {
            return (this.details?.type.toLowerCase() === "pickup") ? "pickup" : this.details?.type.toLowerCase()
        }
        return this.state?.type
    }
    get role() {
        if(this.state?.type === "stops") {
            return (this.details?.type.toLowerCase() === "consignee") ? "consignee" : this.details?.type.toLowerCase()
        }
        return this.state?.type
    }
    get type() {
        return this.state?.type
    }
    get index() {
        return this.props?.params?.index
    }
    followDidMount() {
        this.partial_controller?.load_cache()
    }
    selectRender() {
        let display = true
        let obj = this.controller.getState({key: "data", param: this.state.visible})
        // let obj = this.p
        if (this.state.visible !== undefined) {
            if (obj?.id === undefined) { display = false }
        }
        if (!display) { return (<></>) }
        if (this.contact?.id !== undefined) {
            return this.renderDetails(this.details)
        }
        return this.renderInput()
    }
    renderInput() {
        return (
            <Row className="shipper_panel g-0" id={this.name} ref={ (divElement) => { this.divElement = divElement } }>
                <Col className="cota_panel_object_unsized cota_panel_border adj_padding">
                    <Row className={"font_size_25 "}>
                        <Col className={(this.state.type === "stop") ? "large_title" : ""}>
                            {(this.state.type === "stop") ? ("Add " + this.configs?.[this.state.type]?.title +"s ?") : this.configs?.[this.state.type]?.title}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="g-0">
                            {this.controller?.view.formObject({data: this.state.form[this.state.type], 
                                                                options: undefined, 
                                                                owner: this})}
                        </Col>
                        <Col className="create_contact_col pointer">
                            <center>
                               <FaPlus color={"#0adf1c"} size={20} onClick={this.partial_controller?.editContact.bind(this)} />
                            </center >
                        </Col>
                    </Row>
                </Col>
                {/* <Col sm={1} className="sub_subpanel center_text">
                    {this.configs?.[this.state.type]?.title} 
                </Col> */}
            </Row>
        )
    }
    render_contact(contact) {
        if (contact?.id === undefined || contact?.id === null) {
            return this.renderContactInput()
        }
        return (
            <>
            <Row>
                <Col className="generic_label_md pad_top_point5rem">
                    Contact:
                </Col>
            </Row>
            <Row className="generic_text_md">
                <Col xs="auto">
                    {contact?.first_name}
                </Col>
                <Col xs="auto">
                    {contact?.last_name}
                </Col>
                <Col xs={3}>
                    {contact?.email}
                </Col>
                <Col xs={2}>
                    {contact?.phone}
                </Col>
                <Col></Col>
                <Col xs={1}>
                    <Row>
                        <Col className="g-0">
                            &nbsp;
                        </Col>
                        <Col className="g-0">
                            <FaRegWindowClose size={20} onClick={this.partial_controller.reset_rep.bind(this)} className="pointer"/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            </>
        )
    }
    renderContactInput() {
        return (
            <Row className="pad_top_point5rem">
                <Col>
                    <ContactDescriptionButton params = {{parent: this, controller: this.controller}}/>
                </Col>
                <Col>
                    <Button className={"toggle_button_style "+this.controller.getTheme()} onClick={this.toggle_modal?.bind(this, {toggle_state: true})}>
                        Add Represenative?
                    </Button>
                </Col>
                <Col></Col>
            </Row>
        )
    }
    renderContactDetails(data) {
        return (
            <>
                <Row className="g-0">
                    <Col>
                        {data?.first_name} {data?.last_name}
                    </Col>
                    <Col>
                        {data?.email} 
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {data?.phone} 
                    </Col>
                    <Col>
                    </Col>
                </Row>
            </>
        )
    }
    processDate({date, word}) {
        if (date !== undefined) {
            var splitStr = (""+date+"").split(word);
            return splitStr[0].substring(4)
        }
        return ""
    }
    renderDetails(data) {
        return (
            <Row className="subpanel g-0 partial_text" id="ShipperObject" ref={ (divElement) => { this.divElement = divElement } }>
                <Col className="cota_panel_object_unsized max_height cota_panel_border relative bg remove_padding" >
                    <Row className="g-0">
                        <Col className="g-0">
                            <Row className="g-0">
                                <Col className="g-0">
                                    &nbsp;
                                </Col>
                                <Col sm={2} className={"g-0 uc_text tag " +this.role_css}>
                                    {this.role}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="g-0">
                        <Col className="u_padding">
                            <Row>
                                <Col>
                                    <Row className="g-0" >
                                        <Col>
                                            {/* <Row className=" label g-0" >
                                                <Col>
                                                    {this.configs?.[this.state.type]?.title} 
                                                </Col>
                                            </Row> */}
                                            <Row className="generic_text_md" >
                                                <Col>
                                                    {this.contact?.name}
                                                </Col>
                                            </Row>
                                            <Row className="generic_text_md" >
                                                <Col>
                                                    {this?.contact?.address}
                                                </Col>
                                            </Row>
                                            <Row className="generic_text_md" >
                                                <Col>
                                                    {this?.contact?.address_2}
                                                </Col>
                                            </Row>
                                            <Row className="generic_text_md" >
                                                <Col>
                                                    {this.contact?.full_location}
                                                </Col>
                                            </Row>
                                            <Row className="generic_text_md" >
                                                <Col>
                                                    {this?.contact?.phone}
                                                </Col>
                                            </Row>
                                            <Row className="generic_text_md" >
                                                <Col>
                                                    {this?.contact?.email}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    {this?.contact?.hours.open 
                                    ?   <Row >
                                            <Col className="generic_label_md" xs="auto">
                                                Business Hours:
                                            </Col>
                                            <Col className="generic_text_md" xs="auto">
                                                {this?.contact?.hours.open}
                                            </Col>
                                            <Col className="generic_text_md" xs="auto">
                                                -
                                            </Col>
                                            <Col className="generic_text_md" xs="auto">
                                                {this?.contact?.hours.close}
                                            </Col>
                                        </Row>
                                    : null }
                                        <Row>
                                            <Col className="generic_label_md">
                                                Appointment Required: {((this.details?.appointment_required) ? "Yes" : "No")} 
                                            </Col>
                                        </Row>
                                        {
                                        this.details?.date ?
                                        <Row>
                                            <Col className="generic_label_md" xs="auto">
                                                Date:
                                            </Col>
                                            <Col className="generic_text_md" xs="auto">
                                                {this.details?.formattedDate}
                                            </Col>
                                            <Col></Col>
                                        </Row> 
                                        : null
                                        }
                                        {
                                        this.details?.time || this.details?.end_time ?
                                        <Row>
                                            <Col className="generic_label_md" xs="auto">
                                                Time:
                                            </Col>
                                            <Col className="generic_text_md" xs="auto">
                                                {this.details?.time}
                                            </Col>
                                                {this.details?.end_time ? <Col xs="auto">-</Col> : null}
                                            <Col className="generic_text_md" xs="auto">
                                                {this.details?.end_time}
                                            </Col>
                                            <Col></Col>
                                        </Row> 
                                        : null
                                        }
                                        {this.details?.number &&
                                        <Row>
                                            <Col className="generic_label_md" xs="auto">
                                                {this.configs?.[this.state.type]?.title} # 
                                            </Col>
                                            <Col className="generic_text_md" xs="auto">
                                                {this.details?.number}
                                            </Col>
                                        </Row>
                                        }
                                        {/* <Row className="g-0" >
                                            {this.configs?.[this.state.type]?.title} # {this.details?.number}
                                        </Row> */}
                                </Col>
                                <Col sm={1} className="g-0 btn_col_wrapper">
                                    <ShipperButtons params={{parent: this, controller: this.controller, partial_controller: this.partial_controller}}/>
                                </Col>
                            </Row>
                            {
                                this.render_contact(this.details?.rep)
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
    load_stop_cache() {
        if(this.partial_controller.role_object !== undefined && Object.keys(this.partial_controller.role_object).length) {
            if(this.state.data.stop === undefined) {
                this.state.data.stop = JSON.parse(JSON.stringify(this.partial_controller.role_object))
            }
        }
    }
    renderView() {
        this.load_stop_cache()
        this.partial_controller.load_details()
        this.state.visible = this.props.params?.visible
        this.state.type = this.props.params?.type
        if (this.details !== undefined) {
            this.details._param = this.state?.type
        }
        this.state._completed = this.details?.contact?._any
        return (
            <>
                {
                    this.selectRender()
                }
            </>
        )
    }
}
