// GENERAL REACT MODULES
import { Container, Row, Col } from 'react-bootstrap'

// PAGE SPECIFIC MODULES
import "./EditContact.css"
import "styles/generic.css"
import MultiPanel from "components/MultiPanels/MultiPanel";
import configs from "./EditContact.json"
import Controller from './EditContact.controller.jsx';
import EditPerson from './EditPerson.partial.jsx';
import contact from "controllers/ApiController/Endpoints/Contacts/schema.json"
import { countriesOptions } from "assets/countryCodes.js"
import UploadPartial from "components/FileUpload/Upload.partial.jsx";
import SaveButton from "components/Loading/SaveButton/SaveButton.jsx"

export default class EditContact extends MultiPanel {
    constructor(props) {
        super(props)
        this.state.form = configs.form_objects
        this.controller = new Controller({params: {view: this }})
        this.base_obj   = JSON.parse(JSON.stringify(contact))
        this.state.data = JSON.parse(JSON.stringify(contact))
        this.state.form_disabled    = false
        this.state.states           = []
        this.state.people           = []
        this.state.employees        = []
    }
    handleClick = () => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.onChange = (event) => {
            this.props.onFileChange(event.target.files[0]);
        }
        fileInput.click();
    }
    followUnDidMount() {
        this.state.data = JSON.parse(JSON.stringify(contact))
    }
    renderInnerFrame() {
        return (<>
            <Container className={this.offsetWrapper()} id={this.name}>
                <Row className="max_width_row">
                    <Col sm={1}  >
                        <Row className="logo_col">
                            {console.log("EDIT IMAGE",this)}
                            <Col className="">
                                {/* {this.controller.get_image(this.state?.data?.contact?.image?.[0]?.url)} */}
                                <UploadPartial params={{
                                    parent: this.view, 
                                    controller: this.controller, 
                                    endpoint: "contact", 
                                    single_link: true, 
                                    // css_rules: "icon_row icon_col", 
                                    // image_css:"SettingsUserImage", 
                                    id:this.state?.data?.contact?.id, 
                                    existing_image: this.state?.data?.contact?.image?.[0],
                                    new_image: this.state?.current_image,
                                    view: this,
                                    save: this.state.save,
                                    uploaded: this.state.uploaded,
                                    disabled : false
                                    }} />
                            </Col>
                        </Row>
                  
                     
                    </Col>
                    <Col>
                        <Row>
                            <Col className='new_contact_title'>
                                Contact
                            </Col>
                            <Col>
                                {this.formObject({data: this.state.form.payor, owner: this})}
                            </Col>
                            <Col>
                                {this.formObject({data: this.state.form.shipper, owner: this})}
                            </Col>
                            <Col>
                                {this.formObject({data: this.state.form.consignee, owner: this})}
                            </Col>
                            <Col>
                                {this.formObject({data: this.state.form.importer, owner: this})}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.formObject({data: this.state.form.company, owner: this})}
                            </Col>
                            <Col>
                            
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        Address
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({data: this.state.form.address, owner: this})}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        &nbsp;
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({data: this.state.form.same_billing, owner: this})}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        Phone Number
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({data: this.state.form.phone, owner: this})}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        Address 2
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({data: this.state.form.address_2, owner: this})}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        &nbsp;
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        &nbsp;
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        Fax Number
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({data: this.state.form.fax, owner: this})}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        City
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({data: this.state.form.city, owner: this})}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        State
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({data: this.state.form.state, owner: this, options: this.state.states})}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        Zip
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({data: this.state.form.zip, owner: this})}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        Country
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({data: this.state.form.country, 
                                                            owner: this,
                                                            options: countriesOptions})}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        Email
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({data: this.state.form.email, owner: this})}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='pad_bottom'>
                            <Col>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                Hours of Operation
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.formObject({data: this.state.form.hours_open, owner: this})}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={1}>
                                        <Row>
                                            <Col>
                                                &nbsp;
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='row_dash'>
                                                -
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                &nbsp;
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.formObject({data: this.state.form.hours_close, owner: this})}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={3}>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                &nbsp;
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.formObject({data: this.state.form.require_app, owner: this})}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={2}>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                &nbsp;
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.formObject({data: this.state.form.liftgate, owner: this})}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        Contact Notes
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({data: this.state.form.description, owner: this})}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='pt-3'>
                            <Col className="cota_panel_object_unsized people_panel">
                                <Row className="people_panel_title">
                                    <Col>
                                        People
                                    </Col>
                                </Row>
                                {
                                    this.controller.people?.map((person, index) => {
                                        return <EditPerson params={{parent: this, person: person, controller: this.controller, index: index}} key={"person_"+index}/>
                                    })
                                }
                                {
                                    this.controller.employees?.map((person, index) => {
                                        return <EditPerson params={{parent: this, person: person, controller: this.controller, index: index, override_title: "employees"}} key={"employee_"+index}/>
                                    })
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <UploadPartial params={{parent: this.view, controller: this.controller, endpoint: "contact", title_row: false}} />
                            </Col>
                            <Col sm={1}>
                                &nbsp;
                            </Col>
                            <Col sm={1} className="center_text save_button">
                                <SaveButton className={"primary_button_coloring "+this._theme} onClick={this?.controller?.saveAction.bind(this, "save_action")}
	                                            params={{parent: this, controller: this.controller}}>
                                    Save
                                </SaveButton>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>)
    }
}
