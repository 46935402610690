// GENERAL REACT MODULES
import { Form } from 'react-bootstrap'

// OBJECT SPECIFIC MODULES
import "./ToggleSwitch.css"
import Controller from './ToggleSwitch.controller';

// ALL OTHER MODULES
import _baseComponent from "components/General/_baseComponent";

export default class ToggleSwitch extends _baseComponent {
    constructor(props) {
        super(props)
        this.controller = new Controller({params: {view: this}})
    }
    render() {
        return (
            <Form className="center_switch">
                <Form.Check type="switch"
                            id={this.state.name}
                            label={this.state.label.right}
                            onChange={this.controller?.toggle_switch.bind(this, this.state.name)}
                            value={this.state.name}
                            className="float_right toggle_switch"/>
                <span className="float_right">
                    {this.state.label.left}
                </span>
            </Form>
        )
    }
}