// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./DispatchedPanel.controller.jsx"
import "./DispatchedPanel.css"

export default class DispatchedPanel extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this, controller: this.controller}})
    }
    renderView() {
        if (this.controller.load.linehaul !== undefined && this.controller.load.linehaul !== null) {
            return (
                <>
                    <Row id={this.name} className="row_padding">
                        <Col className="cota_panel_object_unsized cota_panel_border max_block dispatch_panel">
                            <Row className="dispatch_title">
                                <Col>
                                    Rate to Carrier
                                </Col>
                            </Row>
                            <Row className="generic_center">
                                <Col>
                                    {this.controller.load?.carrier?.name}
                                </Col>
                            </Row>
                            <Row className="generic_center title">
                                <Col>
                                    Linehaul
                                </Col>
                                <Col>
                                    Rate/Mile
                                </Col>
                            </Row>
                            <Row className="generic_center">
                                <Col>
                                    ${this.controller.load?.linehaul}
                                </Col>
                                <Col>
                                    ${this.controller.load?.linehaul_rate_mile}
                                </Col>
                            </Row>
                            {this.render_profits()}
                        </Col>
                    </Row>
                </>
            )
        }
        return (<></>)
    }
    render_profits() {
        if (this.controller.check_role("COTA_SUPER_USER")) {
            return (
                <>
                    <Row className="generic_center title small_text">
                        <Col>
                            Profits
                        </Col>
                        <Col>
                            Margin
                        </Col>
                    </Row>
                    <Row className="generic_center small_text">
                        <Col>
                            ${this.controller.load?.profit}
                        </Col>
                        <Col>
                            {this.controller.load?.margin}%
                        </Col>
                    </Row>
                </>
            )
        }
        return (<></>)
    }
}
