// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import NoteModelController from "controllers/ModelControllers/Shipping/NoteModelController.jsx"

export default class NotesModalController extends _viewController {
    constructor(props) {
        super(props)
        this._configs = {
            local: "note",
            array: "notes"
        }
        this.note = this.new_object()
    }
    new_object() {
        return new NoteModelController({params: {parent: this, 
                                                    controller: this, 
                                                    is_view: true}})
    }
    clear_object() {
        console.log("clearobject", this)
        if (this.view?.state?.data?.note !== undefined) {
            // let _note = {note_text: undefined}
            // this.note._sync({data: _note})
            this.view.state.data.note = {}
            this.view.setState(prevState => ({
                index: prevState.index +1
            }))
        }
    }
    add_object() {
        let data_copy = this.modal_controller?.[this.modal_controller?._configs?.local].data.copy()
        if (this.controller?.load?.notes === undefined) {
            this.controller.load.notes = []
        }
        this.controller?.load?.notes.push(data_copy)
        // this.controller?.[this.modal_controller?._configs?.array]?.push(data_copy)
        this.modal_controller.clear_object()
        this.controller?.view?.forceUpdate()
    }
    get notes() {
        return this.view.controller?.[this.modal_controller?._configs?.array]
    }
}
