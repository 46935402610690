import React, { useState } from "react";
import "./Tooltip.css";

const Tooltip = (props) => {
    let timeout;
    const [active, setActive] = useState(false);

    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true);
        }, props.delay || 400);
    };

    const hideTip = () => {
        clearInterval(timeout);
        setActive(false);
    };
    const fadeTip = () => {
        setActive(true);
        timeout = setTimeout(() => {
            setActive(false);
        },  1000);
    };

    const show_internal = (active, props) => {
        return (
            <>
                {props.children}
                {active && (
                    <div className={`Tooltip-Tip ${props.direction || "top"}`}>
                        {/* Content */}
                        {props.content}
                    </div>
                )}
            </>
        )
    }
    if (props.clickable) {
        return (
            <div className="Tooltip-Wrapper" onClick={fadeTip} onMouseLeave={hideTip}>
                {/* Wrapping */}
                {show_internal(active, props)}
            </div>
        );
    } else {
        return (
            <div className="Tooltip-Wrapper" onMouseEnter={showTip} onMouseLeave={hideTip} >
                {/* Wrapping */}
                {show_internal(active, props)}
            </div>
        );
    }
};

export default Tooltip;