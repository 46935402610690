// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import LineItemModelController from "controllers/ModelControllers/Quotes/LineItemModelController"

export default class QuoteLineController extends _viewController {
    constructor(props) {
        super(props)
        this.line_item  = new LineItemModelController({params: {parent: this, 
                                                                        controller: this,
                                                                        is_view: true, _param: "line_items"}})
    }
    add_item() {
        this.view.controller.add_line_item()
    }
}
