// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";
import { FaPen, FaRegWindowClose, FaPlus } from "react-icons/fa";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import "./EditAppointment.css"
import Modal from "./EditAppointment.modal.jsx";

export default class ShipperButtons extends _basePartial {
    constructor(props) {
        super(props)
        this._modal = Modal
        this.partial_controller = props.params.partial_controller
    }
    get type() {
        return this.state.parent.state.type
    }
    renderView() {
        this.state.type = this.type
        return (
            <Row className="btn_row g-0">
                <Col className="g-0 btn_col">
                    <FaPen size={15} className="pointer" onClick={this.toggle_modal?.bind(this, {toggle_state: true})}/>
                </Col>
                <Col className="g-0 btn_col">
                    <FaRegWindowClose size={15} onClick={this.partial_controller.reset_contact?.bind(this.parent)} className="pointer"/>
                </Col>
            </Row>
        )
    }
}