// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"

export default class ContactDescriptionModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.modal_params = {
            positioned: "relative_to_parent",   
            title: "Contact Notes",                         
            title_size: "small",                     
            closeable: false,                    
            has_overlay: false,                  
            close_on_outside_click: true,      
            x_adjustment: "0px",                
            y_adjustment: "0px",                
            draggable: false,                              
        }
    }

    get description(){
        return this.parent?.parent?.contact?.description;
    }

    get descriptionArr(){
        return this.parent?.parent?.contact?.descriptionLineArray
    }

    renderDescription(){
        if(this.description === "" ){
            return "(None)"
        }
        return (this.descriptionArr.map((line)=>{
                    return <Row><Col>{line}</Col></Row>
                })
            
        )
    }

    renderView() {
        return (
            <Row className="flex_centered p-3">
               <Col>
                    <Row>
                        <Col>
                            {this.renderDescription()}
                        </Col>
                    </Row>
               </Col>
            </Row>
        )
    }
}
