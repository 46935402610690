// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"
import "./DeleteContact.css"

export default class DeleteContactModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.closeable    = true
        this.state.modal_params = {
            positioned: "middle_of_panel",
            title: "Delete Contact",
            title_size: "",
            closeable: true,
            has_overlay: true,
            close_on_outside_click: false,
            x_adjustment: "75px",
            y_adjustment: "-150px",
            draggable: false,
        }

    }
    renderView() {
        return (
            <>
                <Row className="g-0 col_padding remove_top_padding">
                   <Col>
                        <Row className="g-0">
                            <Col className="center_btn">
                                Do you want to Delete this contact ?
                            </Col>
                        </Row>
                        <Row>
                            <Col className="center_btn">
                                <Button className={"primary_button_coloring "+this._theme} onClick={this.controller.delete_contact.bind(this)}>
                                    Yes
                                </Button>
                            </Col>
                            <Col className="center_btn">
                                <Button className={"primary_button_coloring "+this._theme}>
                                    No
                                </Button>
                            </Col>
                        </Row>
                   </Col>
                </Row>
            </>
        )
    }

}