// GENERAL REACT MODULES
import { Container, Row, Col } from 'react-bootstrap'
import { GiHamburgerMenu } from 'react-icons/gi';

// PAGE SPECIFIC MODULES
import "./NewQuotePanel.css"
import Controller from "./NewQuotePanel.controller";
import { deliveryOptions } from "./Options.js"

// ALL OTHER MODULES
import "styles/generic.css"
import MultiPanel from "components/MultiPanels/MultiPanel.jsx";
import config_data from "./NewQuotePanel.json"
import PayorObject from "module/pages/Shipments/ShipmentPartials/Payor/Payor.partial.jsx"

export default class NewQuotePanel extends MultiPanel {
    constructor(props) {
        super(props)
        this.controller                 = new Controller({params: {view: this}})
        this.state.form                 = config_data.form_objects
        this.state.channel_name         = "grafana_form"
        this.state.panel_name           = "quotes"
        this.state.panel_names          = ["new_quotes"]
        this.state.know_num_of_pallets  = false
        this.state.ltl_switch           = false
        this.state.optionSelected       = undefined
        this.state.trailer_types        = []
        this.state.data                 = { quote_request: {} }
    }
    load_customer() {
        return <PayorObject params={{parent: this, controller: this.controller, title: "Customer",
                                        data: {company: this.state?.search?.company?.selected_value}, closeable: this.controller?.check_role("INTERNAL_COTA_USER")}}/>
    }
    loadInnerFrame() {
        this.state.truckload_quote = this?.state?.data?.quote?.cost
        return (<>
            <Container className={"colored_text generic_ul_wrapper "+this.offsetWrapper()+" "+this._theme}>
                <Row className="row_1">
                    <Col>
                        <Row className="row_buffer">
                            <Col className="col_buffer buffer_adj">
                                <Row>
                                    <Col>
                                        {this.controller?.get_toggle("ltl_switch")}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="row_buffer">
                            <Col className="col_buffer">
                                <Row className='g-0'>
                                    <Col>
                                        {this.load_customer()}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="row_buffer">
                            <Col className="col_buffer">
                                <Row>
                                    <Col>
                                        Pickup ZipCode
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({data: this.state.form.pickup_zip, 
                                                                            options: undefined, 
                                                                            owner: this})}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={1} className="dropdown_thin_col">
                                <Row>
                                    <Col>
                                        <GiHamburgerMenu className="hamburger_icon" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='thin_menu'>
                                        {this.formObject({data: this.state.form.optionSelectedPickup, owner: this, options: deliveryOptions})}
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="col_buffer">
                                <Row>
                                    <Col>
                                        Delivery ZipCode
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({data: this.state.form.dropoff_zip, 
                                                                            options: undefined, 
                                                                            owner: this})}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={1} className="dropdown_thin_col">
                                <Row>
                                    <Col>
                                        <GiHamburgerMenu className="hamburger_icon" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='thin_menu'>
                                        {this.formObject({data: this.state.form.optionSelectedDropoff, owner: this, options: deliveryOptions})}
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="col_buffer">
                                <Row>
                                    <Col>
                                        Estimated Ship Date
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({data: this.state.form.date, 
                                                            options: undefined,
                                                            owner: this})}
				                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={4} className="QuoteResults">
                        <span className={"max_height "+this.controller?.is_hidden()}>
				            {this.controller?.get_results()}
                        </span>
                    </Col>
                </Row>
                {this.controller?.load_truckview_window()}
            </Container>
        </>)
    }
}
