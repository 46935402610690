// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class QuoteFormController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    hover_line(hover) {
        this.setState({hover: hover})
    }
    get_hover_class(hover) {
        if (hover) {
            return "hover_line"
        }
        return "unhover_line"
    }
    add_line_item() {
        if (Object.keys(this.view.state.data.line_items[this.view.state.data.line_items.length-1]).length === 7) {
            this.view.state.data.line_items.push({})
            this.view.forceUpdate()
        }
    }
}
