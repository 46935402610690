// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal";
import "./ShareButton.css";
import ShareRow from "./ShareRow.partial.jsx"

export default class ShareButtonModal extends _baseModal {
    constructor(props) {
        super(props)
        this.modal_controller = this.state.parent?.partial_controller
        this.state.modal_params = {
            positioned: "middle_of_panel",
            title: "Share Load",
            title_size: "",
            closeable: true,
            has_overlay: true,
            close_on_outside_click: false,
            x_adjustment: "75px",
            y_adjustment: "-150px",
            draggable: false,
        }
    }
    get link() {
        if (this.modal_controller?.link !== undefined) {
            return this.modal_controller?.link
        }
        return ""
    }
    renderView() { 
        return (
            <>
                <Row className="g-0 col_padding remove_top_padding">
                    <Col className="g-0 col_padding remove_padding width">
                        <ShareRow params={{parent: this, controller: this.modal_controller, title: "Editors", link: "https://freightpro.cotasystems.com/shipments/"+this.link }}/>
                        <ShareRow params={{parent: this, controller: this.modal_controller, title: "Carrier View", link: "https://carrierpro.cotasystems.com/loads/"+this.link }}/>
                        <ShareRow params={{parent: this, controller: this.modal_controller, title: "View Only", link: "https://mobile.cotasystems.com/load/"+this.link }}/>
                    </Col>
                </Row>
            </>
        )
    }
}
