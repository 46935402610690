// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import PartialController from "./CarrierSelection.controller.jsx"
import "../ShippingPartials.css"
import "./Carrier.css"

export default class CarrierSelection extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new PartialController({params: { view: this, controller: this.controller }}) 
    }
    get panel_color() {
        if (this.partial_controller?.insurance?.expired) {
            if (this.partial_controller?.insurance?.warning_expired) {
                return "yellow"
            }
            return "red"
        }
        return "blue"
    }
    renderView() {
        this.state.data.carrier     = this.props?.params?.carrier
        this.state.data.company     = this.props?.params?.carrier?.company
        this.state.data.insurance   = this.props?.params?.carrier?.insurance
        return (
            <Row className={"carrier_panel "+this.panel_color} id={this.name} onMouseEnter={() => this.state.view_controller?.select_carrier(this.state.index)} 
                                                            onMouseLeave={() => this.state.view_controller?.select_carrier(undefined)}>
                <Col>
                    <Row>
                        <Col className="bold">
                            {this.partial_controller?.carrier?.name}
                        </Col>
                        <Col sm={4}>
                            {this.partial_controller?.carrier?.phone}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.partial_controller?.carrier?.email}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.partial_controller?.carrier?.city} {this.partial_controller?.carrier?.state} {this.partial_controller?.carrier?.zip}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}
