import model from "./ShipmentDetails.json"

export class ShipmentDetailsModel {
    constructor(props) {
        this.props = props
    }
    data() {
        if (model.data !== undefined) {
            if (model.data.length > 0) {
                return model?.data[0]
            }
        }
    }
    rate_data() {
        return this.data()?.rate_data
    }
    miles_data() {
        return this.data()?.miles_data
    }
    co2() {
        return this.data()?.co2
    }
    shipper() {
        return this.data()?.shipper
    }
    contact() {
        return this.shipper()?.contact
    }
}