// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import ShipperObject from "module/pages/Shipments/ShipmentPartials/ShipperObject/Shipper.partial";
import PayorObject from "module/pages/Shipments/ShipmentPartials/Payor/Payor.partial";
import ColumnController from "components/Column/Column.controller.jsx"
import DistancePanel from "module/pages/Shipments/ShipmentPartials/DistancePanel/DistancePanel.partial"

export default class ColumnOneController extends ColumnController {
    constructor(props) {
        super(props)
        this.state.data = {
            shipper: {},
            consignee: {},
            stops: []
        }
        this.state.rows = this.generate_rows()
    }
    generate_rows() {
        let rows = []
        rows[0] = {obj: PayorObject, params: {params: {parent: this, controller: this.view?.controller, editable: true, index: 0, 
                    label: "Payor", closeable: (this.view?.controller?.check_role("INTERNAL_COTA_USER"))}}}
        rows[1] = {obj: ShipperObject, params: {params: {parent: this, type: "pickup", controller: this.view.controller, editable: true}}}
        if (Array.isArray(this.controller?.load?.stops)) {
            let sortedStops = this.controller?.load?.stops.sort((a, b) => a?.index - b?.index);
            sortedStops.forEach((stop, index) => {
                if(stop?.contact?.id === undefined || stop?.contact?.id === null) { return }
                rows[index+2] = {obj: ShipperObject, params: {params: {obj: stop, parent: this, type: "stops", controller: this.view.controller, editable: true, index: index, dnd: true}}}
            })
        }
        let stop_length = (isNaN(this.view.state.data.stops.length)) ? 0 : this.view.state.data.stops.length
        rows[stop_length + 2] = {obj: ShipperObject, params: {params: {parent: this, type: "consignee", controller: this.view.controller, editable: true}}}
        rows[stop_length + 3] = {obj: ShipperObject, params: {params: {only_form: true, parent: this, type: "stop", controller: this.view.controller, editable: true}}}
        if (this.calc_stop_length() > 1) {
            rows[rows.length] = {obj: DistancePanel, params: {params: {controller: this.view.controller}}}
        }
        return rows
    }
    get _rows() {
        return this.generate_rows()
    }
    calc_stop_length() {
        let len = 0
        if (this.view?.controller?.pickup?.contact?.id !== undefined) {
            len = len + 1
        }
        if (this.view?.controller?.consignee?.contact?.id !== undefined) {
            len = len + 1
        }
        if (Array.isArray(this.view?.controller?.load?.stops)) {
            len = len + this.view?.controller?.load?.stops.length
        }
        return len
    }
}
