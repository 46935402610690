// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import name_request from "assets/graphql/Contacts/Contacts.names.graphql.json"

export default class HomeController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    change_date_value(dates) {
        if (dates?.[0] !== undefined) {
            this.state.from_date = new Date(dates[0]).valueOf()
        } else {
            this.state.from_date = undefined
        }
        if (dates?.[1] !== undefined) {
            this.state.to_date = new Date(dates[1]).valueOf()
        } else {
            this.state.to_date = undefined
        }
        this.forceUpdate()
    }
    processEventReaction(decoded) {
        this.setState({key: "data", value: decoded.message.data})
        this.view.handleScreen({path_num: 0, screen_num: 1, data:decoded.message.data})
    }
    follow_on_selection({event, obj, data}) {
        this.view.empty_results()
        this.setState({key: "data", value: obj})
        this.view.handleScreen({path_num: 0, screen_num: 1, data: obj})
    }
    get _data(){
        return this.getState({key: "data"})
    }
    async get_users(name) {
        name = (name === undefined) ? "" : name
        let body    = JSON.parse(JSON.stringify(name_request))
        body.query  = body.query.replace("name: ", 'name: "'+name+'"')
        let results = await this.api.contacts.ask({caller: this, params: {body: body}})
        results?.data?.fcontacts?.map((cnt, index) => {
            this.view.state.user_options.push({ value: cnt.name, label: cnt.name.Capitalize() })
            return
        })
}
    follow_on_dropdown({key, param, value}) {
	    this.view.forceUpdate()
    }
    get user_filter() {
        if (this.view?.state?.alpha !== undefined) {
            return this.view.state.alpha
        }
        return ""
    }
}
