// GENERAL REACT MODULES
import { Row, Col } from 'react-bootstrap'

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import { BiCopyAlt } from "react-icons/bi";
import Tooltip from "../Tooltip/Tooltip.jsx"

export default class ShareRow extends _basePartial {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    renderView() {
        return (
            <Row>
                <Col sm={3}>
                    {this.state.title+" :"}
                </Col>
                <Col>
                    <a href={this.state.link} target="_blank" rel="noreferrer">{this.state.link}</a>
                </Col>
                <Col sm={1}>
                    <Tooltip content="Copied!" direction="left" clickable="true">
                        <BiCopyAlt onClick={this.controller.copy.bind(this, {link: this.state.link})}/>
                    </Tooltip>
                </Col>
            </Row>
        )
    }
}
