// GENERAL REACT MODULES
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseViewComponent from "components/General/_baseViewComponent.jsx"
import Controller from "./CotaTotalsBar.controller";
import "./CotaTotalsBar.css"

export default class CotaTotalsBar extends _baseViewComponent {
    constructor(props) {
        super(props)
        this.controller = new Controller({params: {view: this}})
    }
    renderView() {
        if (this.state.context?.authClient?.realmAccess?.roles?.includes('INTERNAL_COTA_USER') && !this.state?.context?.is_mobile()) {
            return (
                <Col sm={2} className="max_column_width max_total_column_width" id={this.name}>
                    <Container className={"cota_panel_object_zero generic_100 "}>
                        <Row className={"cota_panel_object generic_100 total_panel_color summary_panel "+this.controller.getTheme()}>
                            {this.controller.render_summary()}
                        </Row>
                    </Container>
                </Col>
            )
        } else {
	    return (<></>)
	}
    }
}
