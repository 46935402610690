
// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import CarrierModelController from "controllers/ModelControllers/Carrier/CarrierModelController"
import graph_request from "assets/graphql/Carriers/Carrier.search.graphql.json"
import general_loads from "assets/graphql/Loads/floads.graphql.json"
import logo from "module/assets/cotalogo.png"

export default class CarrierDetailsController extends _viewController {
    constructor(props) {
        super(props)
        this.people = []
        this.view.handleRoute       = this.handleRoute.bind(this)
        this.view.handleAccountInfo = this.handleAccountInfo.bind(this)
        this.view.handleAccount     = this.handleAccount.bind(this);
        this.carrier                = new CarrierModelController({params: {parent: this, 
                                                        controller: this, 
                                                        is_view: true}})
    }
    load_harness() {
        this.load_carrier(this.view?.props?.params?.panel_params)
    }
    get_factoring_logo() {
        if (this.carrier?.image !== undefined) {
            return this.carrier?.image
        }
        return logo
    }
    get company() {
        return this.carrier?.company
    }
    get billing_details() {
        return this.carrier?.billing_details
    }
    get insurance() {
        return this.carrier?.insurance
    }
    get loads() {
        return this.view.state?.data?.floads
    }
    load_cache() {
        let key = Object.keys(this.panel_controller?.cache)[0]
        let cache = this.panel_controller.getCache(key)
        if (cache !== undefined) {
            this.setState({key:"panel_params", param: "data.id", value: cache.data?.id})
        }
    }
    async load_carrier(params) {
        let id      = (params?.data?.id !== undefined) ? params?.data?.id : this.getState({key:"panel_params", param: "data.id"})
        if (id !== undefined) {
            let body    = JSON.parse(JSON.stringify(graph_request))
            body.query  = body.query.replace("carrier", 'carrier(id: "'+id+'")')
            await this.api.carriers.ask({caller: this, params: {body: body}, callback: {f: this.process_carrier}, nocache: true})
        }
    }
    process_carrier({caller, params, results}) {
	    caller.people = results?.data?.carrier?.people
        if (Array.isArray(results?.data?.carrier?.people) && Array.isArray(results?.data?.carrier?.company?.employees)) {
            caller.people = [...results?.data?.carrier?.people, ...results?.data?.carrier?.company?.employees]
        }
        let carrier = {
            carrier: results?.data?.carrier,
            company: results?.data?.carrier?.company,
	        insurance: results?.data?.carrier?.insurance
        }
        delete carrier?.carrier?.company
        if (carrier?.carrier !== undefined) {
            caller.setState({key: "data", value: carrier})
        }
        caller.view.state.data.billing_details  = caller.process_billing_details(results.data?.carrier?.billing_details)
        caller.load_loads(carrier?.carrier?.id)
        caller.view.forceUpdate()
    }
    process_billing_details(bd) {
        let billing_details = {
            bank: (bd?.bank !== undefined) ? bd?.bank : null,
            routing_number: (bd?.routing_number !== undefined) ? bd?.routing_number : null,
            account_number: (bd?.account_number !== undefined) ? bd?.account_number : null
        }
        return billing_details
    }
    async load_loads(id) {
        if (id !== undefined && id !== null) {
            let body    = JSON.parse(JSON.stringify(general_loads))
            body.query  = body.query.replace("floads(", 'floads(carrier: "'+id+'", ')
            this.api.loads.ask({caller: this, params: {body: body}, callback: {p: {name: "floads" }}, nocache: true})
        }
    }
    editCarrier(page_key) {
        this.state?.parent?.selectPanel({panel_name: page_key, cache_data: this.state.data})
    }
    handleAccount() {
        this.setState((state) => ({
            isVisible: !state.isVisible
        }));
    }
    handleRoute() {
        this.setState((state) => ({
            isHidden: !state.isHidden
        }));
    }
    handleAccountInfo() {
        this.setState((state) => ({
            isSeen: !state.isSeen
        }))
    }
    toggle_bank() {
        return (this.state.isSeen) ? "bank_float" : ""
    }
    delete_action({obj}) {
        this.carrier.documents.splice(obj?.index, 1)
    }
}
