// GENERAL REACT MODULES
import _basePartial from "components/General/_basePartial.jsx"
import { Row, Col } from 'react-bootstrap'

// ALL OTHER MODULES
import "./QuoteForm.css"
import config_data from "./QuoteLine.json"
import { typeOptions, classOptions } from "./Options.js"
import Controller from "./QuoteLine.controller.jsx"

export default class QuoteLine extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this}})
        this.state.form         = config_data.form_objects
        this.state.hover        = false
    }
    get item_data() {
        return this.controller.getState({key: "data", param: "line_items"})[this.state?.index]
    }
    get line_item() {
        return this.partial_controller.line_item
    }
    render() {
	this.state.data = this.item_data
        return (
            <Row id={this.name} onMouseEnter={this.controller.hover_line.bind(this, true)} 
                                onMouseLeave={this.controller.hover_line.bind(this, false)}>
                <Col>
                    <Row className={"bold center_only blue_text line_border "+this.controller.get_hover_class(this.state.hover)}>
                        <Col>
                            {this.parent.formObject({data: this.state.form.type, 
                                                                options: typeOptions,
                                                                controller: this.partial_controller,
                                                                owner: this})}
                        </Col>
                        <Col>
                            {this.parent.formObject({data: this.state.form.number, 
                                                            options: undefined,
                                                            controller: this.partial_controller,
                                                            owner: this})}
                        </Col>
                        <Col>
                            {this.parent.formObject({data: this.state.form.length, 
                                                            options: undefined,
                                                            controller: this.partial_controller,
                                                            owner: this})}
                        </Col>
                        <Col>
                            {this.parent.formObject({data: this.state.form.width, 
                                                            options: undefined,
                                                            controller: this.partial_controller,
                                                            owner: this})}
                        </Col>
                        <Col>
                            {this.parent.formObject({data: this.state.form.height, 
                                                            options: undefined, 
                                                            controller: this.partial_controller,
                                                            owner: this})}
                        </Col>
                        <Col>
                            {this.parent.formObject({data: this.state.form.weight, 
                                                            options: undefined, 
                                                            controller: this.partial_controller,
                                                            owner: this})}
                        </Col>
                        <Col>
                            {this.parent.formObject({data: this.state.form.class, 
                                                            options: classOptions, 
                                                            controller: this.partial_controller,
                                                            owner: this})}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}
