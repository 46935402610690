// GENERAL REACT MODULES
import React from "react";
import { Container, Row, Col } from 'react-bootstrap'

// PAGE SPECIFIC MODULES
import MultiPanel from "components/MultiPanels/MultiPanel";
import form_configs from "./EditCarriers.json"
import Controller from "./EditCarriers.controller.jsx"
import PeoplePartial from "./Partials/People.partial.jsx";
import InsurancePartial from "./Partials/Insurance.partials.jsx"
import "./EditCarriers.css"
import { countriesOptions } from "assets/countryCodes.js"
import carrier from "controllers/ApiController/Endpoints/Carriers/schema.json"
import UploadPartial from "components/FileUpload/Upload.partial.jsx"
import SaveButton from "components/Loading/SaveButton/SaveButton.jsx"

export default class EditCarriers extends MultiPanel {
    constructor(props) {
        super(props)
        this.controller = new Controller({ params: { view: this } })
        this.state.form = form_configs.form_objects
        this.state.screen = 2
        this.base_obj   = JSON.parse(JSON.stringify(carrier))
        this.state.data = JSON.parse(JSON.stringify(carrier))
        this.state.factoring_types = [{ value: "", label: "None" }]
        this.state.areas		= []
        this.state.states		= []
        this.state.trailer_types	= []
        this.state.roles		= []
        this.state.search = {
            company: { 
                    search_value: "", 
                    selecred_value: {} 
            }
        }
    }
    handleClick = () => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.onchange = (event) => {
            this.props.onFileChange(event.target.files[0]);
        }
        fileInput.click();
    }
    renderInnerFrame() {
        return (<>
            <Container className={this.offsetWrapper()}>
                <Row className="max_width_row">
                    <Col sm={1}  >
                        <Row>
                            <Col>
                            </Col>
                        </Row>
                        <Row  >
                            <Col  >
                                {console.log("EDIT CARRIER IMAGE",this)}
                                {/* {this.props.file && <p> {this.props.file?.name}</p>} */}
                                <UploadPartial params={{
                                    parent: this.view, 
                                    controller: this.controller, 
                                    endpoint: "carrier", 
                                    single_link: true, 
                                    css_rules: "icon_row icon_col", 
                                    image_css:"SettingsUserImage", 
                                    id:this?.state?.data?.carrier?.id,
                                    existing_image: this?.state?.data?.carrier?.image?.[0],
                                    new_image: this.state?.current_image,
                                    view: this,
                                    save: this.state.save,
                                    uploaded: this.state.uploaded
                                    }} />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                    </Col>
                                    <Col>
                                        {this.formObject({data: this.state.form.payor, owner: this})}
                                    </Col>
                                    <Col>
                                        {this.formObject({data: this.state.form.shipper, owner: this})}
                                    </Col>
                                    <Col>
                                        {this.formObject({data: this.state.form.consignee, owner: this})}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({ data: this.state.form.company, owner: this})}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row className="mrh">
                                    <Col>
                                        MC#
                                    </Col>
                                    <Col>
                                        DOT#
                                    </Col>
                                    <Col>
                                        SCAC#
                                    </Col>
                                    <Col>
                                        Safety Rating#
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({data: this.state.form.mc, owner: this})}
                                    </Col>
                                    <Col>
                                        {this.formObject({data: this.state.form.dot, owner: this})} 
                                    </Col>
                                    <Col>
                                        {this.formObject({data: this.state.form.scac, owner: this})}
                                    </Col>
                                    <Col>
                                        {this.formObject({data: this.state.form.safety, owner: this})}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                Address 1
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.formObject({data: this.state.form.address, owner: this})}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                &nbsp;
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.formObject({data: this.state.form.same_billing, owner: this})}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                Phone Number
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.formObject({data: this.state.form.phone, owner: this})}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                Address 2
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.formObject({data: this.state.form.address_2, owner: this})} 
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                &nbsp;
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                &nbsp;
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                Fax Number
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.formObject({data: this.state.form.fax, owner: this})}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        City
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {this.formObject({data: this.state.form.city, owner: this})} 
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        State
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {this.formObject({data: this.state.form.state, owner: this,
										                                    options: this.state.states})} 
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        Zip
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {this.formObject({data: this.state.form.zip, owner: this})}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        Country
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                    {this.formObject({data: this.state.form.country, 
                                                            owner: this,
                                                            options: countriesOptions})}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        Email
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {this.formObject({data: this.state.form.email, owner: this})} 
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={1} className="factor_label">
                                        <Row>
                                            <Col>
                                                &nbsp;
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>  
                                                Factor?
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="factor_cell">
                                        <Row>
                                            <Col>
                                                Factoring Company
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.formObject({data: this.state.form.factoring_company,
                                                                    owner: this, 
                                                                    options: this.state.factoring_types})}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                Trailer Types
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>  
                                                {this.formObject({data: this.state.form.trailer_types,
                                                                    owner: this, 
                                                                    options: this.state.trailer_types})}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                # of Trucks
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>  
                                                {this.formObject({data: this.state.form.number_of_trucks, owner: this})}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                Operating Areas
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.formObject({data: this.state.form.operating_areas,
                                                                    owner: this, 
                                                                    options: this.state.areas})}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                EIN
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>  
                                                {this.formObject({data: this.state.form.ein, owner: this})}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                            &nbsp;
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>  
                                                {this.formObject({data: this.state.form.tenninenine, owner: this})}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                            &nbsp;
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>  
                                                {this.formObject({data: this.state.form.airride, owner: this})}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                            &nbsp;
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>  
                                                {this.formObject({data: this.state.form.liftgate, owner: this})}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Payment
                                    </Col>
                                </Row>
                                <Row className="cota_light_blue_button_rounded cota_panel_padding add_shadow">
                                    <Col>
                                        <Row>
                                            <Col>
                                                Bank
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.formObject({data: this.state.form.bank, owner: this})}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                Routing #
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.formObject({data: this.state.form.routing_number, owner: this})}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                Account #
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.formObject({data: this.state.form.account_number, owner: this})}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        People
                                    </Col>
                                </Row>
                                <Row className="cota_light_blue_button_rounded cota_panel_padding add_shadow">
                                    {
                                        this.controller.people?.map((person, index) => {
                                            return <PeoplePartial params={{parent: this, person: person, controller: this.controller, index: index}} key={"person_"+index}/>
                                        })
                                    }
                                    {
                                        this.controller.employees?.map((employee, index) => {
                                            return <PeoplePartial params={{parent: this, person: employee, controller: this.controller, index: index, override_title: "employees"}} key={"employee_"+index}/>
                                        })
                                    }
                                </Row>
                            </Col>
                            <Col sm={3} className="side_panel_buffer">
                                <InsurancePartial params={{parent: this, controller: this.controller } }/>
                            </Col>
                        </Row>
                        <Row className="bottom_row">
                            <Col>
                                <UploadPartial params={{parent: this.view, controller: this.controller, endpoint: "carrier", title_row: false}} />
                            </Col>
                            <Col sm={2}>
                            </Col>
                            <Col sm={1} className="center_text save_button">
                                <SaveButton className={"primary_button_coloring " + this._theme} onClick={this?.controller?.saveAction.bind(this, "save_action")}
                                            params={{parent: this, controller: this.controller}}>
                                    Save
                                </SaveButton>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container >
        </>)
    }
}

