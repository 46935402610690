// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"
import configs from "./Carrier.json"
import "./CarrierModal.css"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Controller from "./Carrier.modal.controller.jsx"


export default class CarrierModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.form = configs.form_objects
        this.modal_controller = new Controller({params: { view: this, controller: this.controller }})
        // this.modal_controller = this.state.parent?.partial_controller
        this.state.selection = undefined
        this.state.selected = undefined
        this.state.data = {
            commodity: {}
        }
        this.onDragEnd = this.modal_controller.onDragEnd.bind(this);
        this.state.hover = undefined
        this.state.dispatched   = false
        this.state.search_carrier = ""
        this.state.to_carrier_rows = [{linehaul: null, rate_mile: null, fields: ["Linehaul ($)", "Rate/Mile ($)"]}]
        this.state.modal_params = {
            positioned: "middle_of_panel",   
            title: "",                
            title_size: "",                     
            closeable: true,                    
            has_overlay: true,                 
            close_on_outside_click: true,       
            relative_position: "",
            relative_alignment: "",
            x_adjustment: "0px",                
            y_adjustment: "0px",
          }
    }
    render_rate_line() {
        return this.state.to_carrier_rows.map((item, index) => {
            return (
                <Row key={"rate_line_"+index}>
                    <Col>
                        <Row>
                            <Col>
                                {item.fields[0]}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller.view.formObject({data: this.state.form?.[Object.keys(item)?.[0]], 
                                                        options: undefined,
                                                        controller: this.modal_controller,
                                                        owner: this})}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                {item.fields[1]}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.controller.view.formObject({data: this.state.form?.[Object.keys(item)?.[1]], 
                                                        options: undefined,
                                                        controller: this.modal_controller,
                                                        owner: this})}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )  
        })
    }
    render_dispatch() {
        return(
            <Col sm={3}>
                <Row>
                    <Col className="title_text">
                        Dispatch ?
                    </Col>
                </Row>
                <Row className="title_text">
                    <Col onClick={this.modal_controller?.submit.bind(this)} className="pointer button_decoration">
                        Yes
                    </Col>
                    <Col onClick={this.modal_controller?.reset_carrier.bind(this)} className="pointer button_decoration">
                        No
                    </Col>
                </Row>
            </Col>
        )
    }
    render_rate_confirm() {
        return (
            <Col sm={3}>
            <Row>
                <Col className="title_text">
                    Send Rate Confirmation?
                </Col>
            </Row>
            <Row className="title_text">
                <Col onClick={this.modal_controller?.submit.bind(this, true)} className="pointer button_decoration">
                    Yes
                </Col>
                <Col onClick={this.modal_controller?.submit.bind(this, false)} className="pointer button_decoration">
                    No
                </Col>
            </Row>
        </Col>
        )
    }
    render_confirm_subpanel() {
        return this.render_dispatch()
    }
    renderView() {	
        this.controller.get_distance()
        if (this.state.selected !== undefined) {
        let carrier = this.modal_controller?.filtered_carriers?.[this.state.selected]
            return (
                <>
                    <Row className="modal_row">
                        <Col>
                            <Row className="blue_bg carrier_panel g-0">
                                <Col sm={2} className="qid_number">
                                    {this.controller?.quote?.number}
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className="">
                                            {carrier?.name}
                                        </Col>
                                        <Col>
                                            {carrier?.company?.phone}
                                        </Col>
                                        <Col>
                                            {carrier?.company?.email}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {carrier?.company?.address?.location?.city} {carrier?.company?.address?.location?.state} {carrier?.company?.address?.location?.zip}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={1} className="seperator">
                        
                        </Col>
                        {this.render_confirm_subpanel()}
                    </Row>
                </>
            )
        }
        return (
            <>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Row className="g-0">
                        <Col className="large_col">
                            <Droppable droppableId="assigned_droppable">
                                {(provided, snapshot) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        <Row>
                                            <Col className="bold center_text large_font">
                                                Cota ID {this.controller?.load?.number}
                                            </Col>
                                        </Row>
                                        <Draggable index={0} draggableId={"cota_id_carrier"} isCombineEnabled id="Rate Droppable">
                                            {(provided, snapshot) => {
                                                if (snapshot.isDragging) {
                                                    return (
                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <Row className="blue_bg small_rate_panel">
                                                                <Col className="small_rate_panel_txt">
                                                                    Load # {this.controller?.load?.number}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <Row className="blue_bg rate_panel">
                                                                <Col className="rate_panel_col">
                                                                    <Row>
                                                                        <Col className="bold center_text large_font">
                                                                            Rate
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="bold center_text large_font">
                                                                            ${this.controller?.load?.rate}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="center_text">
                                                                            ${this.controller?.load?.rate_mile}/Mile
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <hr />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="bold center_text mid_font">
                                                                            Rate to Carrier
                                                                        </Col>
                                                                    </Row>
                                                                    {this.render_rate_line()}
                                                                    <Row>
                                                                        <Col>
                                                                            <hr />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <Row>
                                                                                <Col className="bold center_text mid_font">
                                                                                    ${this.modal_controller.rate_model?.linehaul}
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col className="center_text">
                                                                                    Total
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col>
                                                                            <Row>
                                                                                <Col className="bold center_text mid_font">
                                                                                    ${this.modal_controller.rate_model?.profit}
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col className="center_text">
                                                                                    Profit
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col>
                                                                            <Row>
                                                                                <Col className="bold center_text mid_font">
                                                                                    {this.modal_controller.rate_model?.margin}%
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col className="center_text">
                                                                                    Margin
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                }
                                            }}
                                        </Draggable>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </Col>
                        <Col sm={1} className="g-0 seperator">
                        </Col>
                        <Col className="large_col">
                            <Row>
                                <Col className="bold center_text large_font">
                                    Carriers
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={2}>
                                    Search
                                </Col>
                                <Col>
                                    {this.formObject({data: this.state.form?.carriers,
                                                    options: undefined,
                                                    controller: this.modal_controller,
                                                    owner: this})}
                                </Col>
                            </Row>
                            <div className="carrier_scroller">
                                {this.modal_controller.render_selection()}
                            </div>
                        </Col>
                    </Row>
                </DragDropContext>
            </>
        )
    }
}


