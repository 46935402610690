// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import "../Information.css"
import configs from "../Information.json"

export default class BasicInfo extends _basePartial {
    constructor(props) {
        super(props)
        this.state.form = configs.form_objects
    }
    renderView() {
        return (
            <>
                <Row className="g-0 col_padding remove_padding buffer_bottom_row">
                    <Col className="g-0 col_padding">
                        <Row className="g-0 col_padding remove_padding">
                            <Col>
                                PO #
                            </Col>
                        </Row>
                        <Row className="g-0 col_padding remove_padding">
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.po, owner: this})}
                            </Col>
                        </Row>
                        <Row className="g-0 col_padding remove_padding">
                            <Col>
                                BOL #
                            </Col>
                        </Row>
                        <Row className="g-0 col_padding remove_padding">
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.bol, owner: this})}
                            </Col>
                        </Row>
                        <Row className="g-0 col_padding remove_padding">
                            <Col>
                                Vessel Name
                            </Col>
                        </Row>
                        <Row className="g-0 col_padding remove_padding">
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.vessel_name, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                    <Col className="g-0 col_padding">
                        <Row className="g-0 col_padding remove_padding">
                            <Col>
                                Container #
                            </Col>
                        </Row>
                        <Row className="g-0 col_padding remove_padding">
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.container, owner: this})}
                            </Col>
                        </Row>
                        <Row className="g-0 col_padding remove_padding">
                            <Col>
                                House BOL #
                            </Col>
                        </Row>
                        <Row className="g-0 col_padding remove_padding">
                            <Col>
                                {this.controller?.view?.formObject({data: this.state.form.house_bol, owner: this})}
                            </Col>
                        </Row>
                        <Row className="g-0 col_padding remove_padding">
                            <Col>
                            &nbsp;
                            </Col>
                        </Row>
                        <Row>
                            <Col className="generic_center">
                                {/* remove info_button class from below button? */}
                                <Button className="panel_title pointer info_button" onClick={this.state.modal_controller?.toggle_extra.bind(this.state.modal_controller)}>
                                    Add More Info
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}
