// GENERAL REACT MODULES
import * as React from 'react';
import { Button } from 'react-bootstrap';
import "./SaveButton.css"
import "./loading.css"

export default class SaveButton extends React.Component {
    constructor(props) {
        super(props)
        this.parent = this.props?.params?.parent
        this.controller = this.props?.params?.controller
    }
    render() {
        if (this.parent?.state?._is_mutating) { 
            return (<div className="stub_button">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>)
        } else {
            return <Button {...this.props} disabled={this.parent?.state?._is_mutating} className={this.props.className+" btw_width"}/>;
        }
    }
}
