
// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Modal from "./Status.modal.jsx"
import "./StatusPanel.css"

export default class StatusPanel extends _basePartial {
    constructor(props) {
        super(props)
	    this._modal = Modal
	    this._draggable_modal   = false
        this.state.status = this.controller.getState({key: "data", param: "saved"})
    }
    generateMilesColumn() {
        if (this.state.data?.miles_data?.miles === undefined || this.state.data?.miles_data?.stops === undefined) {
            return (<></>)
        }
        return (
            <>
                <Row className="g-0 cota_panel_object_unsized" >
                    <Col className="g-0" >
                        Miles
                    </Col>
                    <Col className="g-0" >
                        {this.state.data?.miles_data?.miles}
                    </Col>
                </Row>
                <Row className="g-0" >
                    <Col className="g-0" >
                        Stops
                    </Col>
                    <Col className="g-0" >
                        {this.state.data?.miles_data?.stops}
                    </Col>
                </Row>
            </>
        )
    }
    get _cota_id() {
        let label = "Cota "
        if (this.controller?.load?.number !== this.controller?.load?.cota_id) {
            label = ""
        }
        if (this.controller?.load?.cota_id === undefined && this.controller?.load?.data.carrier_quote_number !== undefined) {
            label = "Quote "
        }
        return label+"ID "+this.controller?.load?.number
    }
    add_sublabel() {
        if ((this.controller?.load?.number !== this.controller?.load?.cota_id) && (this.controller?.load?.cota_id !== undefined && this.controller?.load?.cota_id !== null)) {
            return (
                <Row>
                    <Col className="small_text light">
                        Cota ID {this.controller?.load?.cota_id}
                    </Col>
                </Row>
            )
        }
    }
    renderView() {
        return (
            <Row className="g-0 max_height" id={this.name} ref={ (divElement) => { this.divElement = divElement } }>
                <Col className="g-0 inner_margin_right distance_subpanel" >
                    {
                        this.generateMilesColumn()
                    }
                </Col>
                <Col className="g-0 inner_margin_left" >
                    <Row className="g-0 max_height" >
                        <Col className="g-0 center_col buckle_col" sm={2} >
                            <div className="booked_subpanel status_buckle pointer center_text" onClick={this.toggle_modal?.bind(this, {toggle_state: true})}>
                                {this.controller?.load?.status}
                            </div>
                        </Col>
                        <Col className="g-0 heavy_font status_id center_col" >
                            <Row>
                                <Col>
                                    {this._cota_id}
                                </Col>
                            </Row>
                            {this.add_sublabel()}
                        </Col>
                        <Col className="g-0 heavy_font" >
                            {this.state.data?.cota?.id}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}
