// GENERAL REACT MODULES
import React from "react";
import { Container, Row, Col } from 'react-bootstrap'
import { Form } from 'react-bootstrap';

// PAGE SPECIFIC MODULES
import "./AddInsurance.css"

// ALL OTHER MODULES
import "styles/generic.css"
import MultiPanel from "components/MultiPanels/MultiPanel";


class AddInsurance extends MultiPanel {
    constructor(props) {
        super(props)
        this.state = {}
    }
    addCustomer(page_key) {
        this.state?.parent?.selectPanel(this.state.parent.getCurrentTree()[page_key])
    }
    loadInnerFrame() {
        return (
            <Container className={this.offsetWrapper()}>
                <Row>
                    <Col>
                        <Row>
                            Insurance
                        </Row>
                        <Row>
                            <Form.Control type="email" placeholder="Default input" className="cota_bordered_field"/>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            NAIC#
                        </Row>
                        <Row>
                            <Form.Control type="email" placeholder="Default input" className="cota_bordered_field"/>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            Address 1
                        </Row>
                        <Row>
                            <Form.Control type="email" placeholder="Default input" className="cota_bordered_field"/>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            &nbsp;
                        </Row>
                        <Row>
                        Same Billing Address?   
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            Phone Number
                        </Row>
                        <Row>
                            <Form.Control type="email" placeholder="Default input" className="cota_bordered_field"/>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default AddInsurance;