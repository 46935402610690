// GENERAL REACT MODULES
import "./ContactDetails.css"
import "styles/generic.css"
import MultiPanel from "components/MultiPanels/MultiPanel";
import { Container, Row, Col, Button } from 'react-bootstrap'
import grafana_config from "assets/grafana_config.json"
import config_data from "./ContactDetails.json"
import Controller from './ContactDetails.controller.jsx';
import PayorObject from "module/pages/Shipments/ShipmentPartials/Payor/Payor.partial.jsx"
import PeopleListPartial from "./Partials/PeopleList/PeopleList.partial.jsx"
import StatsListPartial from "./Partials/Stats/Stats.partial.jsx"
// import ListPartial from "./Partials/List/List.partial.jsx"
import ListPartial from "components/List/List.partial.jsx"
import ListModal from "components/WebObjectModal/WebObject.modal"
import Modal from "./ContactShare.modal"
import DeleteContact from "./DeleteContact/DeleteContact.partial.jsx"

export default class ContactDetails extends MultiPanel {
    constructor(props) {
        super(props)
        this._modal                 = Modal
        this.list_modal             = ListModal
        this.controller             = new Controller({params: {view: this }})
        this.state.form             = config_data.form_objects
        this.state.config_data      = config_data
        this.state.panel_names      = ["contact_details_people", "contact_details_quotes", "contact_details_shipments"]
        this.state.panel_configs    = grafana_config["pages"]["contacts"]
        this.state.live             = false
        this.state.live_action      = false
    }    
    load_customer() {
        if (this.controller?.company?._id !== undefined && this.controller?.company?._id !== null) {
        // if (this.state?.data?.company?.id !== undefined) {
        return <PayorObject params={{ parent: this, controller: this.controller,
                                                data: {company: this.state?.data?.company}}}/>
        }
        return (
            <span className="title">{this.controller?.contact?.name}</span>
        )
    }
    render_documents() {
        if (Array.isArray(this.controller?.contact?.documents)) {
            return (
                <Row>
                    <Col>
                        <Row>
                            <Col className='blue_font bold center text_row'>
                                Documents
                            </Col>
                        </Row>
                        <Row>
                            <Col className='g-0'>
                                <ListPartial params={{ parent: this, controller: this.controller, 
                                                        obj: "contact", attr: "documents", 
                                                        keys: ["name"], managed: true, list_modal: this.list_modal }}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )
        }
    }
    renderInnerFrame() {
        return (<>
            <Container className={this.offsetWrapper()} id={this.name}>
                <Row className="padding_panel">
                    <Col>
                        <Row>
                            <Col>
                                {this.load_customer()}
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        {this.formObject({data: this.state.form?.payor, owner: this})}
                                    </Col>
                                    <Col>
                                        {this.formObject({data: this.state.form?.shipper, owner: this})}
                                    </Col>
                                    <Col>
                                        {this.formObject({data: this.state.form?.consignee, owner: this})}
                                    </Col>
                                    <Col>
                                        {this.formObject({data: this.state.form?.importer, owner: this})}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({data: this.state.form?.same_billing_address, owner: this})}
                                    </Col>
                                    <Col>
                                        {this.formObject({data: this.state.form?.require_app, owner: this})}
                                    </Col>
                                    <Col>
                                        {this.formObject({data: this.state.form?.liftgate, owner: this})}
                                    </Col>
                                    <Col>
                                        &nbsp;
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col className="label_items">
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        {this.controller?.contact?.address}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {this.controller?.contact?.address_2}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {this.controller?.contact?.location}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        {this.controller.contact.phone}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {this.controller.contact.email}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <PeopleListPartial params={{ parent: this, 
                                                                                controller: this.controller,
                                                                                people: this.state?.data?.contact?.people }} />
                                            </Col>
                                            <Col sm={1}>
                                                &nbsp;
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <StatsListPartial params={{ parent: this, 
                                                                                controller: this.controller,
                                                                                items: [] }} />
                                            </Col>
                                            <Col sm={1}>
                                                &nbsp;
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {this.render_documents()}
                            </Col>
                            <Col sm={4}>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col className="label_items center_only">
                                                Contact Notes
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="center_only">
                                                {this.controller?.contact?.description ? this.controller.contact.descriptionLineArray.map(el => <div>{el}</div>) : "-"}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col className="label_items center_only">
                                                Quotes
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <ListPartial params={{ parent: this, controller: this.controller, 
                                                                obj: "quotes", 
                                                                keys: ["customer_name", "pickup_zip", "dropoff_zip", "cost"], 
                                                                managed: true }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col className="label_items center_only">
                                                Shipments
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <ListPartial params={{ parent: this, controller: this.controller, 
                                                                obj: "loads", 
                                                                keys: ["carrier", "day", "pickup_zip", "dropoff_zip", "rate"], 
                                                                managed: true }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="dates_padding">
                                    <Col>
                                        <Row className="dates_row">
                                            <Col>
                                                Created:
                                            </Col>
                                            <Col>
                                                {this.controller?.contact?._created}
                                            </Col>
                                        </Row>
                                        <Row className="dates_row">
                                            <Col>
                                                Last transaction:
                                            </Col>
                                            <Col>
                                                {this.controller?.contact?._edited}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row><Row>
                            <Col>
                                &nbsp;
                            </Col>
                            <Col sm={2} className="align_right">
                                <DeleteContact params={{ parent: this, controller: this.controller}}/>
                            </Col>
                            <Col sm={2} className={"align_right "+this._theme}>
                                <Button className={"primary_button_coloring "+this._theme} onClick={this.toggle_modal?.bind(this, {toggle_state: true})}>
                                    Share
                                </Button>
                            </Col>
                            <Col sm={2} className="align_center">
                                <Button className={"primary_button_coloring "+this._theme} onClick={this.controller.editContact.bind(this, "edit_contact")}>
                                    Edit
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>)
    }
}
