export const deliveryOptions = [
    { value: "appointment_required", label: "Appointment Required", disabled: true },
    { value: "residential", label: "Residential" },
    { value: "liftgate", label: "Liftgate" },
    { value: "container_station", label: "Container Station", disabled: true },
    { value: "airport", label: "Airport", disabled: true },
    { value: "trade_show", label: "Trade Show" },
    { value: "nyc_metro", label: "NYC Metro", disabled: true },
    { value: "military", label: "Military" },
    { value: "inside_delivery", label: "Inside Delivery" },
    { value: "limited_access", label: "Limited Access"}
];

export const accessorialsOptions = [
    { value: "overdimensional", label: "Overdimensional" },
    { value: "stackable", label: "Stackable" },
    { value: "in_bond", label: "In-Bond" },
    { value: "food_grade", label: "Food Grade" },
    { value: "holiday_pickup", label: "Holiday Pickup" }
]

export const trailerTypeOptions = [
    { value: "VAN", label: "Van"},
    { value: "REEFER", label: "Reefer"},
    { value: "FLATBED", label: "Flatbed"},
    { value: "STEPDECK", label: "Stepdeck"},
    { value: "DOUBLEDROPRGN", label: "DoubleDrop/RGN"},
    { value: "CHASSIS", label: "Chassis"},
    { value: "STRETCH", label: "Stretch"},
    { value: "TANKER", label: "Tanker"},
    { value: "BULK", label: "Bulk"},
    { value: "HOTSHOT", label: "Hotshot"},
    { value: "LIVESTOCK", label: "Livestock"},
    { value: "POWERONLY", label: "Power Only"},
]
