export const statusOptions = [
    { value: "in_transit", label: "In Transit" },
    { value: "delivered", label: "Delivered" },
    { value: "at_stop", label: "At Stop" },
    { value: "at_consignee", label: "At Consignee" },
    { value: "pending", label: "Pending" },
    { value: "at_shipper", label: "At Shipper" },
    { value: "billed", label: "Billed" },
    { value: "paid", label: "Paid" },
    { value: "dispatched", label: "Dispatched" },
    { value: "booked", label: "Booked" }
];
