// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import { internalOptions, externalOptions } from "./Options.js"

export default class HomeController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    get sidebar() {
        return this.view?.state?.context?.sidebar
    }
    get_user_image(url) {
        if (url !== undefined && url !== null) {
            return (<img src={url} alt="Cota User" />)
        }
        return (<></>)
    }
    checkValues(panel_values) {
        let panels_completed = true
        panel_values?.forEach((key, index) => {
            if (key?.value === undefined) {
                panels_completed = false
            }
        })
        return panels_completed
    }
    getActiveViews() {
        return this.state?.windows.reduce(function (a, b) {
            return {display: a.display + b.display}; // returns the number of active panels
        })
    }
    follow_event(decoded) {
        if (decoded.channel === "grafana_loader" && this.check_role("INTERNAL_COTA_USER")) {
            if (this.view?.state?.unloaded !== undefined) {
                this.view.state.windows = [...this.view.state.windows, ...this.view.state.unloaded]
                this.view.state.unloaded = undefined
                this.view.forceUpdate()
            }
        }
    }
    check_roles(user) {
        if (user?.roles !== undefined) {
            Object.keys(user.roles).forEach((key, value) => {
                if (user.roles[key].status === "APPLIED") {
                    this.setState({screen: 2})
                }
            })
        }
    }
    processEventReaction(decoded) {
	    if (decoded.channel === "grafana_row" && this._isMounted) {
            if (decoded?.message?.data?.status.toLowerCase() === "Dispatched".toLowerCase()) {
                this.sidebar?.controller?._handleClick(this.sidebar.context?.menus?.[0]?.[2], this.view.state.context)
            }
            if (decoded?.message?.data?.status.toLowerCase() === "Pending".toLowerCase()) {
		        console.log(this.sidebar.context?.menus)
                this.sidebar?.controller?._handleClick(this.sidebar.context?.menus?.[0]?.[1], this.view.state.context)
            }
	    }
    }
    get options() {
        if (this.check_role("INTERNAL_COTA_USER")) {
            return internalOptions
        }
        return externalOptions
    }
    follow_on_dropdown({key, value, param}) {
        switch (value) {
            case "quote":
                this.sidebar?.controller?._handleClick(this.sidebar.context?.menus?.[0]?.[1], this.view.state.context)
                break
            case "shipment":
                this.sidebar?.controller?._handleClick(this.sidebar.context?.menus?.[0]?.[2], this.view.state.context)
                break
            case "contact":
                this.sidebar?.controller?._handleClick(this.sidebar.context?.menus?.[0]?.[3], this.view.state.context)
                break
            case "carrier":
                this.sidebar?.controller?._handleClick(this.sidebar.context?.menus?.[0]?.[4], this.view.state.context)
                break
            default:
                break
        }
    }
}
