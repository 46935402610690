// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import "module/pages/Shipments/ShipmentPartials/ShippingPartials.css"

import DatesPanel from "module/pages/Shipments/ShipmentPartials/DatesPanel/Dates.partial";
import CommodityPanel from "module/pages/Shipments/ShipmentPartials/Commodity/Commodity.partial";
import EquipmentPanel from "module/pages/Shipments/ShipmentPartials/Equipment/Equipment.partial";
import RatePanel from "module/pages/Shipments/ShipmentPartials/RatePanel/Rate.partial";
import InformationPanel from "module/pages/Shipments/ShipmentPartials/Information/Information.partial";
import DocumentsPanel from "module/pages/Shipments/ShipmentPartials/Documents/Documents.partial";
import NotesPanel from "module/pages/Shipments/ShipmentPartials/Notes/Notes.partial";
import ColumnController from "components/Column/Column.controller.jsx"

import "./ColumnTwo.css"

export default class ColumnTwoController extends ColumnController {
    constructor(props) {
        super(props)
        this.state.data = {}
        this.std_params = {params: {parent: this, controller: this.view.controller, editable: true}}
        this.state.rows = this.generate_rows()
    }
    generate_rows() {
        let rows = Array(6).fill(undefined)
        // rows[0] = {obj: StatusPanel, params: this.std_params}
        // if (this.determine_state({matchval: 3})) {
        //     rows[0] = {obj: DatesPanel, params: this.std_params}
        // }
        if (this.determine_state({matchval: 4})) {
            // this.view?.controller.data.saved.details = true
            // this.view?.controller?.setState({key: "save_title", value: "Dispatch"})
            rows[0] = {obj: CommodityPanel, params: this.std_params}
            rows[1] = {obj: EquipmentPanel, params: this.std_params}
            rows[2] = {obj: RatePanel, params: this.std_params}
            rows[3] = {obj: InformationPanel, params: this.std_params}
            rows[4] = {obj: DocumentsPanel, params: this.std_params}
            rows[5] = {obj: NotesPanel, params: this.std_params}
        }
        return rows
    }
    determine_state({threshold, matchval}) {
        let data = this.view.controller.getState({key: "data"})
        let columnstate = 0
        let company = data?.company
        if (Array.isArray(data?.company)) {
            company = data?.company[0]
        }
        if (company?.id !== undefined ) { columnstate = columnstate+ 1 }
//        if (data?.company?.id !== undefined) { columnstate = columnstate+ 1 }
        if (data.pickup?.contact?.id !== undefined) { 
            if (Object.keys(data.pickup).length > 0) {
                columnstate = columnstate + 1
            }
        }
        if (data.consignee?.contact?.id !== undefined) { 
            if (Object.keys(data.consignee).length > 0) {
                columnstate = columnstate + 1
            }
        }
        if (data?.stop !== undefined) {
            if (Array.isArray(data.stop)) {
                if (data.stops.length > 0) {
                    columnstate = columnstate + 1
                }
            }
        }
        columnstate = columnstate+this.controller?.determine_state();
        // if (data.saved !== undefined) {
        //     if (data.saved?.times) {
        //         columnstate = columnstate + 1
        //     }
        // }
        if (threshold !== undefined) {
            if (threshold >= columnstate) {
                return true
            }
        }
        if (matchval !== undefined) {
            if (matchval === columnstate) {
                return true
            }
        }
        return false
    }
    get _rows() {
        return this.generate_rows()
    }
    get _filtered_rows() {
        return this.state.rows.filter(e => e)
    }
    get _data() {
        return this.state.data
    }
}
