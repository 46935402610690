export const attributesOptions = [
    { value: "created", label: "Created" },
    { value: "carrier", label: "Carrier" },
    { value: "day", label: "Day" },
    { value: "day_created", label: "Day Created" },
    { value: "day_str", label: "Day String" },
    { value: "updated", label: "Updated" },
    { value: "day_updated", label: "Updated Day" },
    { value: "created_date", label: "Created Date" },
    { value: "updated_date", label: "Updated Date" },
    { value: "co2", label: "Co 2" },
    { value: "days", label: "Days" },
    { value: "rate", label: "Rate" },
    { value: "trailer_type", label: "Trailer Type" },
    { value: "trailer_length", label: "Trailer Length" },
    { value: "load_number", label: "Load Number" },
    { value: "length", label: "Length" },
    { value: "total_weight", label: "Total Weight" },
    { value: "total_amount", label: "Total Amount" },
    { value: "asset_type", label: "Asset Type" },
    { value: "distance", label: "Distance" },
    { value: "raw_distance", label: "Distance (Raw)" },
    { value: "rate_mile", label: "Rate Mile" },
    { value: "pickup", label: "Pickup" },
    { value: "pickup_zip", label: "Pickup Zip" },
    { value: "pickup_date", label: "Pickup Date" },
    { value: "pickup_contact", label: "Pickup Contact" },
    { value: "dropoff", label: "Dropff" },
    { value: "pickup_zip", label: "Pickup Zip" },
    { value: "dropoff_date", label: "Dropff Date" },
    { value: "dropoff_contact", label: "Dropff Contact" },
    { value: "company", label: "Company" },
    { value: "revenue", label: "Revenue" },
    { value: "status", label: "Status" },
    { value: "cota_id", label: "Cota ID" },
    { value: "linehaul", label: "Linehaul" },
];