// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"
import ModalController from "./Notes.modal.controller.jsx"
import configs from "./Notes.json"
import "./Notes.css"

export default class NotesModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.data = {
            note: {}
        }
        this.state.form = configs.form_objects
        // this.modal_controller = this.state.parent?.partial_controller
        this.modal_controller = new ModalController({ params: { view: this } })
        this.state.modal_params = {
            positioned: "middle_of_panel",
            title: "Notes",
            title_size: "small",
            x_adjustment: "0px",
            y_adjustment: "-100px",
            has_overlay: false,
            close_on_outside_click: true,
            closeable: true,
            draggable: true
        }
        this.state.index = 0
    }
    renderView() {
        return (
            <div>
                <Row className="g-0 modal_width col_padding remove_top_padding buffer_bottom_row margin_1">
                    <Col className="g-0 col_padding remove_padding">
                        <Row className="g-0">
                            <Col key={"note_input_"+this.state.index} className="cap_sentance">
                                {this.controller?.view?.formObject({ data: this.state.form.note, owner: this, index: 0, controller: this.modal_controller })}
                            </Col>
                            <Col className="checkbox_title">
                                {this.controller?.view?.formObject({ data: this.state.form.viewable, owner: this, index: 0, controller: this.modal_controller })}
                            </Col>
                            <Col>
                                <Button className={"toggle_button_style " + this.controller.getTheme()} onClick={this.modal_controller.add_object.bind(this)}>
                                    Add
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="g-0 col_padding remove_top_padding buffer_bottom_row">
                    <Col className="g-0 col_padding remove_padding">
                        <Row className="g-0 window white_text">
                            <Col>
                                {
                                    this.controller?.load?.notes?.map((key, index) => {
                                        return (
                                            <Row className="g-0 note_row" key={"note_" + index}>
                                                <Col>
                                                    {key?.note_text?.Capitalize()}
                                                </Col>
                                                <Col sm={2}>
                                                    {key?.creator?.Capitalize()}
                                                </Col>
                                                <Col sm={2}>
                                                    {key?.created_date}
                                                </Col>
                                                {key.viewable ? <Col className="left" sm={2}><FaEye></FaEye></Col> : <Col className="left" sm={2}><FaEyeSlash></FaEyeSlash></Col>}
                                            </Row>
                                        )
                                    })
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}
