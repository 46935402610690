// GENERAL REACT MODULES
import React from "react";
import { Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import Controller from "./SearchCarriersMenu.controller";
import { MainConsumer } from "controllers/MainContext"
import "./SearchCarriersMenu.css"

// ALL OTHER MODULES
import PanelMenu from "module/components/CotaPanel/PanelMenu/PanelMenu.view.jsx";

export default class SearchCarriersMenu extends PanelMenu {
    constructor(props) {
        super(props)
        this.controller = new Controller({params: {view: this}})
    }
    renderView() {
        return (
            <MainConsumer>
                {(context) => {
                    this.context = context
                    return (
                        <div className={"panelMenuWrapper panel_menu_header_color "+this._theme}>
                            <Button onClick={this.controller.clearView.bind(this)} 
                                    className={"panelRoundButton btn btn-primary btn-sm primary_button_coloring "+this._theme}>
                                Clear
                            </Button>
                        </div>
                    )
                }
            }
            </MainConsumer>
        )
    }
}