// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import CarrierModelController from "controllers/ModelControllers/Carrier/CarrierModelController.jsx"

export default class CarrierSelectionModalController extends _viewController {
    constructor(props) {
        super(props)
        this.carrier    = new CarrierModelController({params: {parent: this, 
                                                        controller: this, 
                                                        is_view: true}})
    }
    get insurance() {
        return this.carrier?.insurance
    }
}