// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import InformationModelController from "controllers/ModelControllers/Shipping/InformationModelController.jsx"

export default class InformationModalController extends _viewController {
    constructor(props) {
        super(props)
        this._configs = {
            local: "Information",
            array: "Informations"
        }
        this.information = new InformationModelController({params: {parent: this, 
                                                                controller: this.view.controller,
                                                                is_view: true}})
    }
}
