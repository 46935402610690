// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class CarrierTripsViewController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
}