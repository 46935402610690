
// GENERAL REACT MODULES
import React from "react";
import { Row, Col } from 'react-bootstrap'

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import "./TripsView.css"

export default class TripsView extends _basePartial {
    constructor(props) {
        super(props)
        this.state.data = {}
        this.state.data.trips = []
    }
    render_row({trip, index}) {
        return (
            <Row className="center" key={"trip_"+index}>
                {Object.values(trip).map((value, i) => {
                    return (
                        <Col key={"trip_col_"+i}>
                            {value}
                        </Col>
                    )
                })}
            </Row>
        )
    }
    renderView() {
        return(
            <Row id={this.name}>
                <Col>
                    <Row className="blue_font center bold">
                        <Col>
                            Trip #
                        </Col>
                        <Col>
                            Shipper
                        </Col>
                        <Col>
                            Destination
                        </Col>
                        <Col>
                            Trailer
                        </Col>
                        <Col>
                            Driver
                        </Col>
                        <Col>
                            Status
                        </Col>
                        <Col>
                            $/Mile
                        </Col>
                        <Col>
                            Available
                        </Col>
                    </Row>
                </Col>
                {
                    this.state.data.trips.map((trip, index) => {
                        return this.render_row({trip: trip, index: index})
                    })
                }
            </Row>
        )
    }
}
