// GENERAL REACT MODULES
import { Container, Row, Col, Button } from 'react-bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css';

// PAGE SPECIFIC MODULES
import MultiPanel from "components/MultiPanels/MultiPanel";
import grafana_config from "assets/grafana_config.json"
import Controller from './CarrierDetails.controller.jsx';
import InsuranceView from "./Partials/InsuranceView/InsuranceView.partial.jsx"
import TripsView from "./Partials/TripsView/TripsView.partial"
import PeopleListPartial from "module/pages/Contacts/ContactDetails/Partials/PeopleList/PeopleList.partial.jsx"
import "./CarrierDetails.css"
import ListPartial from "components/List/List.partial.jsx"
// import ListPartial from "../../Contacts/ContactDetails/Partials/List/List.partial.jsx"
import ListModal from "components/WebObjectModal/WebObject.modal"
import Modal from "./CarrierDetails.Modal.jsx"
import UploadPartial from "components/FileUpload/Upload.partial.jsx";

export default class CarrierDetails extends MultiPanel {
    constructor(props) {
        super(props)
        this._modal                 = Modal
        this.list_modal             = ListModal
        this._draggable_modal       = false
        this.controller             = new Controller({ params: { view: this } })
        this.state.panel_names      = ["carrier_details_trips", "carrier_details_people"]
        this.state.panel_configs    = grafana_config["pages"]["carriers"]
        this.state.live             = false
        this.state.live_action      = false
    }
    render_factoring_check() {
        if (this.controller?.carrier?.factoring_company?.id !== undefined) {
            return <i className="bi bi-check-circle-fill icon_green checkmark"></i>
        }
    }
    wrap_toggle_modal() {
        if (this.controller.billing_details?.bank !== null && this.controller.billing_details?.bank !== undefined) {
            this.toggle_modal({toggle_state: true})
        }
    }
    renderInnerFrame() {
        return (
            <Container className={this.offsetWrapper()}>
                <Row className='blue_font'>
                    <Col className="cota_sub_title">
                        <span>{this.controller?.carrier?.name}</span>
                    </Col>
                    <Col>
                        <span>MC#:</span> 
                        <span className='bold'>{this.controller?.company?.motor_carrier}</span>
                    </Col>
                    <Col>
                        <span>DOT#</span> 
                        <span className='bold'>{this.controller?.company?.dot_number}</span>
                    </Col>
                    <Col>
                        <span>SCAC:</span> 
                        <span className='bold'>{this.controller?.company?.scac_code}</span>
                    </Col>
                    <Col>
                        <span>Safety:</span> 
                        <span className='bold'>{this.controller?.company?.safety_rating}</span>
                    </Col>
                    <Col>
                        <span>EIN:</span> 
                        <span className='bold'>{this.controller?.company?.ein}</span>
                    </Col>
                </Row>
                <Row className="max_width_row">
                    <Col className='pad_panel'>
                        <Row>
                            <Col sm={1} className="logo_col">
                                {/* <div className="logo"> </div> */}
                                {console.log("CARRIER IMAGE",this)}
                                <UploadPartial params={{
                                    parent: this.view, 
                                    controller: this.controller, 
                                    endpoint: "contact", 
                                    single_link: true, 
                                    css_rules: "icon_row icon_col", 
                                    // image_css:"SettingsUserImage", 
                                    id:this.state?.data?.carrier?.id, 
                                    existing_image: this.parent.state.image ?? this.state?.data?.carrier?.image?.[0],
                                    new_image: this.parent.state.image ?? this.state?.current_image,
                                    view: this,
                                    save: this.state.save,
                                    uploaded: this.state.uploaded,
                                    disabled : true
                                    }} />
                            </Col>
                            <Col>
                                <Row>
                                    <Col className='text_row'>
                                        <Row>
                                            <Col>
                                                {this.controller?.carrier?.address}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.controller?.carrier?.city}, {this.controller?.carrier?.state} {this.controller?.carrier?.zip} {this.controller?.carrier?.country}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                &nbsp;
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className='text_row'>
                                        <Row>
                                            <Col>
                                                Phone {this.controller?.carrier?.phone}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                Fax {this.controller?.carrier?.fax}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.controller?.carrier?.email}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={6}>
                                        <Row>
                                            <Col className="logo_row">
                                                <img src={this.controller?.get_factoring_logo()} border="1px solid black" alt="Cota Logo" className="carrier_logo" />
                                            </Col>
                                            <Col className="cota_sub_title font_weight_heavy carrier_text">
                                                Carrier
                                            </Col>
                                        </Row>
                                        <Row className='text_row'>
                                            <Col className='blue_font right'>
                                                <Row>
                                                    <Col>
                                                        Factoring:
                                                    </Col>
                                                    <Col className='bold'>
                                                        {this.controller?.carrier?.factoring_company?.name}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={2}>
                                                <Row>
                                                    <Col>
                                                        {this.render_factoring_check()}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col className='blue_font bold center text_row'>
                                        &nbsp;
                                    </Col>
                                </Row>
                                <Row className='padding_trucks'>
                                    <Col className="bold banking_col text_row">
                                        <Row>
                                            <Col>
                                                {this.controller?.company?.trailer_types_flat}
                                            </Col>
                                            <Col sm={3}>
                                                {this.controller?.company?.number_of_trucks_labeled}
                                            </Col>
                                            <Col>
                                                {this.controller?.company?.operating_areas_flat}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={2} onClick={this.wrap_toggle_modal?.bind(this)}>
                                <Row>
                                    <Col className='blue_font bold center text_row'>
                                        Bank
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="bold banking_col text_row pnt" >
                                        {this.controller.billing_details?.bank}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col className='blue_font bold center text_row'>
                                        Trips
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TripsView params={{parent: this, controller: this.controller } }/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Row>
                                    <Col className='blue_font bold center text_row'>
                                        Documents
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='g-0'>
                                        <ListPartial params={{ parent: this, controller: this.controller, 
                                                                obj: "carrier", attr: "documents",
                                                                keys: ["name"], managed: true, list_modal: this.list_modal }} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col className='blue_font bold center text_row'>
                                        People
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='g-0'>
                                        <PeopleListPartial params={{ parent: this, controller: this.controller, items: [] }} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={2} className='min_col_right'>
                        <Row>
                            <Col>
                                <Row>
                                    <Col className='blue_font bold center text_row'>
                                        Insurance
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InsuranceView params={{parent: this, controller: this.controller } }/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col className='blue_font bold center text_row'>
                                        Cota Freight History
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='g-0'>
                                        <ListPartial params={{ parent: this, controller: this.controller, obj: "loads",
                                                            keys: ["pickup_zip", "rate"],
                                                            items: this.state.data.floads }} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        &nbsp;
                    </Col>
                    <Col sm={2} className='btn_pos'>
                        <Button className={"primary_button_coloring "+this._theme} onClick={this.controller.editCarrier.bind(this, "edit_carrier")}>
                            Edit
                        </Button>
                    </Col>
                </Row>
            </Container>
        )
    }
}
