// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import CarrierModelController from "controllers/ModelControllers/Carrier/CarrierModelController.jsx"
import RateModelController from "controllers/ModelControllers/Shipping/RateModelController"
import CarrierSelection from "./CarrierSelection.partial.jsx"

export default class CarrierModalController extends _viewController {
    constructor(props) {
        super(props)
        this.carrier    = new CarrierModelController({params: {parent: this, 
                                                                controller: this, 
                                                                is_view: true}})
        this.rate_model = new RateModelController({params: {parent: this, 
                                                                controller: this.controller, 
                                                                is_view: true}})
    }
    select_carrier(selection) {
        this.setState({key: "selection", value: selection})
    }
    reset_carrier() {
        this.setState({selected: undefined})
    }
    assign_carrier() {
        this.setState({key: "selected", value: this.view.state.selection})
    }
    onDragEnd(result) {
        this.modal_controller.assign_carrier()
        const { destination } = result;
        if (!destination) {
            return;
        }
    }
    follow_on_search({results}) {
        if (results?.data?.carriers.length === 0) {
            this.controller.view.state.carriers = undefined
            this.controller.request_data({req: this.controller.requests?.[1]})
        } else {
            this.controller.setState({key: "carriers", value: results?.data?.carriers})
        }
    }
    render_selection() {
        let carriers = this.filtered_carriers
        carriers = this.filtered_carriers
        if (Array.isArray(carriers)) {
            return carriers?.map((carrier, index) => {
                return(
                    <CarrierSelection key={"carrier_select_"+index} params={{parent: this, carrier: carrier, controller: this.controller, view_controller: this, index: index }}/>
                )
            })
        }
        return (<></>)
    }
    get filtered_carriers() {
        if (this.controller?.view?.state?.carriers !== undefined) {
            if (this.view?.state?.data?.carriers?.length > 1) {
                if (typeof this.view?.state?.data?.carriers === "string") {
                    let carriers = this.controller?.view?.state?.carriers?.filter(carrier => {
                        return carrier?.name?.toLowerCase().includes(this.view?.state?.data?.carriers?.toLowerCase())
                    })
                    return carriers
                }
            }
            return JSON.parse(JSON.stringify(this.controller?.view?.state?.carriers))
        }
        return []
    }
    dispatch() {
        this.setState({dispatched: true})
    }
    submit() {
        this.controller.setState({key: "data", param: "carrier", value: this.modal_controller?.filtered_carriers?.[this.state.selected]})
        this.controller.load.carrier    = this.modal_controller?.filtered_carriers?.[this.state.selected]
        this.controller.load.status     = "DISPATCHED_CARRIER"
        this.controller.load.status_details = {code: 22, label: "Dispatched", name: "DISPATCHED_CARRIER", value: "DISPATCHED_CARRIER"}
        this.controller.load.linehaul   = this.modal_controller?.rate_model?.linehaul
        this.controller.load.rate_mile  = this.modal_controller?.rate_model?.rate_mile
        this.controller.load.profit     = this.modal_controller?.rate_model?.profit
        this.controller.load.margin     = this.modal_controller?.rate_model?.margin
        this.controller.setState({key: "data", param: "saved.dispatched", value: true})
        this.setState({dispatched: false, selection: undefined, selected: undefined, carriers: ""})
        this.controller?.save_action()
        this.state.parent.toggle_modal({})
    }
    add_rate() {
        console.log("ADDING")
    }
    get linehaul() {
        return this.view.state?.to_carrier_rows?.[0]
    }
    get rate_mile() {
        if (this.linehaul?.rate_mile !== undefined) {
            if (!isNaN(this.linehaul?.rate_mile)) {
                return this.linehaul.rate_mile
            }
        } else {
            
        }
        return 0
    }
    get linehaul_cost() {
        if (this.linehaul?.linehaul !== undefined) {
            if (!isNaN(this.linehaul?.linehaul)) {
                return this.linehaul.linehaul
            }
        }
        return 0
    }
    get total() {
        return this.controller?.load?.cost
    }
    get profit() {
        return Math.round((this.controller?.load?.cost - this.linehaul_cost) * 100) / 100
    }
    get margin() {
        return Math.round(100 -((this.linehaul_cost / this.controller?.load.cost) * 100))
    }
    get to_carrier_rows() {
        return this.view.state.to_carrier_rows
    }

    follow_on_empty({data}) {
        // this.controller.view.state.carriers = JSON.parse(JSON.stringify(this.controller?.view?.state?.all_carriers))
    }
}
