// GENERAL REACT MODULES
import { Row, Col } from 'react-bootstrap'

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import form_configs from "./EditContact.json"

export default class EditPerson extends _basePartial {
    constructor(props) {
        super(props)
        this.state.form     = JSON.parse(JSON.stringify(form_configs.people_object))
        this.state.person   = {
            first_name: undefined,
            last_name: undefined,
            email: undefined,
            phone: undefined
        }
        this.state.form_disabled = false
    }
    renderView() {
        if (this.state.override_title !== undefined) {
            Object.keys(this.state.form).forEach((k, i) => {
                this.state.form[k].title = this.state.override_title
            })
        }
        if (this.state.override_title === "employees") {
            this.state.form_disabled = true
        }
        return (
            <Row className="people_panel_row" key={"person_"+this.state?.index}>
                <Col className="col_buffer_left">
                    <Row>
                        First Name
                    </Row>
                    <Row>
                        {this.state.parent.formObject({data: this.state.form.first_name, index: this.state.index, owner: this, disabled: this.state.form_disabled})}
                    </Row>
                </Col>
                <Col className="col_buffer">
                    <Row>
                        Last Name
                    </Row>
                    <Row>
                        {this.state.parent.formObject({data: this.state.form.last_name, index: this.state.index, owner: this, disabled: this.state.form_disabled})}
                    </Row>
                </Col>
                <Col className="col_buffer">
                    <Row>
                        Email
                    </Row>
                    <Row>
                        {this.state.parent.formObject({data: this.state.form.email, index: this.state.index, owner: this, disabled: this.state.form_disabled})}
                    </Row>
                </Col>
                <Col className="col_buffer">
                    <Row>
                        Phone
                    </Row>
                    <Row>
                        {this.state.parent.formObject({data: this.state.form.phone, index: this.state.index, owner: this, disabled: this.state.form_disabled})}
                    </Row>
                </Col>
                <Col className="col_buffer_right">
                    <Row>&nbsp;</Row>
                    <Row>
                        {
                            this.controller?.getPersonButton({index: this.state.index, parent: this, disabled: this.state.form_disabled})
                        }
                    </Row>
                </Col>
            </Row>
        )
    }
}
