// GENERAL REACT MODULES
import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

// OBJECT SPECIFIC MODULES
import "./BookingPanel.css"
import MultiPanel from "components/MultiPanels/MultiPanel";
import Controller from "./BookingPanel.controller.jsx"
// import Seperator from "module/pages/Shipments/ShipmentPartials/Seperator.partial.jsx";
import Seperator from "components/Seperator/Seperator.view.jsx"
import Column from "components/Column/Column.view.jsx"
import ColumnOneController from "./BookingColumns/ColumnOne.controller";
import ColumnTwoController from "./BookingColumns/ColumnTwo.controller";
import ColumnThreeController from "./BookingColumns/ColumnThree.controller";
import StatusPanel from "module/pages/Shipments/ShipmentPartials/StatusPanel/Status.partial"
import Modal from "module/pages/Shipments/ShipmentPartials/Carrier/Carrier.modal.jsx"

export default class BookingPanel extends MultiPanel {
    constructor(props) {
        super(props)
        this.controller = new Controller({params: {view: this}})
        this._modal = Modal
        this._draggable_modal       = false
        this.modal._closeable       = true
        this.state._resolve_load    = true
        this.state._final_req       = "carriers"
        this.state.panel_state      = 0
        this.state.data = {
            cota_id: undefined,
            pickup: {},
            consignee: {},
            pickup_details: {},
            consignee_details: {},
            commodities: [],
            equipment: {},
            shipment: {
                distance: -1
            },
            information: {
                po_number: null,
                bol_number: null,
                hbol_number: null,
                vessel_name: null,
                container_number: null,
                container_size: null,
                customers_brokers: null,
                destination_port: null,
                importer: null,
                origin_country: null,
                fulfillment_number: null,
                additional_reference: null,
                import_value: null,
                voyage_flight: null,
                in_bond: null
            },
            documents: [],
            notes: [],
                stops: [],
                ltl: false,
                carrier: undefined,
                stop: undefined,
                company: undefined,
                saved: {
                times: false,
                // details: false,
                dispatched: false
            }
        }
        this.state.is_saveable = true

        let get_column_controller = this.controller?.get_column_controller.bind(this)
        this.column = {
            controllers: {
                get one() {
                    return get_column_controller({name: "column_one_controller", class_name: ColumnOneController})
                },
                get two() {
                    return get_column_controller({name: "column_two_controller", class_name: ColumnTwoController})
                },
                get three() {
                    return get_column_controller({name: "column_three_controller", class_name: ColumnThreeController})
                }
            }
        }
    }
    addBusinessContact(page_key) {
        this.state?.parent?.selectPanel({panel_name: page_key})
    }
    addBookingContact(page_key) {
        this.state?.parent?.selectPanel({panel_name: page_key})
    }
    save_action() {
        this.state.panel_state = 1
        this.state.data.saved.times = true
        this.forceUpdate()
    }
    determine_state() {
        let panel_state = 0
        if (this.state.data.saved.times) {
            panel_state = 1
        }
        if (this.state.data.saved.dispatched) {
            panel_state = 2
        }
        return panel_state
    }
    get_save_title() {
        console.log(this.determine_state())
        let save_title = "Save"
        switch (this.determine_state()) {
            case 1:
                if (this.state?.context?.authClient?.idTokenParsed?.roles?.includes("INTERNAL_COTA_USER")) {
                    save_title = "Dispatch"
                } else {
                    save_title = "Tender"
                }
                if (this.controller?.load?.carrier?.id !== undefined) {
                    return "Close"
                }
                break
            case 2:
                save_title = "Close"
                break
            default:
                break
        }
        return save_title
    }
    renderSave() {
        if (this.state.is_saveable) {
            return (
                <Row className="save_row">
                    <Col/>
                    <Col xs={1} className="save_button_col">
                        <Button onClick={this.controller.save_action.bind(this)} className={"close_button primary_button_coloring "+this.controller.getTheme()}>
                            {this.get_save_title()}
                        </Button>
                    </Col>
                </Row>
            )
        }
    }
    renderInnerFrame() {
        return (
            <>
                <Container className={"container_wrapper "+ this.offsetWrapper()} ref={ (divElement) => { this.divElement = divElement }}>
                    <Row className="g-0 max_height">
                        <Col className="g-0">
                            <Row className="g-0 max_height">
                                <Column params={{parent: this, controller: this.controller, view_controller: this.column.controllers.one }}/>
                                <Seperator params={{parent: this, controller: this.controller, view_controller: this.column.controllers.one }}/>
                            </Row>
                        </Col>
                        <Col className="g-0">
                            <Row className="g-0 status_row">
                                <StatusPanel params={{parent: this, controller: this.controller }}/>
                            </Row>
                            <Row className="g-0 max_height col_two_row">
                                <Column params={{parent: this, controller: this.controller, view_controller: this.column.controllers.two }}/>
                                <Seperator params={{parent: this, controller: this.controller, view_controller: this.column.controllers.two, solid: true}}/>
                                <Column params={{parent: this, controller: this.controller, view_controller: this.column.controllers.three, full_spacing: false}}/>
                            </Row>
                        </Col>
                    </Row>
                    {
                        this.controller.render_save()
                    }
                </Container>
            </>
        )
    }
}
