// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class ShipmentsMenuController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    switchView() {
        // refactor to use controller ASAP
        this.state?.parent?.view.setState({list_view: !this.state?.parent?.view?.state?.list_view})
    }
}