// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

// import BookingModelController from "controllers/ModelControllers/BookingModelController.jsx"
import StopModelController from "controllers/ModelControllers/StopModelController.jsx"
import CommodityModelController from "controllers/ModelControllers/Shipping/CommodityModelController.jsx"
import EquipmentModelController from "controllers/ModelControllers/Shipping/EquipmentModelController.jsx"
import QuoteModelController from "controllers/ModelControllers/Quotes/QuoteModelController"
// import existing_quote from "assets/graphql/Quotes/GetQuoteRaw.graphql.json"
import ShipmentModelController from "controllers/ModelControllers/Shipping/ShipmentModelController.jsx"
import CompanyModelController from "controllers/ModelControllers/CompanyModelController.jsx"
import InformationModelController from "controllers/ModelControllers/Shipping/InformationModelController.jsx"
import CarrierModelController from "controllers/ModelControllers/Carrier/CarrierModelController"

import edit_load from "assets/graphql/Loads/loads.mutation.graphql.json"
import existing_quoteload from "assets/graphql/Loads/load.query.graphql.json"
import documents_query from "assets/graphql/Loads/load.documents.graphql.json"
import distance_request from "assets/graphql/Distance/distance.graphql.json"
import event_request from "assets/graphql/Events/events.min.graphql.json"

import RequestDoc from "components/RequestDoc/RequestDoc.button.jsx"
import ShareButton from "components/ShareButton/ShareButton.button.jsx"

export default class BookingPanelController extends _viewController {
    constructor(props) {
        super(props)
        // this.notes = []
        // this.commodities = this.view?.state?.data?.commodities
        // this.information = {}
        // this.documents = []
        this.state = {}
        this.state.rate_confirmation    = false
        this.state.stops_length         = 0
        this.state.old_stops_length     = 0
        this.state.panel_state          = 0
        this.company        = new CompanyModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
        this.load           = new ShipmentModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
        this.commodity      = new CommodityModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true,
                                                            _param: "commodities",
                                                            index: 0}})
        this.equipment      = new EquipmentModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
        this.quote          = new QuoteModelController({params: {parent: this,
                                                        controller: this,
                                                        is_view: true}})
        this.information    = new InformationModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true}})
        this.pickup         = new StopModelController({params: {parent: this,
                                                        controller: this,
                                                        is_view: true, _param: "pickup"}})  
        this.consignee      = new StopModelController({params: {parent: this,
                                                        controller: this,
                                                        is_view: true, _param: "consignee"}})
        this.carrier        = new CarrierModelController({params: {parent: this, _param: "shipment.carrier",  
                                                            _bind_child: true, controller: this, is_view: true}})   
        this.requests       = [{callback: {p: {name: "accessorials", var: "accessorials"}},
                                    endpoint: "requirements", req_name: "accessorials"},
                                {callback: {p: {name: "carriers", var: "carriers"}, fin: true},
                                    endpoint: "carriers", req_name: "dropdown"}]    
                                                        
        this.configs = {
            "pickup": {
                "title": "Pickup",
                "type": "shipper",
                "default": "Company name",
                "subtype": "pickup"
            },
            "delivery": {
                "title": "Delivery",
                "type": "",
                "default": "Company name",
                "subtype": "consignee"
            }
        }
        this.send_rate_confirmation = this.send_rate_confirmation.bind(this.view)
        this.share_action   = this.share_action.bind(this.view)
        this.back_action    = this.back_action.bind(this.view)
        this.next_action    = this.next_action.bind(this.view)
        this.undispatch     = this.undispatch.bind(this)
        this.dispatch       = this.dispatch.bind(this)
        this.primary_action = this.primary_action.bind(this.view)
        this.bill_action    = this.bill_action.bind(this.view)
        this.save_action    = this.save_action.bind(this.view)
    }
    get stops() {
        return this.load.stops
    }
    set stops(value) {
        this.load.stops = value
    }
    get events() {
        return this.view.state.events
    }
    load_harness() {
        let data = {}
        if (!this.view?.controller?.check_role("INTERNAL_COTA_USER")) {
            let company = this.view?.state?.context?.self?.companies?.[0]
            if (company !== undefined) {
                this.view.controller.setState({key: "data", param: "company", value: company})
            }
        }
        if (this.view?.state?.panel_params?.data?.id !== undefined) {
            data = JSON.parse(JSON.stringify(this.view.state.panel_params.data))
            this.view.state.panel_params.data = undefined
        }
        if (this.paths?.[1] !== undefined) {
            data.load_number = (this.paths?.[1]).substring(1)
        }
        if (data?.id !== undefined || data?.load_number !== undefined) {
            this.view.state._final_req = "load"
            this.load_existing_quote(data)
            this.load_objects()
        } else {

        }
    }
    dispatch() {
        this.view.toggle_modal({})
    }
    undispatch() {
        this.load.carrier = undefined
        this.setState({key: "data", param: "carrier", value: null})
        this.load.status = "Booked"
        this.setState({key: "data", param: "saved.dispatched", value: false})
        let summary = JSON.parse(JSON.stringify(this.load?.summary))
        summary.carrier_id = ""
        let data    = this.toUnquotedJSON(summary)
        let body    = JSON.parse(JSON.stringify(edit_load))
        body.query  = body.query.replace("load", 'load(input: '+data+')')
        this.api.loads.create({caller: this, params: {body: body}, callback: {f: this.call_close}})
    }

    primary_action() {
        this.controller.save_action()
        this.call_close()
    }
    async get_distance() {
        let fetch = false
        if (this.calc_stop_length() !== this.state.old_stops_length) { fetch = true }
        if (this.load?.data?.distance === null || this.load?.data?.distance === undefined) { fetch = true }

        if (fetch) {
            this.state.old_stops_length = this.calc_stop_length()
            let zips = []
            zips = this.push_zip({stop: this.pickup, zips: zips})
            if (Array.isArray(this.load.stops)) {
                for (let stop of this.load?.stops) {
                    // let stop_model = new StopModelController({params: {parent: this,
                    //                                         controller: this, data: stop,
                    //                                         is_view: true}})
                    zips = this.push_zip({stop: stop, zips: zips})
                }
            }
            zips = this.push_zip({stop: this.consignee, zips: zips})
            if (zips.length > 1) {
                let body    = JSON.parse(JSON.stringify(distance_request))
                body.query  = body.query.replace("(zips:)", '(zips: '+JSON.stringify(zips)+')')
                await this.api.distance.ask({caller: this, params: {body: body}, callback: {f: this.process_distance}})
            }
        }
    }
    calc_stop_length() {
        let len = 0
        if (this.pickup?.contact?.id !== undefined) {
            len = len + 1
        }
        if (this.consignee?.contact?.id !== undefined) {
            len = len + 1
        }
        if (Array.isArray(this.load?.stops)) {
            len = len + this.load?.stops.length
        }
        return len
    }
    push_zip({stop, zips}) {
        let zip = stop?.contact?.zip
        if(!this._notnull(zip) || zip === "") {
            zip = stop?.contact?.company?.zip
        }
        if(!this._notnull(zip) || zip === "") {
            zip = stop?.contact?.address?.location?.zip
        }
        if(!this._notnull(zip) || zip === "") {
            zip = stop?.contact?.company?.address?.location?.zip
        }
        if(this._notnull(zip) && zip !== "") {
            zips.push(zip)
        }
        return zips
    } 
    process_distance({caller, params, results}) {
        caller.load.distance = results?.data?.distance?.total_distance
        caller.view.forceUpdate()
    }
    async save_action() {
        if(this?.controller?.load?.notes?.length) {
            if(!this?.controller?.load?.notes[this?.controller?.load?.notes?.length -1]?.viewable ){
                console.log("SETTING")
                this.controller.load.notes[this.controller.load.notes.length -1].viewable = false
            }
            console.log("NOTES HERE2",this?.controller?.load?.notes[this?.controller?.load?.notes?.length -1])
        }
        this.setState({_is_mutating: true})
        await this.controller.get_distance()
        if (this.controller.load?.status?.toLowerCase() === "pending") {
            this.controller.load.status = "Booked"
        }
        let _summary = this.controller?.load?.summary
        let _stops = this.controller?.load?.summary?.stops
        if(Array.isArray(_stops)) {
            _stops.map((stop, index) => {
                stop.index = index
                return
            })
            _summary.stops = _stops
        }
        // callback?.p?.sb?.success?.show
        let data    = this.controller.toUnquotedJSON(_summary)
        let body    = JSON.parse(JSON.stringify(edit_load))
        body.query  = body.query.replace("load", 'load(input: '+data+')')
        this.api.loads.create({caller: this.controller, params: {body: body}, callback: {f: this.controller.process_loaded_quote}})
    }
    send_rate_confirmation({generate_only}) {
        let load = (this.load !== undefined) ? this.load : this.controller.load
        let datas = [{id: load._id, type: "loads", endpoint: "load"}]
        this.api.documents.rate_confirmation({generate_only: generate_only, caller: this, value: datas, type: "loads", callback: {f: this.controller?.refresh_documents}, receipient: load?.carrier?.id})
    }
    back_action() {
        this.setState({panel_state: 0})
    }
    next_action() {
        if (this.controller?.pickup?.contact?._id !== undefined && this.controller?.consignee?.contact?._id !== undefined) {
            this.controller.save_action()
            this.setState({panel_state: 1})
        }
    }
    bill_action() {
        this.controller.load.status = "billed"
        this.forceUpdate()
    }
    generate_load() {
        let load = {
            quote_id: this.quote?._id,
            rate: this.quote?.cost,
            co2: this.quote?.co2?.amount,
            days: this.quote?.days,
            pickup: {id: this.pickup?._id},
            dropoff: {id: this.dropoff?._id}
        }
        return load
    }
    process_save_load({caller, params, results}) {
        caller.controller.load._id = results?.data?.load?.id
    }
    async load_existing_quote(data) {
        this.setState({_is_mutating: true})
        if (data !== undefined) {
            let body    = JSON.parse(JSON.stringify(existing_quoteload))
            let query_param = (data?.id !== undefined) ? 'load(id: "'+data?.id+'")' : 'load(load_number: "'+data?.load_number+'")'
            body.query  = body.query.replace("load", query_param)
            this.api.quotes.ask({caller: this, params: {body: body}, callback: {f: this.process_loaded_quote, fin: true}})
        }
    }
    determine_state() {
        return (this.view.state.panel_state !== undefined) ? this.view.state.panel_state : 0
    }
    async process_loaded_quote({caller, params, results}) {
        let quoteload = results?.data?.load
        if (quoteload?.stops !== undefined) {
            caller.view.state.data.stops = quoteload?.stops
        }
        if (quoteload?.carrier !== undefined) {
            // caller.view.state.data.saved.dispatched = true
            caller.setState({key: "data", param: "saved.times", value: true})
            if (quoteload?.carrier?.id !== undefined) {
                caller.view.state.data.saved.dispatched = true
            }
        }
        if (quoteload?.loads?.length > 0) {
            caller.company.index = 0
            caller.view.state.data.company = quoteload?.loads.map(a => a.payor)
        } else {
            caller.view.state.data.company = quoteload?.payor
        }
        let quote = quoteload?.quote
        if (quoteload?._type === "quote") {
            quote = JSON.parse(JSON.stringify(quoteload))
            delete quoteload.id
        }
        if (quoteload !== undefined) {
            if (quoteload?.commodities === undefined) {
                let items = quote?.raw_quote?.request?.items
                // const modified_items = items.map(item => {
                //     return {
                //         ...item,
                //         weight: item.weight * item.number
                //     };
                // });
                caller.commodity?._sync({data: items, remapper:"quote"})
            } else {
                caller.commodity?._sync({data:quoteload?.commodities})
            }
            caller.setState({key: "data", param: "equipment", value: quoteload?.equipment})
            caller.load?._sync({data: quoteload, remapper:"quote"})
            caller.quote?._sync({data: quote})
            if (quote?.raw_quote !== undefined && quote?.raw_quote !== null) {
                // let raw_request = JSON.parse(JSON.stringify(quote?.raw_quote))
                let date = undefined
                if (quote?.pickup_date !== null && quote?.pickup_date !== undefined) {
                    let convert_date = new Date(parseInt(quote?.pickup_date, 10))
                    if (!isNaN(convert_date)) {
                        date = convert_date
                    }
                }
                caller.quote?._sync({data: quote})
                if (quote?.customer?.id !== undefined && quote?.customer?.id !== null && caller.view?.state?.data?.company?.id === undefined || caller.view?.state?.data?.company?.id === null) {
                    caller.company?._sync({data: quote?.customer})
                }
            }
            caller.load_stop_data({caller: caller, data: quoteload})
            if (quoteload?._type === "load") {
                caller.get_events({id: quoteload.id})
            }
        }
        caller.view.state.data.information = quoteload?.info
        caller.view.setState({_is_loading: false, panel_state: 1})
    }
    async load_stop_data({caller, data}) {
        this.state.stops_length = 0
        if (data?.pickup !== undefined) {
            caller.get_stop({id: data?.pickup?.id, name: "pickup"})
        }
        if (data?.dropoff !== undefined) {
            caller.get_stop({id: data?.dropoff?.id, name: "consignee"})
        }
        if (data?.stops !== undefined) {
            if (Array.isArray(data.stops)) {
                data.stops.forEach((stop, index) => {
                    caller.get_stop({id: stop.id, name: "stops", index: stop.index})
                    return
                })
            }
        }
        await caller.get_distance()
    }
    _delete({name, index}) {
        this.view.state.data[name] = this.view?.state?.data?.[name].filter((value, i) => i !== index)
        this.view.forceUpdate()
    }
//    get information() {
//        return this.view?.state?.data?.information
//    }
    get documents() {
        return this.view?.state?.data?.documents
    }
    get notes() {
        return this.view?.state?.data?.notes
    }
    get commodities() {
        return this.view?.state?.data?.commodities
    }
    new_object(type) {
        switch(type) {
            case ("commodities" || "commodity"):
                return new CommodityModelController({params: {parent: this, 
                                                                controller: this, 
                                                                is_view: true}})
            default: 
                return undefined
        }
    }
    get_events({id}) {
        let body    = JSON.parse(JSON.stringify(event_request))
        body.query  = body.query.replace("events", 'events(id: "'+id+'")')
        this.api.events.ask({caller: this, params: {body: body}, callback: {f: this.process_events, fin: true}})
    }
    process_events({caller, params, results}) {
        if (caller?.view?.state !== undefined) {
            caller.view.state.events = results?.data?.events
        }
    }
    load_objects() {
        Object.keys(this.view.state.data).map((key, index) => {
            if (this.view.state.data[key] !== undefined) {
                if (key === "pickup" || key === "consignee") {
                    this[key] = new StopModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true, _param: key}})
               }
            }
            return
        })
    }
    load_cache() {
        if (this.panel_controller?.cache?.[this.view.name] !== undefined) {
            this.view.state.data = this.panel_controller.getCache(this.view.name)
        }
        let cache_2 = this.panel_controller.getCache("2")
        if (this.panel_controller?.state?.current?.id) {
            this.view.state.panel_params.data = this.panel_controller?.state?.current
        }
        if (cache_2?.data?.id !== undefined) {
            if (cache_2?.data?.rate_data !== undefined) {
                if (this.view?.state?.data?.rate_data !== undefined) {
                    this.view.state.data.rate_data = cache_2.data?.rate_data
                }
            }
        }
        this.panel_controller.state.context.cache[this.view.name] = this.view.state.data
    }
    get_save_title() {
        let save_title = "Save"
        switch (this.determine_state()) {
            case 1:
                save_title = this.dispatch_title()
                break
            case 2:
                save_title = "Close"
                break
            case 3:
                save_title = "Bill"
                break
            default:
                break
        }
        return save_title
    }
    dispatch_title() {
        if (this.view?.state?.context?.authClient?.idTokenParsed?.roles?.includes("INTERNAL_COTA_USER")) {
            return "Dispatch"
        } else {
            return "Tender"
        }
    }
    get_posted_title(){
        console.log('POSTED TITLE', this)
        if(this?.load.status == "Posted") return "Unpost"
        return "Post"
    }
    get_posted_css(){
        if(this?.load.status == "Posted") return "undispatch"
        return "dispatch"
    }
    get_undispatch() {
        if (this.load?.carrier?.id !== undefined && this.load?.carrier?.id !== "") {
            return(
                <Col xs={2} className="button_col">
                    <Button className={"primary_button_coloring button_border "+this._theme} onClick={this.undispatch.bind(this)}>
                        Un-{this.dispatch_title()}
                    </Button>
                </Col>
            )
        }
    }
    share_action() {
        this.panel_controller?.selectPanel({panel_name: "single_page", cache_data: { data: this.state?.data, back: "booking" } })
    }
    render_save() {
        this.buttons = [{condition: this.load?.cota_id, value: "NOT_NULL", title: "Share", action: this.share_action, type: "ShareButton"},
            {condition: [this.pickup?.contact?._id, this.consignee?.contact?._id, this.load?.cota_id, this.load?.carrier?.id, this.view?.state?.context?.authClient?.idTokenParsed?.roles?.includes("INTERNAL_COTA_USER")], value: ["NOT_NULL", "NOT_NULL", "NOT_NULL", "IS_NULL", true], title: this.get_posted_title(), action: this.toggle_post_to_loadboost.bind(this), css: this.get_posted_css()},
                        {condition: [this.pickup?.contact?._id, this.consignee?.contact?._id, this.load?.cota_id, this.load?.carrier?.id], value: ["NOT_NULL", "NOT_NULL", "NOT_NULL", "NOT_NULL"], title: "Send Rate-Confirmation", action: this.send_rate_confirmation, type: "RequestDoc"},
                        {condition: [this.pickup?.contact?._id, this.consignee?.contact?._id, this.load?.cota_id, this.load?.carrier?.id], value: ["NOT_NULL", "NOT_NULL", "NOT_NULL", "IS_NULL"], title: this.dispatch_title(), action: this.dispatch, css: "dispatch"},
                        {condition: [this.pickup?.contact?._id, this.consignee?.contact?._id, this.load?.cota_id, this.load?.carrier?.id], value: ["NOT_NULL", "NOT_NULL", "NOT_NULL", "NOT_NULL"], title: "Un-"+this.dispatch_title(), action: this.undispatch, css: "undispatch"},
                        {condition: this.load?.status, value: "delivered", title: "Bill", action: this.bill_action},
                        {condition: [this.pickup?.contact?._id, this.consignee?.contact?._id], value: ["NOT_NULL", "NOT_NULL"], title: "Save", action: this.save_action},
                        {condition: [this.pickup?.contact?._id, this.consignee?.contact?._id, this.load?.carrier?.id], value: ["NOT_NULL", "NOT_NULL", "NOT_NULL"], title: "Close", action: this.primary_action}]

        if (this.view.state?.is_saveable) {
            return (
                <Row className="save_row add_shadow">
                    <Col/>
                    {this.buttons.map((btn, index) => {
                        return this.render_button(btn)
                    })}
                </Row>
            )
        }
    }
    render_button({condition, not, value, title, action, css, type}) {
        let button_view = this._render_button_view({title: title, action: action, css: css, type: type})
        if (value !== undefined) {
            if (Array.isArray(condition)) {
                let check_value = false
                let checks = []
                condition.forEach((cond, i) => {
                    let cond_value = (Array.isArray(value)) ? value?.[i] : value
                    checks.push(this.check_button_condition({condition: cond, value: cond_value, not: not}))
                })
                check_value = checks.every(v => v === true)
                if (check_value) {
                    return button_view
                }
            } else {
                let check_value = this.check_button_condition({condition: condition, value: value, not: not})
                if (check_value) {
                    return button_view
                }
            }
            if (value === undefined) {
                if (condition !== undefined && condition !== "") {
                    return button_view
                }
            }
            return
        }
        return button_view
    }
    check_button_condition({condition, value, not}) {
        if (value === "NOT_NULL") {
            if (condition !== undefined && condition !== null) {
                return true
            }
            return false
        }
        if (value === "IS_NULL") {
            if (condition === undefined || condition === null) {
                return true
            }
            return false
        }
        if (value === "NOT_BLANK") {
            if (condition !== undefined && condition !== null && condition !== "") {
                return true
            }
            return false
        }
        if (isNaN(value)) {
            if (not) {
                if (condition.toLowerCase() !== value.toLowerCase()) {
                    return true
                }
            } else {
                if (condition.toLowerCase() === value.toLowerCase()) {
                    return true
                }
            }
        } else {
            if (not) {
                if (condition < value) {
                    return true
                }
            } else {
                if (condition > value) {
                    return true
                }
            }
        }
        if(condition === value) return true;
        return false
    }
    render_button_css(title) {
        if (title?.length > 12) {
            return "button_border_wide"
        }
        return "button_border"
    }
    render_button_col_css(title) {
        if (title?.length > 12) {
            return "button_col_wide"
        }
        return "button_col"
    }
    _render_button_view({title, action, css, type}) {
        let passed_css = (css !== undefined) ? css : ""
        switch (type) {
            case "RequestDoc":
                return (
                    <Col xs={1} className={this.render_button_col_css(title)}>
                        <RequestDoc params={{button: true, title: "Rate Confirmation", controller: this, parent: this, emails: [{value: this.carrier?.email, label: this.carrier?.email}], css: this.render_button_css(title)}} />
                    </Col>
                )
            case "ShareButton":
                return (
                    <Col xs={1} className={this.render_button_col_css(title)}>
                        <ShareButton params={{button: true, title: "Share", controller: this, parent: this, css: this.render_button_css(title), link: this.load?.cota_id}} />
                    </Col>
                )
            default:
                return (
                    <Col xs={1} className={this.render_button_col_css(title)}>
                        <Button className={"primary_button_coloring "+passed_css+" "+this.render_button_css(title)+" "+this._theme} onClick={action}>
                            {title}
                        </Button>
                    </Col>
                )
        }
    }
    get_column_controller({name, class_name}) {
        if (this.state[name] === undefined && class_name !== undefined) {
            this.state[name] = new class_name({params: {view: this}})
        }
        return this.state[name]
    }
    follow_on_selection({event, obj, data}) {
        this.get_contact({id: obj?.id, name: data?.name})
    }
    get_stop({id, name, index}) {
        this.state.stops_length = this.state.stops_length + 1
        this.api?.stops?.gid({caller: this, value: id, callback: {f: this.process_loaded_stop, p: {name: name, index: index}}})
    }
    get_contact({id, name}) {
        this.api?.contacts?.gid({caller: this, value: id, callback: {f: this.process_loaded_contact, p: {name: name}}})
    }
    process_loaded_stop({caller, params, results}) {
        if (params.index === undefined) {
            caller?.[params?.name]?._sync({data: results?.data?.stop})
        } else {
            let stops = caller.view.state.data[params?.name]
            console.log("stops from process_loaded_stop ++3", stops)
            if (results?.data?.stop !== undefined && results?.data?.stop !== null) {
                stops[params?.index] = results?.data?.stop
                caller.setState({key: "data", param: params?.name, value: stops})
            }
        }
    }
    process_loaded_contact({caller, params, results}) {
        let contact = {
            contact: results?.data?.contact,
            company: results?.data?.contact?.company,
            insurance: results?.data?.contact?.insurance
        }
        if (params?.name !== "stop") {
            caller.setState({key: "data", param: params?.name, value: contact})
        }
        if (params?.name === "stop") {
            caller.view?.state?.data?.stops?.push(contact)
        }
    }
    reset_contact() {
        this.controller.setState({key: "data", param: "carrier", value: {}})
    }
    add_commodity(data) {
        this.view.state.data.commodities.push(data)
    }
    delete_action({obj}) {
        this.load.documents.splice(obj?.index, 1)
    }
    request_documents({generate_only}) {
        this.send_rate_confirmation({generate_only: generate_only})
    }
    refresh_documents({caller, params, results}) {
        let data    = caller.controller?.load?.data
        let body    = JSON.parse(JSON.stringify(documents_query))
        let query_param = (data?.id !== undefined) ? 'load(id: "'+data?.id+'")' : 'load(load_number: "'+data?.load_number+'")'
        body.query  = body.query.replace("load", query_param)
        caller.api.quotes.ask({caller: caller, params: {body: body}, callback: {f: caller.controller?.process_refresh_documents, fin: true}})
    }
    process_refresh_documents({caller, params, results}) {
        caller.controller.load.documents = results?.data?.load?.documents
        caller.forceUpdate()
    }
    toggle_post_to_loadboost(){
        if(this.load.status == "Posted"){
            this.load.status = "Booked"
            this.load.status_details = {code: 3, label: "Booked", name: "BOOKED", value: "BOOKED"}
            this.save_action()
            return
        }
       this.load.status = "Posted"
       this.load.status_details = {code: 30, label: "Posted", name: "POSTED", value: "POSTED"}
       this.save_action()
    }
}
