// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./DistancePanel.controller.jsx"
import "./DistancePanel.css"

export default class DistancePanel extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this, controller: this.controller}})
    }
    renderView() {
        if (this.controller.load.distance !== undefined && this.controller.load.distance !== null) {
            return (
                <>
                    <Row id={this.name} className="row_padding">
                        <Col className="cota_panel_object_unsized cota_panel_border max_block dispatch_panel">
                            <Row className="dispatch_title">
                                <Col>
                                    Total Distance
                                </Col>
                            </Row>
                            <Row className="generic_center title">
                                <Col>
                                  { (this.controller.load?.distance >= 0) ? this.controller.load?.distance : 0 } miles
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            )
        }
        return (<></>)
    }
}
