// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "../_ModelController";

export default class MultiLoadModalController extends _ModelController {
    constructor(props) {
        super(props)
        this._param             = "loads"
        let get_total_weight    = this.get_total_weight.bind(this)
        let get_total_length    = this.get_total_length.bind(this)
        let get_total_miles     = this.get_total_miles.bind(this)
        let get_total_rate      = this.get_total_rate.bind(this)
        let get_total_rm        = this.get_total_rm.bind(this)
        let get_total_stops     = this.get_total_stops.bind(this)
        let get_total_distance  = this.get_total_distance.bind(this)
        let get_total_time      = this.get_total_time.bind(this)
	    let get_total_co2       = this.get_total_co2.bind(this)
        this.total = {
            get weight() {
                return get_total_weight()
            },
            get length() {
                return get_total_length()
            },
            get miles() {
                return get_total_miles()
            },
            get rate() {
                return get_total_rate()
            },
            get rm() {
                return get_total_rm()
            },
            get co2() {
                return get_total_co2()
            },
            get stops() {
                return get_total_stops()
            },
            get distance() {
                return get_total_distance()
            },
            get time() {
                return get_total_time()
            }
        }
    }
    get_total_weight() {
        return this.total_weight
    }
    get total_weight() {
        if (Array.isArray(this.loads)) {
            return this.loads.reduce(function(prev, current) {
                return prev + +current.total_weight
            }, 0)
        }
        return
    }

    get_total_length() {
        return this.total_length
    }
    get total_length() {
        if (Array.isArray(this.loads)) {
            return this.loads.reduce(function(prev, current) {
                return prev + +current.length
            }, 0)
        }
        return 
    }

    get_total_miles() {
        return this.total_miles
    }
    get total_miles() {
        return ""
    }

    get_total_rate() {
        return this.total_rate.toFixed(2)
    }
    get total_rate() {
        if (Array.isArray(this.loads)) {
            return this.loads.reduce(function(prev, current) {
                return prev + +current.total_amount
            }, 0)
        }
        return
    }

    get_total_rm() {
        return this.total_rm
    }
    get total_rm() {
	    return (this.total_rate / this.distance.total_distance).toFixed(2)
    }

    get_total_co2() {
        return this.total_co2.toFixed(2)
    }
    get total_co2() {
        if (Array.isArray(this.loads)) {
            return this.loads.reduce(function(prev, current) {
                return prev + +((current.co2 !== undefined) ? current.co2 : 0)
            }, 0)
        }
        return
    }

    get_total_stops() {
        return this.total_stops
    }
    get total_stops() {
        return this.stops?.length
    }

    get loads() {
        return this.data
    }
    get complexity() {
	    return (Math.round(this.distance?.complexity * 100) / 100).toFixed(2)
    }
    get ave_complexity() {
        return (Math.round(this.distance?.ave_complexity * 100) / 100).toFixed(2)
    }
    get full_complexity() {
        return this.complexity.toString() + " / " + this.ave_complexity.toString()
    }
    get_total_distance() {
        return this.distance?.total_distance
    }
    get_total_time() {
        return this.distance?.total_time?.toString().replace('.', ':')
    }
    get distance() {
        return this.controller.getState({key: this._data_key, param: "distance"})
    }
    get stops() {
        return this.controller.getState({key: this._data_key, param: "stops"})
    }
    get summary() {
        let load = {
        }
	    load.scrub()
        return load
    }
}
