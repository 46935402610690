// GENERAL REACT MODULES
import React from "react";
import { Button, Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import "./SecondPanel.css"

// ALL OTHER MODULES
import MultiPanel from "components/MultiPanels/MultiPanel";
import { MainConsumer } from "controllers/MainContext.jsx"
import MainContext from "controllers/MainContext.jsx";
import Controller from "./SecondPanel.controller.jsx";
import "styles/generic.css"
import config_data from "../../Home.json"
import PayorObject from "module/pages/Shipments/ShipmentPartials/Payor/Payor.partial.jsx"

export default class SecondPanel extends MultiPanel {
    constructor(props) {
        super(props)
        this.state.form = config_data.form_objects
        this.controller = new Controller({params: {view: this}})
    }
    renderInnerFrame() {
        return(
            <MainConsumer>
                {(props) => {
                    {
                        this.state.user = props?.user
                    }
                    return(
                        <Row className="margin_auto generic_panel_object generic_90" id={this.name}>
                            <Col sm={2}>&nbsp;</Col>
                            <Col className="generic_block_ul inner_company_wrapper">
                                <Row className="generic_center company_title_question">
                                    <Col>
                                        Company Name
                                    </Col>
                                </Row>
                                <Row className="company_field">
                                    <Col>
                                        <PayorObject params={{parent: this, controller: this.controller, center: true,
                                                data: {company: this.controller.company}, closeable: false}}/>
                                    </Col>
                                </Row>
                                <Row className="generic_override_flex padding_row">
                                    <Col className="generic_center generic_side_buffer">
                                        Is this you ?
                                    </Col>
                                    <Col>
                                        <Button className={"confirm_button "+this._theme} onClick={this?.controller?.submit_action.bind(this)}>
                                            Request to Join
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="generic_override_flex padding_row">
                                    <Col className="generic_center generic_side_buffer">
                                        Do you have a code ?
                                    </Col>
                                    <Col>
                                        {this.formObject({data: this.state.form.code, owner: this, disabled: this.state.form_disabled})}
                                    </Col>
                                </Row>
                                <Row className="generic_center_content">
                                    <Col>&nbsp;</Col>
                                    <Col className="generic_center">
                                        <Button variant="link" className={"reject_button generic_center "+this._theme} onClick={this?.buttonAction.bind(this, false)}>
                                            No, not my company
                                        </Button>
                                    </Col>
                                    <Col>&nbsp;</Col>
                                </Row>
                            </Col>
                            <Col sm={2}>&nbsp;</Col>
                        </Row>
                    )
                }
            }
            </MainConsumer>
        )
    }
}
SecondPanel.contextType = MainContext
