// GENERAL REACT MODULES
import { Button } from 'react-bootstrap'

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import PersonModelController from "controllers/ModelControllers/PersonModelController.jsx"
import CarrierModelController from "controllers/ModelControllers/Carrier/CarrierModelController"
import graph_request from "assets/graphql/Companies/Company.graphql.json"
import carrier_request from "assets/graphql/Carriers/Carrier.search.graphql.json"
import factoring_request from "assets/graphql/Companies/Factoring.graphql.json"
import mutation_request from "assets/graphql/Carriers/Carriers.mutation.graphql.json"
import update_request from "assets/graphql/Carriers/Carrier.update.graphql.json"

export default class EditCarriersController extends _viewController {
    constructor(props) {
        super(props)
        this.people     = []
        this.cache      = {}
        this.employees  = []
        this.person     = new PersonModelController({params: {parent: this, key: "", index: 0, _param: "people",
                                                            controller: this,
                                                            is_view: true}})
        this.carrier    = new CarrierModelController({params: {parent: this, 
                                                            controller: this, 
                                                            is_view: true}})
        if (this.people.length === 0) {
            this.new_person()
        }
        this.requests   = [{request: factoring_request, 
                                callback: {p: {name: "factoring", var: "factoring_types"}}, 
                                endpoint: "companies"},
                            {callback: {p: {name: "trailer_types", var: "trailer_types"}}, 
                                endpoint: "trailers"},
                            {callback: {p: {name: "roles", var: "roles", disp: "label"}}, 
                                    endpoint: "roles"},
                            {callback: {f: this.state_results, p: {var: ["areas", "states"]}}, 
                                endpoint: "areas", replace: {o: "params:", r:'params: {country: "US"}'}}]
    }
    get_image(url) {
        if (url !== undefined && url !== null) {
            return (<img src={url} className="logo" alt="Cota Freight Logo"/>)
        }
        return (<div className="logo" ></div>)
    }
    get company() {
        return this.carrier.company
    }
    get billing_details() {
        return this.carrier.billing_details
    }
    get insurance() {
        return this.carrier.insurance
    }
    new_person() {
        this.people?.push({"index": this.people.length, "first_name": undefined, "last_name": undefined, "email": undefined, "phone": undefined, "roles": []})
        this.view?.forceUpdate()
    }
    state_results({caller, params, results}) {
        if (results?.errors === undefined && results?.data?.areas !== undefined) {
            results.data.areas.forEach((area, index) => {
                caller.view.state.areas.push({ value: area.id, label: area.name+" ("+area.state+")"})
                caller.view.state.states.push({ value: area.state, label: area.state })
            })
        }
    }
    build_people() {
        let summary_people = []
        if (this.people.length > 0) {
            this.people?.forEach((person, index) => {
                if (this.person !== undefined) {
                    this.person.index = index
                    if (this.person.sum > 2) {
                        summary_people.push(this.person.summary)
                    }
                }
            })
        }
        return summary_people
    }
    load_cache() {
        let key = "0"
        let cache = this.panel_controller.getCache(key)
        if (cache !== undefined) {
            this.setState({key:"panel_params", param: "data.id", value: cache.data?.carrier?.id})
        }
    }
    load_harness() {
        this.load_carrier(this.view?.props?.params?.panel_params)
    }
    async load_carrier(params) {
        if (params?.data?.id?.length !== undefined) {
            this.view.state.form_disabled = true
            let id      = (params?.data?.id !== undefined) ? params?.data?.id : this.getState({key:"panel_params", param: "data.id"})
            if (id !== undefined) {
                let body    = JSON.parse(JSON.stringify(carrier_request))
                body.query  = body.query.replace("carrier", 'carrier(id: "'+id+'")')
                this.api.carriers.ask({caller: this, params: {body: body}, nocache: true, callback: {f: this.process_carrier}})
            }
        }
        this.view.state.panel_params.data = undefined
    }
    process_carrier({caller, params, results}) {
        if (results?.data?.carrier?.people !== undefined & results?.data?.carrier?.people !== null) {
            caller.people       = [...results?.data?.carrier?.people, ...caller.people]
        }
        caller.cache.people = results?.data?.carrier?.people 
        caller.employees    = results?.data?.carrier?.company?.employees
        caller.factoring    = results?.data?.carrier?.factoring_company
        if (results?.data.carrier !== null && results?.data.carrier !== undefined) {
            results.data.carrier.roles   = caller.process_roles(results?.data?.carrier?.roles)
        }
        if (results?.data?.carrier?.address === null) {
            results.data.carrier.address = {}
        }
        if (results?.data?.carrier?.address?.location === null) {
            results.data.carrier.address.location = {}
        }
        caller.view.state.data.carrier                      = results.data?.carrier
        caller.view.state.data.carrier.factoring_company    = results?.data?.carrier?.factoring_company?.id 
        caller.view.state.data.company                      = results.data?.carrier?.company
        caller.view.state.data.insurance                    = (results.data?.carrier?.insurance !== null && results.data?.carrier?.insurance !== undefined) ? results.data?.carrier?.insurance : {}
        caller.view.state.data.billing_details              = caller.process_billing_details(results.data?.carrier?.billing_details)
        if (caller?.view?.state?.data?.company !== null && caller?.view?.state?.data?.company !== undefined) {
            caller.view.state.data.company.trailer_types    = caller.process_object(results.data?.carrier?.company?.trailer_types)
            caller.view.state.data.company.operating_areas  = caller.process_object(results.data?.carrier?.company?.operating_areas)
        }

        let name    = ((caller._notnull(caller.view.state.data?.carrier?.name)) ? caller.view.state.data?.carrier?.name : "")
        let company = ((caller._notnull(caller.view.state.data?.company?.id)) ? caller.view.state.data?.company : null)
        if (results?.data?.carrier?.name !== undefined && results?.data?.carrier?.name !== null) {
            company = {name: results.data.carrier.name}
        }
        caller.setState({key: ["search",
                                "search"],
                        param: ["company.search_value",
                                "company.selected_value"],
                        value: [name,
                                company]})
        let address = caller.carrier?.address
        let location = caller.carrier?.location
        let zip = caller.carrier?.zip
//        let address = ((caller._notnull(company?.address?.address)) ? company?.address?.address : "")
//        let location = ((caller._notnull(company?.address?.location)) ? company?.address?.location : "")
//        let zip = ((caller._notnull(company?.address?.location?.zip)) ? company?.address?.location?.zi
        caller.setState({key: ["search",
                                        "search",
                                        "search"],
                                    param: ["address.search_value",
                                            "zip.selected_value",
                                            "zip.search_value"],
                                    value: [address,
                                        location,
                                        zip]})

    }
    process_object(values) {
        if (values !== undefined && values !== null) {
            let obj_values = []
            values?.forEach((k, i) => {
                obj_values.push(k?.id)
            })
            return obj_values
        }
        return undefined
    }
    process_billing_details(bd) {
        let billing_details = {
            bank: (bd?.bank !== undefined) ? bd?.bank : null,
            routing_number: (bd?.routing_number !== undefined) ? bd?.routing_number : null,
            account_number: (bd?.account_number !== undefined) ? bd?.account_number : null
        }
        return billing_details
    }
    process_roles(roles) {
        let formatted_roles = { 
            payor: null,
            shipper: null,
            consignee: null,
            importer: null
        }
        if (roles !== undefined && roles !== null) {
            roles?.forEach((role, index) => {
                if (role?.name !== undefined) {
                    formatted_roles[role.name.toLowerCase()] = true
                }
            })
        }
        return formatted_roles
    }
    async saveAction(page_key) {
        this.state.save = true
        if (this?.state?.current_image){
            this.state.parent.state.image = this?.state?.current_image
        }
        this.setState({_is_mutating: true})
        let body = undefined
        let id = undefined
        let summary = this.controller.carrier.summary
        if (this.state?.data?.carrier?.id === undefined) {
            body    = JSON.parse(JSON.stringify(mutation_request))
        } else {
            body    = JSON.parse(JSON.stringify(update_request))
            id = this.controller?.carrier?._id
            delete summary.company?.company?.employees
            delete summary.company?.company?.email
        }
        let summary_people = this.controller.build_people()
        if (summary_people.length > 0) {
            summary.carrier.people = summary_people
        }
        if (id !== undefined) {
            summary.carrier.id = id
        }
        let data = this.controller.toUnquotedJSON(summary.scrub())
        body.query = body.query.replace("input:", 'input: '+data)
        let sb_config = {success: {show: false}}
        await this.api.carriers.create({caller: this, params: {body: body}, callback: {f: this.controller?.process_save, p: {page_key: page_key, sb: sb_config}}})
    }
    async process_save({caller, params, results}) {
        let id = (results?.data?.setCarrier?.id !== undefined) ? results?.data?.setCarrier?.id : results?.data?.updateCarrier?.id
        if (id !== undefined && id !== null) {
            if (caller?.controller?.uploader !== undefined) {
                // await caller.controller.uploader?.process_queue(id)
            }
            caller.panel_controller.selectPanel({panel_name: params?.page_key, cache_data: {id: id, type: caller.controller?.contact_type}})
        }
    }
    async follow_on_selection({event, obj, data}) {
        if (data?.name === "zip") { 
            this.carrier.zip        = obj.zip
            this.carrier.city       = obj.city
            this.carrier.state      = obj.state
            this.carrier.country    = obj.country
        }
        if (data?.name === "address") {
            this.carrier.address    = obj?.address
            this.carrier.address_2  = obj?.address_2
            this.carrier.zip        = obj?.location?.zip
            this.carrier.city       = obj?.location?.city
            this.carrier.state      = obj?.location?.state
            this.carrier.country    = obj?.location?.country
            this.setState({key: "search",
                            param: "zip.selected_value",
                            value: {zip: obj?.location?.zip} })
        }
        if (obj?.id !== undefined && data?.name === "company") {
            let body    = JSON.parse(JSON.stringify(graph_request))
            body.query  = body.query.replace("company", 'company(id: "'+obj?.id+'")')
	        this.api.companies.ask({caller: this, params: {body: body}, nocache: true, callback: {f: this.process_company}})
        }
    }
    process_company({caller, params, results}) {
        caller.setState({key: ["search",
                             "search",
                             "data"],
                            param: ["address.selected_value",
                                    "zip.selected_value",
                                    "company"],
                            value: [{address: results?.data?.company?.address?.address},
                                    {zip: results?.data?.company?.address?.location?.zip},
                                    results?.data?.company]})
    }
    getPersonButton({index, parent, disabled}) {
        if (index === this.people.length-1 && disabled !== true) {
            return (
                <Button className="cota_light_blue_button small_btn" onClick={this.action_new_person.bind(this, parent?.state?.index)}>
                    Add
                </Button>
            )
        } else {
            return (
                <Button className="cota_light_blue_button small_btn" onClick={this.remove_person.bind(this, index)}>
                    Delete
                </Button>   
            )
        }
    }
    action_new_person(index) {
        if (this.person.load(index)._sum > 0) {
            this.new_person()
        }
    }
    remove_person(index) {
        if (index > -1) {
            this.people.splice(index, 1)
        }
        this?.view?.forceUpdate()
    }
    new_object(type) {
        switch(type) {
            case "people":
                return new PersonModelController({params: {parent: this, 
                                                                controller: this, 
                                                                is_view: true}})
            default: 
                return undefined
        }
    }
}
