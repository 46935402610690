// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import GrafanaFrame from "components/GrafanaFrame/GrafanaFrame.view.jsx"

export default class ShipmentsPanelController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    renderIframeView() {
        if (this.view?.state?.parent?.view?.state?.list_view) {
            return (
                <GrafanaFrame className="grafana-wrapper" params={{parent: this, 
                                                                panel_configs: this.view?.state?.panel_configs, 
                                                                panel_name: "shipments_maps", 
                                                                use_control_bar: false,
                                                                filters: {created_by: this.context?.authClient?.subject},
                                                                controller: this.view.controller}}/>
            )
        }
        return (
            <span>
                <GrafanaFrame className="grafana-wrapper" params={{parent: this, 
                                                                panel_configs: this.view?.state?.panel_configs, 
                                                                panel_name: "shipments", 
                                                                use_control_bar: false,
                                                                filters: {created_by: this.context?.authClient?.subject},
                                                                controller: this.view.controller}}/>
            </span>
        )  
    }
}
