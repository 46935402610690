// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button} from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"
import form_configs from "./EditAppointment.json"
import "./EditAppointment.css"

export default class EditAppointmentModal extends _baseModal {
    constructor(props) {
        super(props)
        this.modal_controller   = this.state.parent?.partial_controller
        this.state.dims = {width: 636, height: 980}
        this.state.form = form_configs.form_objects?.stop
        this.state.modal_params = {
            positioned: "middle_of_panel",   
            title: "Edit Stop Details",                
            title_size: "",                     
            closeable: true,
            draggable: true,                  
            has_overlay: false,                 
            close_on_outside_click: true,       
            x_adjustment: "0px",                
            y_adjustment: "0px",
        }
    }
    get type() {
        return this.state.parent.state.type
    }
    renderView() {
        return (
            <>
                 <Row className="pad_top_point5rem">
                    <Col className="generic_form_label">
                        Stop #
                        {/* {this.type == "pickup" ? "Pickup #:" : "Delivery #:"} */}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.formObject({ data: this.state.form?.pickup_number, controller: this.modal_controller})}
                    </Col>
                </Row>
                <hr />
                <Row className="pad_top_point5rem">
                    <Col>
                        <Row>
                            <Col className="generic_form_label">
                                Stop Date
                                {/* {this.type == "pickup" ? "Pickup Date:" : "Delivery Date:"} */}
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                {this.formObject({ data: this.state.form?.delivery_date, controller: this.modal_controller})}
                            </Col>
                        </Row>
                    </Col>
                    <Col className="flex_centered">
                        <Row>
                            <Col className="generic_form_label">
                                {this.formObject({ data: this.state.form?.appointment_required, controller: this.modal_controller})}
                            </Col>
                        </Row> 
                    </Col>
                </Row>
                <Row className="pad_top_point5rem">
                    <Col >
                        <Row>
                            <Col className="generic_form_label">
                                Stop Time
                                {/* {this.type == "pickup" ? "Pickup Time:" : "Delivery Time:"} */}
                            </Col>
                        </Row>
                        <Row className="generic_form_label">
                            <Col xs="auto">
                                {this.formObject({ data: this.state.form?.start_time, controller: this.modal_controller})} 
                            </Col>
                            <Col className="flex_centered" xs="auto">
                                -
                            </Col>
                            <Col xs="auto">
                                {this.formObject({ data: this.state.form?.end_time, controller: this.modal_controller})} 
                            </Col>
                            <Col></Col>
                        </Row>
                    </Col>
                </Row>
            <hr/>
            <Row>
                <Col>
                </Col>
                <Col className="button_min_w g-0">
                    <Button className={"center_button clear_button "+this._theme} onClick={this.modal_controller.cancel.bind(this.parent)} >
                        Cancel
                    </Button>
                </Col>
                <Col className="button_min_w g-0">
                    <Button className={"center_button primary_button_coloring "+this._theme} onClick={this.modal_controller.save_action.bind(this.parent)} >
                        Save
                    </Button>
                </Col>
            </Row>
        </>
        );
    }
}
