// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import NoteModelController from "controllers/ModelControllers/Shipping/NoteModelController.jsx"

export default class NotesModalController extends _viewController {
    constructor(props) {
        super(props)
        this._configs = {
            local: "note",
            array: "notes"
        }
        this.note = new NoteModelController({params: {parent: this, 
                                                        controller: this, 
                                                        is_view: true,
                                                        index: 0,
                                                        _param: "notes"}})
    }
    add_object() {
        this.controller.view.state.data.notes.push(JSON.parse(JSON.stringify(this.modal_controller?.note?.summary)))
        this.modal_controller.setState({key: "data", param: "notes", value: [{note_text:null, viewable: false}]})
        this.forceUpdate()
    }
    render_date_row({label, date}) {
        if (date !== null && date !== undefined) {
            return (
                <Row className="date_row">
                    <Col sm={2} className="generic_center">
                        {label} :
                    </Col>
                    <Col className="generic_center">
                        {date}
                    </Col>
                </Row>
            )
        }
        return (<></>)
    }
}
